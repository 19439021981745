
import React from "react";
import CheckoutForm from './checkout-form';
import { useLocation } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const StripePaymentForm = () => {
  const stripePromise = loadStripe(process.env?.REACT_APP_STRIPE_KEY);
  const data = useLocation();
  const options = {
    clientSecret: data.state?.client_secret,
  };

  // console.log('env__', process.env?.REACT_APP_STRIPE_KEY)
  return (
    <React.Fragment>
      <Elements stripe={stripePromise} options={options}>
        <div className="main py-lg-5 py-4 align-items-start" >
          <div className="container">
            <div className="row justify-content-center px-md-3">
              <div className="col-lg-8 col-md-10">
                {/* <h1 className="fs-24 medium pb-3">Payment-{paymentCompleted}</h1> */}
                <div className="site-form" >
                  <CheckoutForm
                    bookingInfo={data.state} amount={parseFloat(data.state?.priceAmount?.toFixed(2))}
                    orginialPrice={data.state?.isReschedulBooking
                      ? data.state?.coachData?.profileData?.price : (data?.state?.isFacilite ? data.state.coachData.facility_detail.price : data?.state?.coachData?.coach_detail?.price)} /* setPaymentCompleted={setPaymentCompleted} */ />
                </div>
              </div>
            </div>
          </div>
        </div>

      </Elements>

    </React.Fragment>
  )
};

export default StripePaymentForm;
