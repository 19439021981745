import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { ValidateList, ValidationTypes } from "../../Utils/ValidationHelper";
import { setCookie } from "../../helpers/authUtils";
import { postCall } from "../../helpers/axiosUtils";
import { connect } from 'react-redux';
import { loginUserSuccess } from '../../redux/actions';
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import FacebookLogin from 'react-facebook-login';
function ContactUs(props) {
  const history = useNavigate();

  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [cPasswordVisiblity, setCPasswordVisiblity] = useState(false);
  const [socialLoginType, setSocialLoginType] = useState("");

  const Submit = async (e) => {
    e.preventDefault();
    let validate_arr = [
      [
        email,
        ValidationTypes.Email,
        "Please enter a valid email",
      ],
      [reason, ValidationTypes.Empty, "Please enter reason to contact"],
    ];
    let validate = await ValidateList(validate_arr);
    if (!validate) {
      return;
    }
    let obj = {
        email: email
    };
return
    try {
      let resp = await FetchApi(Endpoints.login, obj);
      if (resp && resp.type === "success") {
     
        if (resp.access_token) {
          setCookie('token', resp.access_token)
          
          let options = {
            'content-type': 'application/json',
            AUTHORIZATION: `Bearer ${resp.access_token}`
          };
          const url = Endpoints.baseUrl + Endpoints.userDetail
          postCall(url, {}, { headers: options }).then(r => {
            props.loginUserSuccess(r.data.data);
            // console.log('checkuserlog_', JSON.stringify(r.data.data, null, 4));
            localStorage.setItem('loggedUser', JSON.stringify(r.data.data));
            setCookie('user', JSON.stringify(r.data.data))
            history('/feeds')
          }).catch(error => {
            history('/')
          })
        }
        else {
          ToastMessage.Success(resp.message);
          const data = resp.data;
          let redirectUrl = ''
          if (data.customer_selected_role === 'User') {
            redirectUrl = '/user-complete-profile'
          }
          else if (data.customer_selected_role === 'Coach') {
            redirectUrl = '/coach-profile-complete'
          }
          else if (data.customer_selected_role === 'Facilities') {
            redirectUrl = '/facility-profile-complete'
          }
          data['fname'] = data.name;
          data['mobile'] = data.phone
          data['user_slug'] = { user_slug: data.user_slug }
          history(redirectUrl, { state: data });
        }
      }

      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
      if (resp.status === "false") {
        Swal.fire({
          text: typeof resp.message === 'object' ? resp.message.email[0] : resp.message,
          icon: "error",
        });
      }
    } catch (e) {

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="main py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <h1 className="text-darkgray semibold pb-2 text-center">
                Contact Us!
              </h1>
              {/* <p className="text-green2 text-center mb-3">
                Welcome Back! please enter your login credentials.
              </p> */}

              <form onSubmit={Submit} action="" className="site-form pt-2">
                <div className="form-field mb-4">
                  <label htmlFor="" className="pb-1">
                    Email 
                  </label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                    placeholder="Please Enter Your Email"
                  />
                </div>
                <div className="form-field mb-1 pwd-group position-relative">
                  <label htmlFor="" className="pb-1">
                    Reason
                  </label>
                  <textarea
                  rows={'3'}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    type={'text'}
                    placeholder="Enter reason to contact"
                    className="pwd-field"
                  />
                </div>
                
                <button
                  type="submit"
                  className="butn w-100 rounded-pill border-0 mt-4"
                >
                  Submit
                </button>
              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
