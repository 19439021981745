import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { getAddressDetails, mapConfigs } from '../helpers/authUtils'
import React, { useImperativeHandle, useState, forwardRef } from "react";
const placesLibrary = ["places"];

const LocationAutocomplete = forwardRef((props, ref) =>{
    const mapConfig = mapConfigs()
    const { isLoaded } = useLoadScript({
        libraries: placesLibrary,
        googleMapsApiKey: mapConfig['configKey']
    })
    const [searchResult, setSearchResult] = useState("Result: none");
    const [address, setAddress] = useState(props.address ? props.address : '');
    useImperativeHandle(ref, () => {
        return {
            clearAddress: clearAddress
        }
    });
    const clearAddress = () => {
        setAddress('')
    }
    const onLoad = (autocomplete) => {
        setSearchResult(autocomplete);
    }
    const onPlaceChanged = () => {
        if (searchResult != null) {
            const place = searchResult.getPlace();
            const name = place.name;
            const status = place.business_status;
            const formattedAddress = place.formatted_address;
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setAddress(formattedAddress)
            props?.onLocationChanged?.({ name: formattedAddress, lat: lat, lng: lng, locationIndex: props.locationIndex, place: place })
        } else {
            alert("Please enter text");
        }
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }
    return (
        <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
            <input
                type="text" className="border-0 location-field"
                placeholder="Search Location" value={address} onChange={(e) => { setAddress(e.target.value) }}
            />
        </Autocomplete>
    )
})
export default LocationAutocomplete;