import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ToastMessage from '../Utils/ToastMessage';
import { useNavigate } from 'react-router-dom';

const FacilityCoachModal = ({ show, onClose }) => {
    const history = useNavigate()
;  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{
            // display: 'flex',
            // alignSelf: 'center'
        }}>{" "}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
          <div className='d-flex justify-content-center py-3'>
            <div>
            <p className='pb-1'>
                Do you want to pull out your other reservations?
            </p>

            <div className='d-flex align-items-center justify-content-center pt-3'>
                <Button 
                style={{
                    borderRadius: '20px'
                }}
                className='w-25' 
                variant="secondary" 
                onClick={()=>{
                    history("/booking-summary", {state: {is_show_reservation: false}});
                }}>
                    No
                </Button>
                <Button 
                onClick={()=>{
                    history("/booking-summary", {state: {is_show_reservation: true}});
                }}
                className='butn w-25 ms-3'>
                    Yes
                </Button>
            </div>
            </div>
            </div>
      </Modal.Body>

      {/* <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          No
        </Button>
        <Button variant='Primary' onClick={onClose}>
          Yes
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default FacilityCoachModal;