import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import { getUserToken } from "../helpers/authUtils";

export const useGetChatCount=()=>{
    let token = getUserToken();
    return useQuery({
        queryKey : ["chat_count"],
        enabled: token? true : false,
        queryFn : ()=> FetchApi(Endpoints.GetChatCounter, {}),
        select : (response)=>{
            return response?.data
        }
    })
}

export const useGetChatList=()=>{
    return useQuery({
        queryKey : ["chat_list"],
        queryFn : ()=> FetchApi(Endpoints.GetChatAllRoom),
        refetchOnWindowFocus: false,
        select : (response)=>{
            return response?.data
        }
    })
}

// export const useGetChatList=()=>{
//     return useMutation({
//         mutationKey : ["chat_list"],
//         mutationFn : ()=> FetchApi(Endpoints.GetChatAllRoom),
//         // refetchOnWindowFocus: false,
//         onSuccess : (response)=>{
//             // console.log('resp__', JSON.stringify(response?.data, null, 4));
//             return response?.data
//         }
//     })
// }