import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { FetchApi } from "../../API/FetchApi";
import { setCookie } from "../../helpers/authUtils";
import { useDispatch } from "react-redux";
import { savePendingMessage } from "../../redux/Slices/messageCountSlice";
import { useQueryClient } from "@tanstack/react-query";
import { useGetChatList } from "../../Services/query";

export default function ChatRoomList({ pageType, setActiveChatId, activeChatId, messageStatusChange }) {
    const [chatRoom, setChatRoom] = useState([]);
    const [chatPendingRoom, setChatPendingRoom] = useState([]);
    const [backupchatRoom, setBackupChatRoom] = useState([]);
    const [backupchatPendingRoom, setBackupChatPendingRoom] = useState([]);
    const [user, setUser] = useState("");
    const {data: chatList} = useGetChatList();
    // const {mutate: getChatList, data: chatList} = useGetChatList();
    const [isZero, setZero] = useState(true);
    const location = useLocation();
    const dispath = useDispatch();
    const queryClient = useQueryClient();

    // console.log('chatList_', JSON.stringify(chatList, null, 4))

    console.log('loc__', location?.state);
    useEffect(() => {
        setActiveChatId(0);
        // getChatList();
        // getChatRoomList();
    }, []);
    useEffect(() => {
        setActiveChatId(0);
        // getChatRoomList();
        queryClient.invalidateQueries('chat_list');
    }, [messageStatusChange]);

    useEffect(()=>{
        if(chatList){
        let chatPending = [];
        let chatApprove = [];
        chatList?.map((item) => {
            if (item?.status == "pending") {
                chatPending = [...chatPending, item];
            } else {
                chatApprove = [...chatApprove, item];
            }
        });
        if(location.pathname == "/message-request" ){
            if(chatPending.length > 0){
            setActiveChatId(location?.state?.chatId || chatPending[0]?.id);
            }
        }
        if(location.pathname == "/message"){
            if(chatApprove.length > 0){
                if(isZero){
                    setActiveChatId(location?.state?.chatId || chatApprove[0]?.id);
                    setZero(false);
                }
            }
        }
        const updatedChatRoom = [...chatApprove];
        // updatedChatRoom[0].AuthUserNotReadMsgCount = 0;
        if (updatedChatRoom[0]) {
            updatedChatRoom[0].AuthUserNotReadMsgCount = 0;
        }
        setChatRoom(updatedChatRoom);
        //  setChatRoom(chatApprove);
        setChatPendingRoom(chatPending);
        setBackupChatRoom(chatApprove);
        setBackupChatPendingRoom(chatPending);
        }
    },[chatList])

    const getChatRoomList = async (e) => {
        try {
            let resp = await FetchApi(Endpoints.GetChatAllRoom);
            if (resp && resp.status === "success") {
                let chatPending = [];
                let chatApprove = [];
                resp.data?.map((item) => {
                    if (item?.status == "pending") {
                        chatPending = [...chatPending, item];
                    } else {
                        chatApprove = [...chatApprove, item];
                    }
                });
                if(location.pathname == "/message-request" ){
                    if(chatPending.length > 0){
                    setActiveChatId(location?.state?.chatId || chatPending[0]?.id);
                    }
                }
                if(location.pathname == "/message"){
                    if(chatApprove.length > 0){
                        setActiveChatId(location?.state?.chatId || chatApprove[0]?.id);
                    }
                }
                const updatedChatRoom = [...chatApprove];
                updatedChatRoom[0].AuthUserNotReadMsgCount = 0;
                setChatRoom(updatedChatRoom);
                //  setChatRoom(chatApprove);
                 setChatPendingRoom(chatPending);
                 setBackupChatRoom(chatApprove);
                 setBackupChatPendingRoom(chatPending);
            }


        } catch (e) {
            if (e && e.response && e.response.message && e.response.message.email) {
                Swal.fire({
                    text: "This email is already used",
                    icon: "error",
                });
            }
        }
    };

    const handleSearch = (value) =>{
        console.log('value', value)
        if(location.pathname == "/message"){
            if (value) {
            setChatRoom(chatRoom.filter((chat) => {
                // console.log("chat", chat)
                return (
                    chat?.room_participants[0]?.user.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
                 
                );
              })
            );
            }else{
                setChatRoom(backupchatRoom);
            }
        }else{
            if (value) {
            setChatPendingRoom(chatPendingRoom.filter((chat) => {
                return (
                    chat?.room_participants[0]?.user.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
                 
                );
              })
            );
            }else{
                setChatPendingRoom(backupchatPendingRoom);
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <div 
        className="col-md-4 pe-md-4"
        >
            <div 
            className="pb-3 d-flex align-items-center gap-2"
            >
                <form
                    className="message-search d-flex flex-row-reverse align-items-center justify-content-end w-100 "
                >
                    <input
                        type="text"
                        placeholder="Search"
                        name="search"
                        className="bg-transparent fs-14 medium border-0"
                        onChange={(e)=>{
                            handleSearch(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                    />
                    {/* <button
                        type="submit"
                        className="bg-transparent border-0 ps-0"
                    > */}
                        <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            stroke="#949494"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="css-i6dzq1"
                        >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                    {/* </button> */}
                </form>
            </div>

            <div className="message-request d-flex gap-4 pb-3">
                <Link className={location.pathname == "/message" ? "active regular fs-16" : "regular fs-16"} to={"/message"}>Inbox</Link>
                <Link className={location.pathname == "/message-request" ? "active fs-16 regular" : "fs-16 regular"} to={"/message-request"}>Message Request({chatPendingRoom.length})</Link>
            </div>

            <div 
            className="msg-left-sidebar inbox-msg-list mb-5"
            >
                {pageType == "approve" ?
                    <ul className="user-messages ">
                        {
                            chatRoom.length > 0 ?
                                chatRoom.map((item, index) => {

                                    return (
                                        <li 
                                        className="message-item p-3 d-flex align-items-center userchat gap-2 c-p" key={index} 
                                        onClick={()=>{
                                            setActiveChatId(item?.id);
                                            queryClient.invalidateQueries('chat_count');
                                            
                                            const updatedChatRoom = [...chatRoom];
                                            if(updatedChatRoom[index].AuthUserNotReadMsgCount != 0){
                                                updatedChatRoom[index].AuthUserNotReadMsgCount = 0;
                                                setChatRoom(updatedChatRoom);
                                            }
                                            
                                            // getChatRoomList(); 
                                            // dispath(savePendingMessage(Math.random()));
                                            // setCookie('countchangestatus', Math.random())
                                        }}>
                                            <div 
                                            style={{
                                                width: '60px',
                                                height: '60px'
                                            }}
                                            className="position-relative">
                                                <img
                                                    src={item?.room_participants[0]?.user?.image}
                                                    alt=""
                                                    style={{
                                                        width: '100%',
                                                        height: '100%', 
                                                        objectFit: 'cover',
                                                        borderRadius: '50%'
                                                    }}
                                                    // width="60"
                                                    // height="60"
                                                    // className="rounded-circle"
                                                    // className="rounded-pill"
                                                />
                                                {item?.room_participants[0]?.user?.isCurrentlyLoggedIn === true ?
                                                    <span className="user-status">
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            width="12"
                                                            height="12"
                                                            stroke="#fff"
                                                            stroke-width="1"
                                                            fill="#61F100"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    </span>
                                                    :
                                                    <span className="user-status">
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            width="12"
                                                            height="12"
                                                            stroke="#fff"
                                                            stroke-width="1"
                                                            fill="#E20000"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    </span>
                                                }
                                            </div>
                                            <div className="w-100 d-flex justify-content-between"> 
                                                <div>
                                                    <h6 className="medium text-darkgray fs-16 pb-1">
                                                        {item?.room_participants[0]?.user?.name} {item?.room_participants[0]?.user?.last_name}
                                                    </h6>
                                                    <h6 className="fs-13 regular text-darkgray">
                                                        {/* {item?.messages_single?.msg} */}

                                                        {item?.messages_single?.msg_type === "image" ? 
                                                            <p>
                                                                 <img src="assets/img/icon/image.svg" alt="" width={"10"} height={"30"}/>
                                                            </p>
                                                            : 
                                                            item?.messages_single?.msg_type === "file" ? 
                                                                <img src="assets/img/icon/file.svg" alt="" width={"10"} height={"30"}/>                                                           
                                                            :
                                                            item?.messages_single?.msg_type === "video" ? 
                                                                <img src="assets/img/icon/video.svg" alt="" width={"10"} height={"30"}/>
                                                            :
                                                            <p style={{width: '200px'}} 
                                                            className="text-truncate"
                                                            >
                                                                {item?.messages_single?.msg}
                                                            </p>
                                                            
                                                            }
                                                    </h6>
                                                </div>
                                                <div className="text-end">
                                                    {item?.AuthUserNotReadMsgCount > 0 ? item?.AuthUserNotReadMsgCount : ""}
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                <li className="message-item p-3 d-flex align-items-center userchat gap-2">
                                    Chat Room Not Found
                                </li>
                        }


                    </ul>
                    :
                    <ul className="user-messages ">
                        {
                            chatPendingRoom.length > 0 ?
                                chatPendingRoom.map((item, index) => {

                                    return (
                                        <li className="message-item p-3 d-flex align-items-center userchat gap-2 c-p" key={index} 
                                        onClick={()=>{
                                            setActiveChatId(item?.id)  
                                        }}
                                        >
                                            <div 
                                            style={{
                                                width: '60px',
                                                height: '60px'
                                            }}
                                            className="position-relative">
                                                <img
                                                    src={item?.room_participants[0]?.user?.image}
                                                    alt=""
                                                    style={{
                                                        width: '100%',
                                                        height: '100%', 
                                                        objectFit: 'cover',
                                                        borderRadius: '50%'
                                                    }}
                                                    // width="60"
                                                    // height="60"
                                                    // className="rounded-circle"
                                                    // className="rounded-pill dp-border"
                                                />
                                                {item?.room_participants[0]?.user?.isCurrentlyLoggedIn === true ?
                                                    <span className="user-status">
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            width="12"
                                                            height="12"
                                                            stroke="#fff"
                                                            stroke-width="1"
                                                            fill="#61F100"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    </span>
                                                    :
                                                    <span className="user-status">
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            width="12"
                                                            height="12"
                                                            stroke="#fff"
                                                            stroke-width="1"
                                                            fill="#E20000"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    </span>
                                                }
                                            </div>
                                            <div className="w-100">
                                                <div className=" w-100">
                                                    <h6 className="medium text-darkgray fs-16 pb-1">
                                                        {item?.room_participants[0]?.user?.name} {item?.room_participants[0]?.user?.last_name}
                                                    </h6>
                                                    <h6 
                                                    style={{width: '200px'}}
                                                    className="fs-13 regular text-darkgray text-truncate">
                                                        {/* {item?.messages_single} */}
                                                        {item?.messages_single?.msg || ''}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                <li className="message-item p-3 d-flex align-items-center userchat gap-2">
                                   No Message Request
                                </li>
                        }


                    </ul>

                }
            </div>
        </div>
    )
}
