"use client";
import { ClipLoader } from "react-spinners";

export default function Loader({isButton, color}) {
    return <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: isButton? '': '100vh',
        paddingInline: isButton?'10px' : ''
    }}>
        <ClipLoader size={25} color={color || "white"} />
    </div>
}