import React, { useEffect, useState } from 'react'
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import moment from "moment";
export default function AdaList() {
    const [List, setList] = useState([]);
    const [Pagination, setPagination] = useState({});
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            let resp = await CommonAPi(Endpoints.GetAdvertisements, {});
            if (resp && resp.status === "success") {
                setList(resp.data?.data);
                setPagination(resp.data);
            }
        } catch (e) {
            if (e && e.response && e.response.message) {
                Swal.fire({
                    text: e.response.message,
                    icon: "error",
                });
            }
        }
    };
    const ChangeStatus = async (Id, Status) => {
        let data = {
            id:Id,
            status:Status
        };
        try {
            let resp = await CommonAPi(Endpoints.ChangeAdvertisementStatus, data);
            if (resp && resp.status === "success") {
                getData();
                Swal.fire({
                    text: "Advertisement " + Status + " Successfully",
                    icon: "success",
                });
            }
        } catch (e) {
            if (e && e.response && e.response.message) {
                Swal.fire({
                    text: e.response.message,
                    icon: "error",
                });
            }
        }
    };
    return (
        <div className="wrapper">
            <div className="main py-5">
                <div className="container">
                    <h1 className="fs-24 medium pb-2">
                     
                        Manage Ads
                        <Link to={"/create-advertisement"} className="ms-2 butn py-2 ">
                            Add Ads
                        </Link>
                    </h1>

                    <table className="job-table w-100">
                        <thead>
                            <tr className="fs-14 text-green table-head bg-lightgreen">
                                <th>Title</th>
                                <th>Ads For</th>
                                <th>Type</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>View Count</th>
                                <th>Lead Count</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                List.length > 0 ?
                                    List?.map((item, index) => {
                                        return (
                                            <tr key={index} className="border">
                                                <td>
                                                    <h5 className="medium pb-2">{item?.add_title}</h5>
                                                </td>

                                                <td>
                                                    <h6 className="medium pb-2 "
                                                        style={{
                                                            textTransform: "capitalize"
                                                        }}>
                                                        {item?.add_for}
                                                    </h6>
                                                </td>
                                                <td>
                                                    <h6 className="medium"
                                                        style={{
                                                            textTransform: "capitalize"
                                                        }}
                                                    >
                                                        {item?.add_type}
                                                    </h6>
                                                </td>

                                                <td>
                                                    <h6 className="medium">
                                                        {item?.add_start_date}
                                                    </h6>

                                                </td>
                                                <td>
                                                    <h6 className="medium">
                                                        {item?.add_expire_date}
                                                    </h6>

                                                </td>
                                                <td>
                                                    <h6 className="medium">
                                                        {item?.advertisement_view_counts_count}
                                                    </h6>

                                                </td>
                                                <td>
                                                    {item?.add_type == "lead" ?  
                                                    <Link to={"/lead/" + item?.id}>
                                                    <h6 className="medium fs-14 text-primary ">
                                                        {item?.advertisement_leads_count }
                                                    </h6>
                                                    </Link>
                                                    : "-" }
                                                </td>
                                                <td>
                                                    <h6 className="medium"
                                                    style={{
                                                        textTransform: "capitalize"
                                                    }}
                                                    >
                                                         {moment().format("YYYY-MM-DD") <= moment(item.add_expire_date).format("YYYY-MM-DD") ?
                                                            item?.status : "Expire" }
                                                        
                                                    </h6>

                                                </td>

                                                <td>
                                                    <div className="d-flex flex-column flex-md-row gap-2">
                                                        <Link
                                                        to={`/view-advertisement/${item?.id}`}
                                                        className="job-action-btn"
                                                        >
                                                        <img src="assets/img/icon/eye.svg" className="" />
                                                        </Link>
                                                        <Link
                                                            to={`/edit-advertisement/${item?.id}`}
                                                            className="job-action-btn"
                                                        >
                                                            <img
                                                                src="../assets/img/icon/edit-2.svg"
                                                                className="icon-green"
                                                            />
                                                        </Link>
                                                        {moment().format("YYYY-MM-DD") <= moment(item.add_expire_date).format("YYYY-MM-DD") ?
                                                        item?.status == "active" ? 
                                                        <Link
                                                            className="job-action-btn"
                                                            onClick={()=>{
                                                                ChangeStatus(item?.id, "inactive")
                                                            }}
                                                        >
                                                             <i class="fa fa-ban text-green" aria-hidden="true"></i>
                                                           
                                                        </Link>
                                                        : 
                                                        <Link
                                                            className="job-action-btn"
                                                            onClick={()=>{
                                                                ChangeStatus(item?.id, "active")
                                                            }}
                                                        >
                                                            <i class="far fa-badge-check text-green"></i>
                                                        </Link>
                                                        : ""
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    :
                                    <tr>
                                        <td>
                                            No Advertisement found
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                    {Pagination.length > 0 && (
                        <div className="paginations text-start py-3">
                            <span className="me-2">
                                Page 1 of{" "}
                                {Math.ceil(Pagination.total / Pagination.per_page)}
                            </span>
                            {Pagination.links.map((item, i) => {
                                return item.url ? (
                                    <React.Fragment key={i}>
                                        <Link
                                            className={item.active ? "page-active" : ""}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                getData(item.url);
                                            }}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{ __html: item.label }}
                                            ></span>
                                        </Link>
                                    </React.Fragment>
                                ) : null;
                            })}
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}
