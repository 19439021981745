import React, { useState } from "react";
import ChatRoomList from "./ChatRoomList";
import ChatMessage from "./ChatMessage";


function UserMessages() {
  const [activeChatId, setActiveChatId] = useState(0);
  const [messageStatusChange, setMessageStatusChange] = useState(false);

  console.log('activechatid__', activeChatId);
  return (
    <div className="wrapper">
      {/* <Header /> */}
      <div className="main py-5">
        <div className="container">
          <div className="row ">
              <ChatRoomList 
              {...{
                pageType:"approve",
                setActiveChatId:setActiveChatId,
                activeChatId:activeChatId,
                messageStatusChange:messageStatusChange,
              }}/>
        
              <ChatMessage 
              {...{
                pageType:"approve",
                activeChatId:activeChatId,
                setMessageStatusChange:setMessageStatusChange,
                
              }}
              />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default UserMessages;

