import React, { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import OTPInput from "otp-input-react";

function VerifyOtp() {
  const history = useNavigate();
  let data = useLocation();
  const [OTP, setOTP] = useState("");
  let email = data.state;
  let parm = {
    email: email,
    otp: OTP,
  };
  const forgot = async (e) => {
    e.preventDefault();
    let obj = {
      email: data.state,
      stage: "verify",
      otp_token: OTP,
    };

    try {
      let resp = await FetchApi(Endpoints.forgotPassword, obj);
      if (resp && resp.status === "success") {
        ToastMessage.Success(resp.message);
      }
      history("/set-new-Password", { state: parm });
    } catch (e) {
      console.log("forgotErr__", JSON.stringify(e.response, null, 4));
      ToastMessage.Error(e.message);
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  return (
    <div className="main py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <h1 className="text-darkgray semibold pb-2 text-center">
                Verify Your Email
              </h1>
              <p className="text-center">
                We have sent a 6 digit OTP to
                <span className="medium d-block text-gray1">
                  {data.state}
                </span>
              </p>
              <form onSubmit={forgot} className="site-form pt-2">
                <div className="form-field pb-4 otp-field d-flex gap-2 gap-lg-3 justify-content-center">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    inputClassName="custom-otp-input"
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                  />
                </div>
                <button type="submit" disabled={!OTP || (OTP && OTP.toString().length !=6)}
                  className="butn w-100 rounded-pill border-0"
                >
                  Reset Password
                </button>
              </form>

              <div className="text-center pt-3">
                <Link to={"/Login"} className="text-green py-3 medium">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default VerifyOtp;
