import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Endpoints } from "../../API/Endpoints";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import Swal from "sweetalert2";
import { connect } from "react-redux";

function ManageGallery(props) {
  const [galleryList, setGalleryList] = useState(props.user.facility_image ? [...props.user.facility_image] : [])
  const onPictureChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const formData = new FormData();
      for (const image of event.target.files) {
        formData.append("facility_images[]", image);
      }
      try {
        let resp = await CommonAPi(Endpoints.addFacilityImages, formData);
        if (resp && resp.status === "success") {
          ToastMessage.Success(resp.message);
          document.getElementById('file').value = ""
          getGalleryList()
        }
        if (resp && resp.message && resp.message.email) {
          Swal.fire({
            text: "This email is already used",
            icon: "error",
          });
        }
      } catch (e) {
        if (e && e.response && e.response.message && e.response.message.email) {
          Swal.fire({
            text: "This email is already used",
            icon: "error",
          });
        }
      }
    }
  }
  const getGalleryList = async () => {
    try {
      let resp = await FetchApi(Endpoints.userDetail, { slug: props.user.slug });
      if (resp && resp.type === "success") {
        setGalleryList(resp.data.facility_image)
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }
  const deleteItem = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const postData = { facility_image_id: item.id }
          FetchApi(Endpoints.removeFacilityImage, postData).then((resp) => {
            if (resp && resp.status === "success") {
              let galleryItems = [...galleryList]
              setGalleryList(galleryItems.filter(i => i.id != item.id));
              ToastMessage.Success(resp.message);
            }
            if (resp && resp.message && resp.message.email) {
              Swal.fire({
                text: "Failed to delete the post",
                icon: "error",
              });
            }
          })
        } catch (e) {
          if (e && e.response && e.response.message && e.response.message.email) {
            Swal.fire({
              text: "Failed to delete the post",
              icon: "error",
            });
          }
        }
      }
    });
  };
  useEffect(() => {
    //getGalleryList()
  }, []);
  return (
    <div className="main py-lg-5 py-4 align-items-start">
      <div className="container">
        <div className="row justify-content-center px-3">
          <div className="border rounded-8 p-3 col-lg-8 col-md-10 col-sm-11">
            <h1 className="fs-24 medium pb-3">Manage Gallery</h1>

            <div className="row gx-3 m-0 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2">
              <div className="mb-3">
                <input
                  multiple
                  name="myImage"
                  type="file"
                  className="d-none"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    onPictureChange(e);
                  }}
                />
                <label
                  htmlFor="file"
                  className="custom-pic text-green p-3 fs-13 light text-center  w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                >
                  <img
                    src="assets/img/icon/upload-picture.svg"
                    className="d-block mx-auto mb-2"
                    width="40"
                    alt=""
                  />
                  Upload Pictures
                </label>
              </div>
              {galleryList.map((item, i) => {
                return (<div className="gallery-box position-relative mb-3" key={i}>
                  <a onClick={(e) => {
                    e.preventDefault();
                    deleteItem(item)
                  }} className="gal-remove position-absolute m-2 me-3 top-0 end-0 bg-white c-p">
                    <img src="assets/img/icon/trash.svg" width="16" alt="" />
                  </a>
                  <img
                    src={item.image}
                    className="gal-edit-img"
                    alt=""
                  />
                </div>)
              })}
            </div>
          </div>
          {/* <div className="mt-3 text-center group-butn">
            <Link className="butn gray-butn ">Cancel</Link>
            <Link className="butn">Save</Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(ManageGallery);
