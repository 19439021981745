import React, { useEffect, useState } from 'react';

const WeekdayButtons = ({ selectedDays, handleChange }) => {
  const days = [
    { abbr: 'mon', full: 'Monday' },
    { abbr: 'tue', full: 'Tuesday' },
    { abbr: 'wed', full: 'Wednesday' },
    { abbr: 'thu', full: 'Thursday' },
    { abbr: 'fri', full: 'Friday' },
    { abbr: 'sat', full: 'Saturday' },
    { abbr: 'sun', full: 'Sunday' },
  ];

  const [selectedDaySet, setSelectedDaySet] = useState(new Set(selectedDays ? selectedDays.split(',') : []));

  const handleButtonClick = (day) => {
    const newSelectedDaySet = new Set(selectedDaySet);
    if (newSelectedDaySet.has(day)) {
      newSelectedDaySet.delete(day);
    } else {
      newSelectedDaySet.add(day);
    }
    setSelectedDaySet(newSelectedDaySet);
    handleChange({ target: { name: 'repeat_days', value: Array.from(newSelectedDaySet).join(',') } });
  };

  useEffect(() => {
    if (selectedDays) {
      setSelectedDaySet(new Set(selectedDays.split(',')));
    }
  }, [selectedDays]);

  return (
    <>
    <label className="mb-2">Repeat</label>
    <div className="d-flex mb-3">
      {days.map((day, index) => (
        <button
          key={index}
          className={`btn btn-outline-secondary mx-1 ${selectedDaySet.has(day.abbr) ? 'bg-success text-white' : ''}`}
          style={{
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
          }}
          onClick={() => handleButtonClick(day.abbr)}
        >
          {day.full.substring(0, 1).toUpperCase()}
        </button>
      ))}
    </div>
    </>
  );
};

export default WeekdayButtons;
