import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import CountDownRedirect from '../../Components/CountDown';
import moment from 'moment';
import { FetchApi } from '../../API/FetchApi';
import { Endpoints } from '../../API/Endpoints';
import Swal from 'sweetalert2';
import CreateReferAvailability from '../../Components/CreateReferAvail';

function PaymentSuccess() {
	const [searchParams] = useSearchParams();
	const [redirectUrl, setRedirectUrl] = useState('/my-bookings');
    const [modalIsOpen, setModalIsOpen] = useState(false);
	const waitTime = 10000;
    const history = useNavigate();

    const [paymentIntent, setPaymentIntent] = useState('');
    const coachData = JSON?.parse(localStorage.getItem('coachData'));
    const eventData = JSON?.parse(localStorage.getItem('eventData'));
    const props = JSON?.parse(localStorage.getItem('bookingCreateData'));

    console.log('prop__', props);
    console.log('propamt__', props?.amount);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        const paymentIntentValue = queryParams.get('payment_intent');

        setPaymentIntent(paymentIntentValue);
    }, []);

    useEffect(()=>{
        if(paymentIntent){
            createBooking();
        }
    }, [paymentIntent])

    const getUserInfo = async () => {
        try {
          let resp = await FetchApi(Endpoints.userDetail, {slug:props.user?.slug});
          if (resp && resp.type === "success") {
            history('/my-bookings', {replace: true})
            props.loginUserSuccess(resp.data)
          }
        }
        catch (e) {
        }
    };

    const createBooking = async () => {
        const postObject = {
          payment_transaction_id: paymentIntent,
          coach_facility_id: coachData.id,
          availability_id: eventData?.id,
          availability_date: moment(eventData?.start)?.format('YYYY/MM/DD'),
          is_used_reward_checked: props?.bookingInfo?.is_used_reward_checked ? '1' : '0',
          tax_amount: props?.bookingInfo?.taxAmount,
          total_amount: props.amount,
          is_show_reservation: props?.bookingInfo?.is_show_reservation ? '1' : '0'
        }
        if (props.bookingInfo.kidInfo) {
          postObject['user_kid_id'] = props.bookingInfo.kidInfo.id
        }
        if (props.bookingInfo.isReschedulBooking) {
          postObject['booking_id'] = props.bookingInfo.bookingId;
        }
        try {
          let resp = await FetchApi(props.bookingInfo.isReschedulBooking ? Endpoints.reschedulBooking : Endpoints.createBooking, postObject);
          if (resp && resp.type === "success") {
            if(eventData?.slot_type == "fragmented" && 
              props.user?.user_role?.id == 3 && 
              props?.bookingInfo?.is_show_reservation
            ){
              setModalIsOpen(true);
            }
            else{
              getUserInfo();
            }
          }
          else {
            Swal.fire({
              text: JSON.stringify(resp.message),
              icon: "error",
            });
          }
        }
        catch (e) {
        }
    };

    console.log('search__', paymentIntent)

	return (
		<div className='wrapper'>
			<div className='main pt-5 main-login'>
				<div className='container pt-5'>
					<div className='row'>
						<div className=' d-flex flex-column align-items-center'>
							<h2 className='mx-5 my-5 text-center'>
								{'Your Payment is Successful!'}
							</h2>
							{/* <CountDownRedirect
								timeInSeconds={waitTime / 1000}
								redirectUrl={redirectUrl}
							/> */}
						</div>
					</div>
				</div>
			</div>
            <CreateReferAvailability
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            eventDetails={eventData}
            />
		</div>
	);
}

export default PaymentSuccess;
