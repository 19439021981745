import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ValidateList, ValidationTypes } from "../../Utils/ValidationHelper";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Swal from "sweetalert2";
import { FetchApi } from "../../API/FetchApi";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { GoogleMap, useJsApiLoader, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import { getAddressDetails, mapConfigs } from '../../helpers/authUtils'
import LocationAutocomplete from "../../Components/LocationAutocomplete";
import { loginUserSuccess } from '../../redux/actions';
import { connect } from "react-redux";
const libraries = ["places"];


function UserCompleteProfile(props) {
  const history = useNavigate();
  const userSlug = useLocation();
  const stateParam = userSlug.state;
  const [isEdit, setPageMode] = useState(stateParam?.edit ? true : false)
  const user = isEdit ? stateParam.user : stateParam;
  const [firstName, setFirstName] = useState(isEdit ? user.name : user?.fname);
  const [lastName, setLastName] = useState(isEdit ? user?.last_name : user?.lname);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(isEdit ? user?.phone : user?.mobile);
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState(isEdit ? user.country: '');
  const [state, setState] = useState(isEdit ? user.state : '');
  const [countryCode, setCountryCode] = useState(isEdit ? user?.phone_code : user?.phone_code);
  const [about, setAbout] = useState(isEdit ? user.about : "");
  const [address, setAddress] = useState(isEdit ? user.address : "");
  const [city, setCity] = useState(isEdit ? user.city: '');
  const [image, setImage] = useState(isEdit ? user.image : '');
  const [zip, setZip] = useState(isEdit ? user.zip_code : '');
  const [imageData, setImageData] = useState("");
  const [sports, setSports] = useState(isEdit ? user.sport_id : "");
  const [latitude, setLatitude] = useState(isEdit ? user.latitude : "");
  const [longitude, setLongitude] = useState(isEdit ? user.longitude : "");
  const mapConfig = mapConfigs()
  const [sportsData, setSportsData] = useState([]);

  console.log('abcd___', JSON.stringify(stateParam, null, 4))
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageData(event.target.files[0]);
    }
  };
  const onLocationChanged = (data) => {
    setLatitude(data.lat)
    setLongitude(data.lng)
    const place = data.place
    const mapObject = getAddressDetails(place.address_components)
    setAddress(place.name)
    setCity(mapObject.city ? mapObject.city : '')
    setState(mapObject.state ? mapObject.state : '')
    setCountry(mapObject.country ? mapObject.country : '')
    setZip(mapObject.zipcode ? mapObject.zipcode : '')
  };
  const handleOnChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.dialCode);
  };

  // const phoneNumberWithoutCode = phone?.replace(countryCode, "");

  const register = async (e) => {
    e.preventDefault();
    let validate_arr = [
      [firstName, ValidationTypes.Empty, "Please enter first name"],
      [lastName, ValidationTypes.Empty, "Please enter last name"],
      [email, ValidationTypes.Email, "Please enter a valid email"],
      [phone, ValidationTypes.Mobile, "Please enter a valid mobile number"],
      [address, ValidationTypes.Empty, "Please enter a valid address"],
      [image, ValidationTypes.Empty, "Please select the image"],
      [about, ValidationTypes.Empty, "Please enter the about detail"],
      [sports, ValidationTypes.Empty, "Please select sports detail"],
      [city, ValidationTypes.Empty, "Please enter city"],
    ];
    let validate = await ValidateList(validate_arr);
    if (!validate) {
      return;
    }
    let dat = new FormData();
    dat.append("name", firstName);
    dat.append("last_name", lastName);
    dat.append("image", imageData);
    dat.append("email", email);
    dat.append("user_slug", isEdit ? user.slug : user.user_slug.user_slug);
    dat.append("address", address);
    dat.append("phone_code", countryCode);
    dat.append("country_id", 1);
    dat.append("country", country);
    dat.append("state", state);
    dat.append("city", city);
    dat.append("zip_code", zip);
    if(latitude){
      dat.append("latitude", latitude.toString().slice(0,9));
    }
    if(longitude){
      dat.append("longitude", longitude.toString().slice(0,9));
    }
    dat.append("about", about);
    dat.append("password", password);
    dat.append("phone", phone);
    dat.append("sport_id", sports);

  
    try {
      let resp = await FetchApi(Endpoints.updateUserDetail, dat);
      if (resp && resp.status === "success") {
        ToastMessage.Success(resp?.message);
        // ToastMessage.Success(isEdit ? resp.message : 'Your profile setup completed');
        if (isEdit) {
          props.loginUserSuccess(resp.data)
        }
        history(isEdit ? '/profile' : '/login');

      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is not exist",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const getSportsList = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.getSports);
      if (resp && resp.status === "success") {
        setSportsData(resp.data);
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  useEffect(() => {
    //CoachList();
    getSportsList();
    if (userSlug.state === null) {
      history('/login')
    }
  }, []);
  return (
    <div className="main py-5">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-6 col-md-8">
            <div className="position-relative profile-thumb overflow-hidden rounded-circle mb-4">
              {/* <input
                  onChange={onImageChange}
                  type="file"
                  id="profile-field"
                  className="d-none"
                  accept=".jpg "
                /> */}
              <input
                onChange={onImageChange}
                type="file"
                accept=".png, .jpg, .jpeg"
                id="profile-field"
                className="d-none"
              />
              <img
                src={image ? image : "assets/img/edit-profile.png"}
                className="profile-pic rounded-circle"
                alt=""
              />
              <label
                htmlFor="profile-field"
                className="profile-upload d-flex align-items-center justify-content-center start-0 bottom-0 position-absolute"
              >
                <img src="assets/img/icon/cover-picture.svg" alt="" />
              </label>
            </div>
            <form onSubmit={register} className="site-form pt-2">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <label htmlFor="" className="pb-1">
                      First Name
                    </label>
                    <input
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      placeholder="Enter your first name"
                      value={firstName}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <label htmlFor="" className="pb-1">
                      Last Name
                    </label>
                    <input
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      placeholder="Enter your Last name"
                      value={lastName}
                    />
                  </div>
                </div>
              </div>
              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Email Address
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  className="bg-lightgreen border-0"
                  placeholder="Enter email address" readOnly={true}
                  value={email}
                />
              </div>
              <div className=" mb-3">
                <label htmlFor="" className="pb-1">
                  Contact Number
                </label>
                <div className="d-flex custom-select">
                  <div className="form-field d-flex align-items-center field-phone w-100">
                    <PhoneInput
                      containerClass=""
                      country={"us"}
                      value={phone}
                      onChange={handleOnChange}
                      inputClass="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  About
                </label>
                <textarea
                  onChange={(e) => setAbout(e.target.value)}
                  name="the-textarea"
                  cols="10"
                  rows="4"
                  placeholder="Write about yourself.."
                  value={about}
                ></textarea>
              </div>

              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Sports
                </label>
                <select
                  value={sports}
                  onChange={(e) => setSports(e.target.value)}
                  name=""
                  id=""
                ><option value="">Select Sports</option>
                  {sportsData.map((item, i) => {
                    return (<option key={i} value={item.id}>{item.name}</option>)
                  })}
                </select>
              </div>
              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Address
                </label>
                <div className="map-filed">
                  <LocationAutocomplete onLocationChanged={onLocationChanged} address={address}></LocationAutocomplete>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <label htmlFor="" className="pb-1">
                      City
                    </label>
                    <input
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      type="text"
                      placeholder="Enter City"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <label htmlFor="" className="pb-1">
                      State
                    </label>
                    <input
                      value={state}
                      onChange={(e) => setAddress(e.target.value)}
                      type="text"
                      placeholder="State"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-field mb-2">
                    <label htmlFor="" className="pb-1">
                      Country
                    </label>
                    <input
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      type="text"
                      placeholder="State"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-field mb-2">
                    <label htmlFor="" className="pb-1">
                      Zip Code
                    </label>
                    <input
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                      type="text"
                      placeholder="Enter zip code"
                    />
                  </div>
                </div>
              </div>

              <div className="btn-group d-flex gap-3 justify-content-center">
                <button className="gray-butn butn  rounded-pill border-0" onClick={(e) => {
                  e.preventDefault()
                  history(isEdit ? '/profile' : '/signup');
                }}>
                  Back
                </button>
                <button
                  type="submit"
                  className="butn rounded-pill border-0"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    isLoading: state.Auth.isLoading
  }
}

export default connect(mapStateToProps, { loginUserSuccess })(UserCompleteProfile);
