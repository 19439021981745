import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
// import { persistor, store } from './redux/store';
import { Cookies } from "react-cookie";
import { getUserToken } from './helpers/authUtils';
import { postCall } from './helpers/axiosUtils';
import { Endpoints } from './API/Endpoints';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient()
// app inital context
function initalRender(data) {
  // console.log('google__id______', process.env.REACT_APP_GOOGLE_ID);
  root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
    <Provider store={configureStore()}>
    {/* <Provider store={store}> */}
      {/* <PersistGate persistor={persistor}> */}
      <QueryClientProvider client={queryClient}>
      <App userData={data} />
      </QueryClientProvider>
      {/* </PersistGate> */}
    </Provider>
    </GoogleOAuthProvider>
  );
}
function deleteAllCookies() {
  return new Promise((resolve, reject) => {
    let cookies = new Cookies();
    cookies.remove("token", { path: "/" });
    resolve(true)
  })

}

let token = getUserToken();
if (token) {
  let options = {
    'content-type': 'application/json',
    AUTHORIZATION: `Bearer ${token}`
  };
  const url = Endpoints.baseUrl + Endpoints.userDetail
  postCall(url,{}, { headers: options }).then(r => {
    if (r.data.message === 'Token is Expired') {
      deleteAllCookies().then(r => {
        initalRender(null)
      });
    }
    else {
      initalRender(r.data.data)
    }
  }).catch(error => {
    deleteAllCookies().then(r => {
      initalRender(null)
    });
  })
}
else {
  initalRender(null);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
