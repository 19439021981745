import React, { useEffect, useState } from 'react';
import {
  useStripe, useElements,
  CardNumberElement, CardExpiryElement, CardCvcElement,
  PaymentElement
} from '@stripe/react-stripe-js';
import { stripePaymentMethodHandler } from './script';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from "../../API/Endpoints";
import { FetchApi } from "../../API/FetchApi";
import { connect } from 'react-redux';
import Swal from "sweetalert2";
import ToastMessage from '../../Utils/ToastMessage';
import { loginUserSuccess } from '../../redux/actions';
import moment from 'moment';
import CreateReferAvailability from '../../Components/CreateReferAvail';
import Loader from '../../Components/loader';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CheckoutForm(props) {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useNavigate();
  const [name, setName] = useState(props.user.name);
  const [email, setEmail] = useState(props.user.email);
  const [cardErrorMessage, setCardErrorMsg] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const coachData = JSON?.parse(localStorage.getItem('coachData'));
  const eventData = JSON?.parse(localStorage.getItem('eventData'));

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading2(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  // console.log('id___coach', coachData.id)
  const handleSubmit = async (event) => {
    event.preventDefault();
    localStorage.setItem('bookingCreateData', JSON.stringify(props));
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setErrorMsg('');

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: Endpoints.paymentBaseUrl + 'payment-success',
      },
    });

    // console.log('result__', result)

    if (result.error) {
			ToastMessage.Error(result?.error?.message);
      console.log(result.error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
    }

    // const paymentMethodObj = {
    //   type: 'card',
    //   card: elements.getElement(CardNumberElement),
    //   billing_details: {
    //     name,
    //     email
    //   },
    // };
    // const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);

    // stripePaymentMethodHandler({
    //   result: paymentMethodResult,
    //   amount: props.amount
    // }, handleResponse);
  };
  const handleSecurePayment = async (response) => {
    const securepaymentMethodResult = await stripe.handleCardAction(response.payment_intent_client_secret);
    stripePaymentMethodHandler({
      result: securepaymentMethodResult,
      payment_intent_client_secret: response.payment_intent_id
    }, handleResponse);
  }
  // callback method to handle the response
  const handleResponse = response => {
    setLoading(false);
    if (response.requires_action) {
      handleSecurePayment(response)
    }
    if (response.status === 'false') {
      ToastMessage.Error(JSON.stringify(response.message));
      //setErrorMsg(response.message);
      return;
    }
    if (response.type === 'success' && !response.requires_action) {
      createBooking(response)
    }
  };
  const createBooking = async (data) => {
    const postObject = {
      payment_transaction_id: data.paymentTransaction,
      coach_facility_id: coachData.id,
      availability_id: eventData?.id,
      availability_date: moment(eventData?.start)?.format('YYYY/MM/DD'),
      is_used_reward_checked: props?.bookingInfo?.is_used_reward_checked ? '1' : '0',
      tax_amount: props?.bookingInfo?.taxAmount,
      total_amount: props.amount,
      is_show_reservation: props?.bookingInfo?.is_show_reservation ? '1' : '0'
    }
    if (props.bookingInfo.kidInfo) {
      postObject['user_kid_id'] = props.bookingInfo.kidInfo.id
    }
    if (props.bookingInfo.isReschedulBooking) {
      postObject['booking_id'] = props.bookingInfo.bookingId;
    }
    try {
      let resp = await FetchApi(props.bookingInfo.isReschedulBooking ? Endpoints.reschedulBooking : Endpoints.createBooking, postObject);
      if (resp && resp.type === "success") {
        if(eventData?.slot_type == "fragmented" && 
          props.user?.user_role?.id == 3 && 
          props?.bookingInfo?.is_show_reservation
          // eventData?.is_marked_availability
        ){
          setModalIsOpen(true);
        }
        else{
          getUserInfo();
        }
      }
      else {
        Swal.fire({
          text: JSON.stringify(resp.message),
          icon: "error",
        });
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  };

  const getUserInfo = async () => {
    try {
      let resp = await FetchApi(Endpoints.userDetail, {slug:props.user?.slug});
      if (resp && resp.type === "success") {
        props.loginUserSuccess(resp.data)
        history('/my-bookings')
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  };
  const handleCardChange = (e) => {
    setCardErrorMsg(e.error ? e.error : null)
  }
  return (
    <React.Fragment>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Pay</span>
      </h4>
      {loading2? <Loader color={'#548235'}/> : null}
      <form onSubmit={handleSubmit}>
        <PaymentElement 
        // options={{
        //   wallets: {applePay: 'auto', googlePay: 'auto'}
        // }}
        />
        {/* <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-name">Name on card</label>
            <input
              id="cc-name"
              type="text"
              className="form-control"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-email">Email</label>
            <input
              id="cc-email"
              type="text"
              className="form-control"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div> */}

        {/* <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card Number</label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleCardChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="expiry">Expiration Date</label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleCardChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleCardChange}
            />
          </div>
          {cardErrorMessage && cardErrorMessage.type === "validation_error" && <React.Fragment>
            <div className='col-md-12 mb-1'>
              <div className="text-danger mt-2">{cardErrorMessage.message}</div>
            </div>
          </React.Fragment>}
        </div> */}

        <hr className="mb-4" />
        <button className="btn btn-dark w-100 butn" type="submit" disabled={loading}>
          {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `PAY $${props.amount?.toLocaleString("ja-JP")}`}
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
      </form>

      <CreateReferAvailability
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      eventDetails={eventData}
      />
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, { loginUserSuccess })(CheckoutForm);