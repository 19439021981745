import { createSlice } from '@reduxjs/toolkit';


const messageSlice = createSlice({
    name : 'message',
    initialState : {
        pendingMessage : null,
    },
    reducers : {
        savePendingMessage : (state,action)=>{
            state.userInfo = action.payload
        },
    }
})

export const {
    savePendingMessage
} = messageSlice.actions;

export default messageSlice.reducer;