import React, { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ValidateList, ValidationTypes } from "../../Utils/ValidationHelper";
import Swal from "sweetalert2";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import FacebookLogin from 'react-facebook-login';
import {LoginSocialFacebook} from 'reactjs-social-login';
import { FacebookLoginButton } from "react-social-login-buttons";
const $ = window.jQuery;
function Signup() {
  const history = useNavigate();
  const [select, setSelect] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisiblity, setPasswordVisiblity] = useState(false);
  const [cPasswordVisiblity, setCPasswordVisiblity] = useState(false);
  const [state, setState] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [googleResponse, setGoogleResponse] = useState("");
  const [facebookResponse, setFacebookResponse] = useState("");
  const [socialLoginType, setSocialLoginType] = useState("");
  const REDIRECT_URI =
  'https://atrio.dignitech.com/login';

  let userType = [
    {
      to: "/user-complete-profile",
      type: "User",
    },

    {
      to: "/coach-profile-complete",
      type: "Coach",
    },
    {
      to: "/facility-profile-complete",
      type: "Facilities",
    },
  ];
  const handleOnChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.dialCode);
  };

  const onLoginStart = useCallback(() => {
    alert('login start');
  }, []);
  // const phoneNumberWithoutCode = phone.replace(countryCode, "");
  const register = async (e) => {
    e.preventDefault();
    let validate_arr = [
      [firstName, ValidationTypes.Empty, "Please enter first name"],
      // [lastName, ValidationTypes.Empty, "Please enter last name"],
      [email, ValidationTypes.Email, "Please enter a valid email"],
      [phone, ValidationTypes.Mobile, "Please enter a valid mobile number"],
      [password, ValidationTypes.Password, "Please enter a valid password"],
      [
        confirmPassword,
        ValidationTypes.Password,
        "Please enter a valid confirm password",
      ],
    ];
    let validate = await ValidateList(validate_arr);
    if (!validate) {
      return;
    }
    if (password !== confirmPassword) {
      Swal.fire({
        text: "Password doesn't match. Please re-enter your password",
        icon: "error",
      });
      return;
    }
    let obj = {
      name: firstName + lastName,
      email: email,
      password_confirmation: confirmPassword,
      password: password,
      phone: phone,
      customer_selected_role: userType[select].type,
      phone_code: countryCode,
    };

    try {
      let resp = await FetchApi(Endpoints.register, obj);

      let user_slug = resp.data;
      // const data = resp.data;
      if (resp && resp.status === "success") {
        //ToastMessage.Success(resp.message);
        let user_data = {
          fname: firstName,
          lname: lastName,
          email: email,
          mobile: phone,
          phone_code: countryCode,
          user_slug: user_slug,
        };
        history(userType[select].to, { state: user_data  });
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log(tokenResponse);
      if(tokenResponse.access_token){
      axios
      .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
          headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: 'application/json'
          }
      })
      .then((res) => {
          console.log(res.data);
          setGoogleResponse(res.data);
          $("#selectRoleModel").modal("show");
      })
      .catch((err) => console.log(err));
    }else{
      Swal.fire({
        text: "Something went wrong, please check after some time",
        icon: "error",
      });
    }
    },
  });

  const socialMediaRegister = async() =>{
    let obj;
    if(socialLoginType == "google"){
    obj = {
      name: googleResponse?.name,
      email: googleResponse?.email,
      customer_selected_role: userType[select].type,
      media_login_type: "google",
      media_login_id:googleResponse?.id,
      password_confirmation: "atrioGoogleLogin",
      password: "atrioGoogleLogin",
    };
    }
    else if(socialLoginType == "facebook"){
      obj = {
        name: facebookResponse?.name,
        email: facebookResponse?.email,
        customer_selected_role: userType[select].type,
        media_login_type: "facebook",
        media_login_id: facebookResponse?.id,
        // password_confirmation: "atriofacebookLogin",
        // password: "atriofacebookLogin",
      };
      }

    try {
      let resp = await FetchApi(Endpoints.register, obj);

      let user_slug = resp.data;
      if (resp && resp.status === "success") {
        $("#selectRoleModel").modal("hide");
        const data = resp.data;
        let user_data = {
          fname: firstName,
          lname: lastName,
          email: email,
          mobile: phone,
          user_slug: user_slug,
        };
        data['fname'] = data.name;
        data['mobile'] = data.phone
        data['user_slug'] = { user_slug: data.user_slug }
        history(userType[select].to, { state: data });
      }
      if (resp && resp.message && resp.status=='success') {
        Swal.fire({
          text: resp.message,
          icon: 'success',
        });
      }
      if (resp && resp.message && resp.message.email && resp.message.email?.[0]) {
        Swal.fire({
          text: resp.message.email?.[0],
          icon: "error",
        });
      }
      else if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };

  const responseFacebook = (response) => {
    // console.log('fbresp___',response);
    setSocialLoginType("facebook");
    setFacebookResponse(response);
    $("#selectRoleModel").modal("show");
  };

  useEffect(()=>{
    const Facebutton = document.getElementsByClassName('kep-login-facebook')[0];
    Facebutton.innerHTML = '<img src="../assets/img/icon/facebook.svg" width="30" height="30" alt="" />';       
  }, []);
  return (
    <>
      <div className="main py-5">
        <div className="container">
          <div className="row justify-content-center sign-up">
            <div className="col-lg-6 col-md-8">
              <h1 className="text-darkgray semibold pb-2 text-center">
                Create an Account
              </h1>
              <p className="text-green2 text-center mb-3">
                Let’s start your journey with us.
              </p>

              <div className="row pb-3 gx-md-3 gx-2">
                <div onClick={() => setSelect(0)} className="col-4 c-p">
                  <div
                    className={select === 0 ? 'icon-box active d-flex align-items-center gap-2 justify-content-center': 'icon-box d-flex align-items-center gap-2 justify-content-center'}
                  >
                    <img
                      src="../assets/img/icon/profile2user.svg"
                      width="30"
                      height="30"
                      alt=""
                    />
                    <h5 className="text-green2">User</h5>
                  </div>
                </div>
                <div onClick={() => setSelect(1)} className="col-4 c-p">
                  <div
                    className={select === 1 ? 'icon-box active d-flex align-items-center gap-2 justify-content-center': 'icon-box d-flex align-items-center gap-2 justify-content-center'}
                  >
                    <img
                      src="../assets/img/icon/usertag.svg"
                      width="30"
                      height="30"
                      alt=""
                    />
                    <h5 className="text-green2">Coach</h5>
                  </div>
                </div>
                <div onClick={() => setSelect(2)} className="col-4 c-p">
                  <div
                    className={select === 2 ? 'icon-box active d-flex align-items-center gap-2 justify-content-center': 'icon-box d-flex align-items-center gap-2 justify-content-center'}
                  >
                    <img
                      src="../assets/img/icon/dribbble.svg"
                      width="30"
                      height="30"
                      alt=""
                    />
                    <h5 className="text-green2">Facilities</h5>
                  </div>
                </div>
              </div>

              <form onSubmit={register} className="site-form pt-2">
                <div className="row">
                  <div className={select === 2 ? "col-md-12"  :"col-md-6"}>
                    <div className="form-field mb-3">
                      <label htmlFor="" className="pb-2">
                      {select === 2 ? "Facility Name" : "First Name" } 
                      </label>
                      <input
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        type="text"
                        placeholder="Enter your first name"
                      />
                    </div>
                  </div>
                  {select === 2 ? 
                  ""
                  :
                  <div className="col-md-6">
                    <div className="form-field mb-3">
                      <label htmlFor="" className="pb-2">
                        Last Name
                      </label>
                      <input
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        type="text"
                        placeholder="Enter your Last name"
                      />
                    </div>
                  </div>
                  }
                </div>
                <div className="form-field mb-4">
                  <label htmlFor="" className="pb-1">
                    Email Address
                  </label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="text"
                    placeholder="Enter email address"
                  />
                </div>

                <div className=" mb-3">
                  <label htmlFor="" className="pb-2">
                    Mobile Number
                  </label>
                  <div className="d-flex">
                    <div className="form-field d-flex align-items-center field-phone w-100">
                      <PhoneInput
                        containerClass=""
                        country={"us"}
                        value={phone}
                        onChange={handleOnChange}
                        inputClass="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-field mb-4 pwd-group position-relative">
                  <label htmlFor="" className="pb-1">
                    Password
                  </label>
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type={passwordVisiblity ? 'text' : 'password'}
                    placeholder="Enter password"
                    className="pwd-field"
                  />
                  <a
                    onClick={(e) => setPasswordVisiblity(!passwordVisiblity)}
                    className="pwd-visibility text-gray2 position-absolute "
                  >
                    <i className={passwordVisiblity ? 'fa-light fa-eye-slash' : 'fa-light fa-eye'}></i>
                  </a>
                </div>
                <div className="form-field mb-2 pwd-group position-relative">
                  <label htmlFor="" className="pb-1">
                    Confirm Password
                  </label>
                  <input
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    type={cPasswordVisiblity ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    className="pwd-field"
                  />
                  <a
                    onClick={(e) => setCPasswordVisiblity(!cPasswordVisiblity)}
                    className="pwd-visibility text-gray2 position-absolute "
                  >
                    <i className={cPasswordVisiblity ? 'fa-light fa-eye-slash' : 'fa-light fa-eye'}></i>
                  </a>
                </div>
                <button
                  type="submit"
                  className="butn w-100 rounded-pill border-0"
                >
                  Sign Up
                </button>
              </form>

              <div>
                <span className="fs-13 light py-3 d-block text-center">
                  or continue with
                </span>
                <div className="d-flex gap-3 justify-content-center">
                  {/* <a href="#" className="google-login"> */}
                  <Link className="google-login"
                  onClick={()=>{
                    googleLogin();
                    setSocialLoginType("google")
                  }}
                  >
                    <img
                      src="../assets/img/icon/google.svg"
                      width="30"
                      height="30"
                      alt=""
                    />
                  </Link>

                  {/* </a> */}
                  {/* <a href="#" className="google-login"> */}
                  <Link className="google-login">
                    {/* <img
                      src="../assets/img/icon/facebook.svg"
                      width="30"
                      height="30"
                      alt=""
                    /> */}
                    {/* <LoginSocialFacebook
                      appId="1311088136228928"
                    // appId={process.env.REACT_APP_FACEBOOK_ID}
                    fieldsProfile={
                      'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                    }
                    onLoginStart={onLoginStart}
                    // onLogoutSuccess={onLogoutSuccess}
                    redirect_uri={REDIRECT_URI}
                    onResolve={({ provider, data }) => {
                      console.log('provider__', provider);
                      console.log('provider__', data);
                      // setProvider(provider);
                      // setProfile(data);
                    }}
                    onReject={err => {
                      console.log('errfb__', err);
                    }}
                  >
                    <FacebookLoginButton />
                  </LoginSocialFacebook> */}
                    <FacebookLogin
                      // appId={process.env.REACT_APP_FACEBOOK_ID}
                      appId="1311088136228928"
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="public_profile,user_friends,user_actions.books,email"
                      callback={responseFacebook}
                    />
                  </Link>

                  {/* </a> */}
                </div>
              </div>

              <div className="text-center pt-3">
                <p className="medium text-gray">
                  Do you have an account? 
                  <a href="/login" className="text-green ms-1">Login</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div
				className='modal fade '
				id='selectRoleModel'
				tabIndex='-1'
				aria-hidden='true'
				data-bs-backdrop='static'
				data-bs-keyboard='false'
			>
				<div className='modal-dialog modal-dialog-scrollable'>
					<div className='modal-content border-0 rounded-10'>
						<div className='modal-header border-0 p-2'>
							<button
								type='button'
								className='modal-close bg-transparent border-0'
								data-bs-dismiss='modal'
								aria-label='Close'
							>
								<img src='../assets/img/icon/close-circle.svg' alt='' />
							</button>
						</div>

						<div className='modal-body sign-up'>
							<h3 className='text-darkgray pb-3'>Select Roles</h3>
                  
              <div className="row pb-3 gx-md-3 gx-2">
                <div onClick={() => setSelect(0)} className="col-4">
                  <div
                    className={select === 0 ? 'icon-box active d-flex align-items-center gap-2 justify-content-center': 'icon-box d-flex align-items-center gap-2 justify-content-center'}
                  >
                    <img
                      src="../assets/img/icon/profile2user.svg"
                      width="30"
                      height="30"
                      alt=""
                    />
                    <h5 className="text-green2">User</h5>
                  </div>
                </div>
                <div onClick={() => setSelect(1)} className="col-4">
                  <div
                    className={select === 1 ? 'icon-box active d-flex align-items-center gap-2 justify-content-center': 'icon-box d-flex align-items-center gap-2 justify-content-center'}
                  >
                    <img
                      src="../assets/img/icon/usertag.svg"
                      width="30"
                      height="30"
                      alt=""
                    />
                    <h5 className="text-green2">Coach</h5>
                  </div>
                </div>
                <div onClick={() => setSelect(2)} className="col-4">
                  <div
                    className={select === 2 ? 'icon-box active d-flex align-items-center gap-2 justify-content-center': 'icon-box d-flex align-items-center gap-2 justify-content-center'}
                  >
                    <img
                      src="../assets/img/icon/dribbble.svg"
                      width="30"
                      height="30"
                      alt=""
                    />
                    <h5 className="text-green2">Facilities</h5>
                  </div>
                </div>
                <div className="col-12 mt-3 text-center">
                  <button className="butn rounded-pill border-0" onClick={()=>{
                    socialMediaRegister()
                  }}>Submit</button>
                </div>
              </div>
						</div>
					</div>
				</div>
			</div>
    </>
  );
}
export default Signup;
