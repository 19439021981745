/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
export const CART_DATA = 'CART_DATA';
export const SET_CART_DATA = 'SET_CART_DATA';
export const UPDATE_CART_DATA = 'UPDATE_CART_DATA';
export const SET_LOADING = 'SET_LOADING';
export const SET_COUNTRY_DATA = 'SET_COUNTRY_DATA';
export const CATEGORY_DATA = 'CATEGORY_DATA';
export const SET_CATEGORY_LOOK_UP_DATA = 'SET_CATEGORY_LOOK_UP_DATA';
export const SET_CATEGORY_DATA = 'SET_CATEGORY_DATA';
export const ACTIVE_CATEGORY_DATA = 'ACTIVE_CATEGORY_DATA';
export const ACTIVE_SUB_CATEGORY_DATA = 'ACTIVE_SUB_CATEGORY_DATA'
export const ACTIVE_FOLDER_DATA = 'ACTIVE_FOLDER_DATA';
export const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX';
export const SET_SUB_CATEGORY_DATA = 'SET_SUB_CATEGORY_DATA';
export const SUB_CATEGORY_DATA = 'SUB_CATEGORY_DATA';