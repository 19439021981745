export const Endpoints = {
	baseUrl: process.env.REACT_APP_API_BASE_URL,
	// WebSocket: 'https://atriobackend.dignitech.com',
	WebSocket: process.env.REACT_APP_SOCKET_URL,
	paymentBaseUrl: window.location.href.includes('localhost')
		? 'http://localhost:3000/'
		: 'https://atrio.dignitech.com/',
	login: 'login',
	register: 'register',
	forgotPassword: 'forgetPassword',
	updateUserDetail: 'updateUserDetail',
	userDetail: 'userDetail',
	availability: 'saveAvailability',
	deleteAvailability: 'deleteAvailability',
	coaches: 'getCoaches',
	getAvailability: 'getAvailability',
	getUserAvailability: 'getUserAvailability',
	stripePay: 'stripePay',
	getFacilities: 'getFacilities',
	getSports: 'getSports',
	getFiltersData: 'getFiltersData',
	getCategories: 'getCategories',
	getSpecialities: 'getSpecialities',
	getMasterFacilities: 'getMasterFacilities',
	getBookings: 'getBookings',
	cancelBooking: 'cancelBooking',
	confirmBooking: 'confirmBooking',
	createBooking: 'createBooking',
	reschedulBooking: 'reschedulBooking',
	getUserKids: 'getUserKids',
	saveUserKids: 'saveUserKids',
	deleteUserKids: 'deleteUserKids',
	updateUserKids: 'updateUserKids',
	userReview: 'userReview',
	createPost: 'createPost',
	getPosts: 'getPosts',
	likePost: 'likePost',
	createPostComment: 'createPostComment',
	sharedPost: 'sharedPost',
	deletePost: 'deletePost',
	addFacilityImages: 'addFacilityImages',
	removeFacilityImage: 'removeFacilityImage',
	removeCertificateImage: 'removeCertificateImage',
	removeUserResume: 'removeUserResume',

	// Jobs
	createJob: 'createJob',
	getJobListing: 'getJobs',
	getJobDetail: 'getJobDetail',
	deleteJob: 'deleteJob',
	changeJobStatus: 'changeJobStatus',
	applyForJob: 'applyForJob',
	GetChatAllRoom : 'getUsersAllChatRoom',
	GetMessage : 'getMessages',
	AcceptDeclineChatRequest : 'acceptDeclineChatRequest',
	sendMessage : 'sendMessage',
	ReadChatMessages : 'readChatMessages',
	CreateMessageRoomId : 'createMessageRoomId',
	CreateAdvertisement : 'createAdvertisement',
	GetAdvertisements : 'getAdvertisements',
	AddAdvertisementClick : 'addAdvertisementClick',
	AddAdvertisementLead : 'addAdvertisementLead',
	GetSingalAdvertisements : 'getAdvertisementDetail',
	GetPromotions : 'getPromotions',
	ChangePassword : 'changePassword',
	UserRewardTransactions : 'userRewardTransactions',
	GetChatCounter : 'getChatCounter',
	GetJobApplication : 'getJobApplicants',
	Logout : 'logout',
	ChangeAdvertisementStatus : 'changeAdvertisementStatus',
	GetAdvertisementLeads : 'getAdvertisementLeads',
	GetMasterAmenities : 'getMasterAmenities',
	getCoachCategories: 'getCategories/coach',
	getFacilityCategories: 'getCategories/facility',
	createReferAvailability: 'createReferAvailability'
};
