import React, { useEffect, useState } from "react";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { formatTime } from "../../Utils/constants";
import { connect } from "react-redux";

function Payment(props) {
  const [paymentData, setPaymentData] = useState([]);
  const [pagination, setPagination] = useState("");
  const history = useNavigate();
  useEffect(()=>{
    getPaymentData();
  }, []);

  const getPaymentData = async() =>{
    try {
      let resp = await CommonAPi(Endpoints.getBookings+"?status=payments&bookingData=my");
      if (resp && resp.status === "success") {
        setPagination(resp?.data);
        setPaymentData(resp?.data?.data);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));
    }
  };

  const navigateUserHome = async (user) => {
		try {
			let resp = await FetchApi(Endpoints.userDetail, { slug: user?.slug });
			if (resp && resp.type === 'success') {
				const userInfo = resp.data;
				userInfo['viewOnly'] = true;
				if (userInfo.user_role.name === 'Coach') {
					history('/coach-details/'+user?.slug);
				} else if (userInfo.user_role.name === 'Facilities') {
					history('/facilities-details/'+user?.slug);
				} else {
					history('/user-details/'+user?.slug);
				}
			}
		} catch (e) {
			if (e && e.response && e.response.message && e.response.message.email) {
				Swal.fire({
					text: 'Failed to delete the post',
					icon: 'error',
				});
			}
		}
	};

  return (
    <div className="wrapper">
      <div className="main py-5">
        <div className="container">
          <h1 className="fs-24 medium pb-2">
            Payments
          </h1>

          <table className="job-table w-100">
            <thead>
              <tr className="fs-14 text-green table-head bg-lightgreen">
              <th>Booking ID</th>
              <th>{props.user.user_role.name === 'Coach' ?  'Athlete' :  'Coaches/Athlete'} </th>
              <th>Booking Date & Time</th>
              <th>Amount</th>
              <th>Status</th>
              </tr>
            </thead>
            <tbody>
            {paymentData.map((item, i) => {
              return (
                <tr className="border" key={i}>
                  <td>
                    <h5 className="medium fs-13 text-darkgray">{item.id}</h5>
                  </td>
                  <td>
                    <div className="d-flex gap-2">
                     
                     <img
                        src={item.user && item.user.image ? item.user.image : "../assets/img/edit-profile.png"}
                        width="50"
                        height="50"
                        alt=""
                        className="rounded-pill c-p"
                        onClick={()=>{
                          navigateUserHome(item.user);
                        }}
                      />
                      <div>
                      
                          <React.Fragment>
                            <h5 className="semibold text-darkgray c-p"
                             onClick={()=>{
                              navigateUserHome(item.user);
                            }}
                            >{item.user.name} {item.user.last_name ? item.user.last_name : ''}</h5>
                            <span className="text-878787 fs-13 medium">
                              {item.user.user_sports.name}
                            </span>
                            <h6 className="semibold">
                              Booking Location:{" "}
                              <span className="regular d-block">
                                {item.user.address}
                              </span>{" "}
                            </h6>
                          </React.Fragment>
                     
                      </div>
                    </div>
                  </td>
                  <td>
                    <h6 className="medium pb-2  text-434343">
                      <span className="regular semibold fs-13"> Date:</span> {item.availability_date}
                    </h6>
                    <h6 className="medium text-434343">
                      <span className=" regular semibold fs-13">Time:</span>{" "}
                      {item.time_slot ? formatTime(item.time_slot) : ''} - {item.time_slot_end ? formatTime(item.time_slot_end) : ''}
                    </h6>
                  </td>
                  <td>
                    <h5 className="medium fs-13">${item.payout_amount.toLocaleString("ja-JP")}</h5>
                  </td>
                  <td>
                    <h5 className="medium fs-13 text-success">Paid</h5>
                  </td>
                  
                </tr>
              )
            })}
             {paymentData.length == 0 &&
              <tr>
                <td className="border">
                  No payment Available
                </td>
              </tr>
            }
            </tbody>
          </table>

          {pagination.length > 0 && (
            <div className="paginations text-start py-3">
              <span className="me-2">
                Page 1 of{" "}
                {Math.ceil(pagination.total / pagination.per_page)}
              </span>
              {pagination.links.map((item, i) => {
                return item.url ? (
                  <React.Fragment key={i}>
                    <Link
                      className={item.active ? "page-active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        getPaymentData(item.url);
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: item.label }}
                      ></span>
                    </Link>
                  </React.Fragment>
                ) : null;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(Payment);
