import React, { useState } from "react";
import { connect } from "react-redux";

import SideBar from "../../Layout/SideBar";
import { Posts } from "../../Layout/Posts";
import Promotions from "./Promotions";
const $ = window.jQuery;

function UserHomePage(props) {
    return (
        <div className="main py-4">
            <div className="container">
                <div className="row feed-page">
                    <SideBar />
                    <div className="col-lg-6 middle-area">
                        <Posts user={props.user} pageFrom='feed' />
                    </div>
                    {/* <!-- END MIDDLE COLUMN --> */}

                    <Promotions />
                </div >
            </div >
        </div >
    );
}
const mapStateToProps = (state) => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};
export default connect(mapStateToProps, {})(UserHomePage);