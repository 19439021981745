import React, { useEffect, useState } from "react";
import { Button, Modal, Toast } from "react-bootstrap";
import ToastMessage from "../Utils/ToastMessage";
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import moment from "moment";
import { ValidateList, ValidationTypes } from "../Utils/ValidationHelper";
import WeekdayButtons from "./weekdayButtons";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export default function CreateReferAvailability({
    coachInfo,
    modalIsOpen,
    setModalIsOpen,
    eventDetails,
    isBeforeBooking,
    Api
}) {
    const [userDetail, setUserDetail] = useState({});
    const [isOwnTerms, setOwnTerms] = useState(false);
    const [athleteFee, setAthleteFee] = useState('');
    const [athleteOccupancy, setAthleteOccupancy] = useState('');
    const [wantCacelationCharge, setWantCancelationCharge] = useState();
    const [cancellationCharge, setCancellationCharge] = useState('');
    const history = useNavigate();
    // console.log('evdet__', eventDetails);
    

    const saveAvailability = async () => {
        let validate_arr = [
            [athleteFee, ValidationTypes.Empty, "Fee is required"],
            [athleteOccupancy, ValidationTypes.Empty, "Max occupancy is required"],
            [wantCacelationCharge, ValidationTypes.Empty, "Select cancellation charge"],
        ];
        let validate = await ValidateList(validate_arr);
        if (!validate) {
            return;
        }
        if(wantCacelationCharge == 1 && !cancellationCharge){
            ToastMessage.Error('Cancellation charge field is required');
            return
        }
        let obj = {
            availability_id: eventDetails?.id,
            max_athlete: athleteOccupancy,
            athlete_fee: athleteFee,
            availability_date: moment(eventDetails?.start)?.format('YYYY/MM/DD'),
            is_want_cancellation_charge: wantCacelationCharge,
            cancellation_charge: cancellationCharge
        }
        try {
            let resp = await FetchApi(Endpoints.createReferAvailability, obj);
            if (resp && resp.type === "success") {
                ToastMessage.Success(resp.message);
                setModalIsOpen(false);
                setOwnTerms(false);
                if(Api){
                    Api();
                }
                if(!isBeforeBooking){
                    history("/my-bookings");
                }
            }
            else {
                ToastMessage.Error(JSON.stringify(resp.message));
            }
        } catch (e) {
        }
    }

    const getUserDetail = async () => {
        try {
            let resp = await FetchApi(Endpoints.userDetail, {});
            if (resp && resp.type === 'success') {
                setUserDetail(resp?.data);
            }
        } catch (e) {
        }
    };

    useEffect(() => {
        getUserDetail();
    }, []);

    const handleClose = () => {
        setModalIsOpen(false);
    };

    return (
        <Modal
            show={modalIsOpen}
            onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="fs-18">{" "}</Modal.Title>
                <div
                    className="c-p"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    X
                </div>
            </Modal.Header>
            <Modal.Body
            // className="site-form"
            >
                <div
                    className="site-form"
                >
                    <span className="pb-2 d-flex justify-content-center">Do you want to set your own terms?</span>
                    {!isOwnTerms &&
                    <div className='d-flex align-items-center justify-content-center pt-3'>
                        <Button
                            style={{
                                borderRadius: '20px'
                            }}
                            className='w-25'
                            variant="secondary"
                            onClick={() => {
                                if(!isBeforeBooking){
                                    history("/my-bookings");
                                }
                                else{
                                    handleClose();
                                }
                            }}>
                            No
                        </Button>
                        <Button
                            onClick={() => {
                                setOwnTerms(true);
                            }}
                            className='butn w-25 ms-3'>
                            Yes
                        </Button>
                    </div>}

                    {isOwnTerms && 
                    <>
                    <div className="d-flex mb-2 mt-3">
                        <div className={`me-2 w-50`}>
                            <label>Maximum Athletes
                                {eventDetails?.max_athlete? <p style={{fontSize: '12px'}}>(You can add max {eventDetails?.max_athlete} athletes)</p>: null}
                            </label>
                            <input
                                name="max_athlete"
                                className="p-2"
                                style={{
                                    // borderColor: '#EAEAEA',
                                    borderWidth: '1px',
                                    borderRadius: '5px'
                                }}
                                type="number"
                                placeholder="Enter number"
                                value={athleteOccupancy}
                                onChange={(e)=>{
                                    setAthleteOccupancy(e.target.value)
                                }}
                                // onChange={handleChange}
                            />
                        </div>

                        <div className={`d-flex flex-column justify-content-between w-50`}>
                        <label>Fee for Athletes</label>
                        <input
                            name="athlete_fee"
                            className="p-2"
                            style={{
                                // borderColor: '#EAEAEA',
                                borderWidth: '1px',
                                borderRadius: '5px'
                            }}
                            type="number"
                            value={athleteFee}
                            placeholder="Enter amount"
                            onChange={(e)=>{
                                setAthleteFee(e.target.value)
                            }}
                            // onChange={handleChange}
                        />
                        </div>

                    </div>

                    <label className="mb-2">Want to apply cancellation charges?</label>
                    <select
                    name="is_want_cancellation_charge"
                    value={wantCacelationCharge}
                    onChange={(e)=> setWantCancelationCharge(e.target.value)}
                    className="p-2 mb-3"
                    style={{
                        borderWidth: '1px',
                        borderRadius: '5px'
                    }} 
                    >
                        <option value={''}>Select</option>
                        <option value={1}>{"Yes"}</option>
                        <option value={0}>{"No"}</option>
                    </select>

                    {wantCacelationCharge==1?
                        <>
                        <label className="mt-2">Cancellation Charge</label>
                        <input 
                        name="cancellation_charge"
                        className="p-2 mb-3"
                        style={{
                            borderWidth: '1px',
                            borderRadius: '5px'
                        }} 
                        type="number"
                        placeholder="Enter amount"
                        value={cancellationCharge}
                        onChange={(e)=> setCancellationCharge(e.target.value)}
                        />
                        </>
                        : null
                        }
                        </>
                        }
                </div>

            </Modal.Body>
            {isOwnTerms&&
            <Modal.Footer>
                <Button
                    style={{
                        backgroundColor: 'gray'
                    }}
                    onClick={() => {
                        setModalIsOpen(false);
                        if(!isBeforeBooking){
                            history("/my-bookings");
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button
                    style={{
                        backgroundColor: 'green'
                    }}
                    onClick={() => {
                        saveAvailability();
                    }}
                >
                    Save
                </Button>
            </Modal.Footer>}

        </Modal>
    )
}