import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import moment from "moment";
import "./message.css";
import ToastMessage from "../../Utils/ToastMessage";
import { useQueryClient } from "@tanstack/react-query";
const $ = window.jQuery;
export default function ChatMessage({ pageType, activeChatId, setMessageStatusChange }) {
    const [MessageData, setMessageData] = useState([]);
    const [UserData, setUserData] = useState([]);
    const [status, setStatus] = useState(false);
    const [ChatStatus, setChatStatus] = useState("pending");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [loginuser, setLoginUser] = useState("");
    const [user_id, setUser_id] = useState("");
    const [file, setFile] = useState("");
    // const loginuser = getLoggedInUser()
    const history = useNavigate()
    const filePreviewBox = useRef();
    const fileRef = useRef();
    const socket = useRef();
    const [messageError, setMessageError] = useState("");
    const queryClient = useQueryClient();
    useEffect(() => {
        if (activeChatId > 0) {
            getMessages();
            getLoginUser();
        }

        return () => {
            if (socket.current) {
                socket.current.close()
            }
        }
    }, [activeChatId]);
    const getMessages = async (e) => {
        let data = {
            message_room_id: activeChatId
        }
        try {
            let resp = await CommonAPi(Endpoints.GetMessage, data);
            if (resp && resp.status === "success") {
                setStatus(true);
                setChatStatus(resp?.data?.status)
                setUser_id(resp?.data?.user_id)
                setMessageData(resp?.data?.messages);
                setUserData(resp?.data?.room_participants);

                // socket.current = io("ws://143.110.248.218:6001/demo/server.php", {
                //     transports: ["websocket"],
                //     jsonp: false,
                // });
                // socket.current.connect();
                socket.current = new WebSocket(Endpoints.WebSocket);


                initiateSocket(activeChatId);

                resp?.data?.messages.map((item) => {
                    if (item?.IsAuthUserRead == false) {
                        readMessage();
                    }
                })
                scrollToEnd();

            } else {
                setStatus(false);
            }
        } catch (e) {
            if (e && e.response && e.response.message && e.response.message.email) {
                Swal.fire({
                    text: "This email is already used",
                    icon: "error",
                });
            }
        }
    };

    const scrollToEnd = () => {
        setTimeout(() => {
            var container = $(".containerHeight")[0];
            container.scrollTop = container.scrollHeight;
        }, 1000);
    };

    const getLoginUser = async (e) => {

        try {
            let resp = await CommonAPi(Endpoints.userDetail, {});
            if (resp && resp.status === "success") {
                setLoginUser(resp.data)
            }
        } catch (e) {
            if (e && e.response && e.response.message && e.response.message.email) {
                Swal.fire({
                    text: "This email is already used",
                    icon: "error",
                });
            }
        }
    };
    const changeStatus = async (status) => {
        let data = {
            message_room_id: activeChatId,
            status: status
        }
        try {
            let resp = await CommonAPi(Endpoints.AcceptDeclineChatRequest, data);
            if (resp && resp.status === "success") {
                history('/message')
                getMessages();
            }
        } catch (e) {
            if (e && e.response && e.response.message && e.response.message.email) {
                Swal.fire({
                    text: "This email is already used",
                    icon: "error",
                });
            }
        }
    };
    const hanldeSendMessage = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("message_room_id", activeChatId);
        if (message) {
            formData.append("message", message);
        } else {
            formData.append("message", "ok");
        }

        formData.append("msg_type", messageType);
        if (file) {
            formData.append("file", file);
        }
        if(!message?.length && !file){
            ToastMessage.Error("Please enter message to send");
            return
        }
        try {
            let resp = await CommonAPi(Endpoints.sendMessage, formData);
            if (resp && resp.status === "success") {

                let msg = {
                    message_room_id: resp.data?.message_room_id,
                    message_room: "",
                    client_id: resp.data?.user_id,
                    name: resp.data?.user?.name,
                    msg_type: resp.data?.msg_type,
                    msg: resp.data?.msg,
                    CreatedAtCustom: resp.data?.CreatedAtCustom,
                    color: resp.data?.created_at,
                    image: resp.data?.user?.image,
                    IsAuthUserRead: resp.data?.IsAuthUserRead,
                };
                socket.current.send(JSON.stringify(msg))
                // getMessages();
                setMessageType("");
                setMessage("");
                setFile("");
                scrollToEnd();
                queryClient.invalidateQueries('chat_list');
                // getMessages();
            }
            if(resp && resp.status=="false"){
                ToastMessage.Error(resp?.message)
            }
        } catch (e) {
            if (e && e.response && e.response.message && e.response.message.email) {
                Swal.fire({
                    text: "This email is already used",
                    icon: "error",
                });
            }
        }
    };

    const handleChangeFile = (e) => {
        console.log(e.target.files[0].type);
        if (e.target.files[0].type === "image/jpeg"
            || e.target.files[0].type === "image/png"
            || e.target.files[0].type === "image/bmp"
            || e.target.files[0].type === "image/gif"
            || e.target.files[0].type === "image/tiff"
        ) {
            setFile(e.target.files[0]);
            setMessageType('image');
        } else if (e.target.files[0].type === "video/mp4"
            || e.target.files[0].type === "video/*"
        ) {
            setFile(e.target.files[0]);
            setMessageType('video');
        } else {
            setFile(e.target.files[0]);
            setMessageType('file');
        }
    };

    const readMessage = async () => {
        let data = {
            message_room_id: activeChatId
        }
        try {
            let resp = await CommonAPi(Endpoints.ReadChatMessages, data);
            if (resp && resp.status === "success") {
                queryClient.invalidateQueries('chat_count');
                // getMessages();
            }
        } catch (e) {
            if (e && e.response && e.response.message && e.response.message.email) {
                Swal.fire({
                    text: "This email is already used",
                    icon: "error",
                });
            }
        }
    };

    const checkStatus = (msg) =>{
        setMessageStatusChange(msg);
    };
    const initiateSocket = () => {
        socket.current.onopen = (error) => {
            console.log("connection____", error);
        }

        socket.current.onerror = (error) => {
            console.log("connection____error", error);
        }
        socket.current.onmessage = (text) => {

            if (text) {
                let data = JSON.parse(text.data);
                if (data.type === "usermsg") {

                    if(data?.color == "messege_request_status"){
                        checkStatus(data?.msg);
                    }
                    if (activeChatId == data?.message_room_id) {
                        let newData = {
                            message_room_id: data?.message_room_id,
                            client_id: data?.client_id,
                            name: data?.name,
                            msg_type: data?.msg_type,
                            msg: data?.msg,
                            CreatedAtCustom: data?.CreatedAtCustom,
                            created_at: data?.color,
                            IsAuthUserRead: data?.IsAuthUserRead,
                            user: {
                                id: data?.client_id,
                                image: data?.image
                            }
                        }

                        setMessageData((prevArray) => [...prevArray, newData]);
                        scrollToEnd();

                    }
                }
            }
        }
    };

    const navigateUserHome = async (user) => {
		try {
			let resp = await FetchApi(Endpoints.userDetail, { slug: user?.slug });
			if (resp && resp.type === 'success') {
				const userInfo = resp.data;
				userInfo['viewOnly'] = true;
				if (userInfo.user_role.name === 'Coach') {
					history('/coach-details/' + user?.slug);
				} else if (userInfo.user_role.name === 'Facilities') {
					history('/facilities-details/' + user?.slug);
				} else {
					history('/user-details/' + user?.slug);
				}
			}
		} catch (e) {
			if (e && e.response && e.response.message && e.response.message.email) {
				Swal.fire({
					text: 'Failed to delete the post',
					icon: 'error',
				});
			}
		}
	};

    return (
        <>
            {status ?
                <div className="col-md-8 ps-md-4">
                    <div className="p-3 message-person">
                        <div className="d-md-flex align-items-center gap-5">
                            <Link className="d-flex align-items-center gap-3"
                            onClick={()=>{
                                navigateUserHome(UserData[0]?.user)
                            }}
                            >
                                <div 
                                style={{
                                    width: '80px',
                                    height: '80px'
                                }}
                                className="position-relative">
                                    <img
                                        src={UserData[0]?.user?.image}
                                        alt=""
                                        style={{
                                            width: '100%',
                                            height: '100%', 
                                            objectFit: 'cover',
                                            borderRadius: '50%'
                                        }}
                                        // width="80"
                                        // height="80"
                                        // className="rounded-pill dp-border"
                                        // className="rounded-circle active-userchatpic"
                                    />
                                </div>

                                <div>
                                    <h6 className="medium text-black fs-16 pb-1">
                                        {UserData[0]?.user?.name} {UserData[0]?.user?.last_name}
                                    </h6>
                                    {UserData[0]?.user?.isCurrentlyLoggedIn == true ?
                                        <div className="d-flex gap-1 align-items-center">
                                            <svg
                                                viewBox="0 0 24 24"
                                                width="10"
                                                height="10"
                                                stroke="#fff"
                                                stroke-width="1"
                                                fill="#57CB6C"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <circle cx="10" cy="10" r="10"></circle>
                                            </svg>
                                            <h6 className="fs-12 regular text-darkgray">
                                                Active Now
                                            </h6>
                                        </div>
                                        :
                                        <div className="d-flex gap-1 align-items-center">
                                            <svg
                                                viewBox="0 0 24 24"
                                                width="12"
                                                height="12"
                                                stroke="#fff"
                                                stroke-width="1"
                                                fill="#E20000"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <circle cx="12" cy="12" r="10"></circle>
                                            </svg>
                                            <h6 className="fs-12 regular text-darkgray">
                                                Offline
                                            </h6>
                                        </div>

                                    }
                                </div>
                            </Link>
                            {pageType == "pending" && ChatStatus == "pending" ?
                                user_id == loginuser?.id ? "" :
                                    <div className="d-flex gap-3 mt-md-0 mt-3">
                                        <Link className="butn gray-butn"
                                            onClick={() => {
                                                changeStatus("decline")
                                            }}>Decline</Link>
                                        <Link className="butn"
                                            onClick={() => {
                                                changeStatus("active")
                                            }}
                                        >Accept</Link>
                                    </div>
                                : ""}

                                {/* <span onClick={()=>{
                                    setMessageStatusChange();
                                }}>
                                            55555
                                </span> */}
                               
                        </div>
                    </div>

                    <div className="pt-3 msg-listing containerHeight" style={{ height: "520px" }}>
                        <div className="messages" >
                            {MessageData.map((item, index) => {
                                return (
                                    <>
                                        {item?.user?.id == loginuser?.id ?
                                            <div key={index}>
                                                <div className="d-flex gap-2 align-items-end">
                                                    <div className="msg-item reply-msg mt-md-0 mt-3">
                                                        <div className="pb-2">
                                                            {item?.msg_type === "image" ?
                                                                <p>
                                                                    <Link to={item?.msg} target={"_blank"}>
                                                                        <img src={item?.msg} alt="" width={"128"} height={"128"} />
                                                                    </Link>
                                                                </p>
                                                                :
                                                                item?.msg_type === "file" ?
                                                                    <Link to={item?.msg} target={"_blank"}>
                                                                        <img src="assets/img/file.png" alt="" />
                                                                    </Link>
                                                                    :
                                                                    item?.msg_type === "video" ?
                                                                    <video width={"128"} height={"128"} src={item?.msg} controls>

                                                                    </video>
                                                                    :
                                                                    <p style={{
                                                                        // backgroundColor: 'red',
                                                                        overflowWrap: 'break-word'
                                                                    }}>
                                                                        {item?.msg}
                                                                    </p>

                                                            }
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={item?.user?.image}
                                                        alt=""
                                                        width="40"
                                                        height="40"
                                                        className="rounded-circle active-userchatpic"
                                                    />
                                                </div>
                                                <h6 className="fs-13 text-lightgray pt-1 regular ps-5 ms-auto msg-time">
                                                    <img src="assets/img/icon/icon-date-time.svg" alt="" />{" "}
                                                    {moment(
                                                        item?.created_at
                                                    ).format("hh:mm A")}
                                                </h6>
                                            </div>
                                            :
                                            <div key={index}>
                                                <div className="d-flex gap-2 align-items-end">
                                                    <img
                                                        src={item?.user?.image}
                                                        alt=""
                                                        width="40"
                                                        height="40"
                                                        className="rounded-circle active-userchatpic"
                                                    />
                                                    <div className="msg-item">
                                                        <div className="pb-2">
                                                            {item?.msg_type === "image" ?
                                                                <p>
                                                                    <Link to={item?.msg} target={"_blank"}>
                                                                        <img src={item?.msg} alt="" width={"128"} height={"128"} />
                                                                    </Link>
                                                                </p>
                                                            :
                                                                item?.msg_type === "file" ?
                                                                    <Link to={item?.msg} target={"_blank"}>
                                                                        <img src="assets/img/file.png" alt="" />
                                                                    </Link>
                                                            :
                                                                item?.msg_type == "video" ?
                                                                    <Link to={item?.msg} target={"_blank"}>
                                                                        <video width={"128"} height={"128"} src={item?.msg} controls>

                                                                        </video>
                                                                    </Link>
                                                            :
                                                                    <p 
                                                                    // className="text-wrap"
                                                                    style={{
                                                                        // backgroundColor: 'gray',
                                                                        overflowWrap: 'break-word'
                                                                    }}>
                                                                        {item?.msg}
                                                                    </p>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <h6 className="fs-13 text-lightgray pt-1 regular ps-5">
                                                    <img src="assets/img/icon/icon-date-time.svg" alt="" />{" "}
                                                    {moment(
                                                        item?.created_at
                                                    ).format("hh:mm A")}
                                                </h6>
                                            </div>
                                        }


                                    </>
                                )
                            })}


                        </div>
                    </div>

                    <form
                        onSubmit={hanldeSendMessage}
                    >
                          {file && (
                            <div className="file-preview-box d-flex ms-auto" ref={filePreviewBox} 
                           
                            >
                                <div className="svg-container">
                                    <svg
                                        height="15px"
                                        width="15px"
                                        id="Layer_1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setFile("");
                                        }}
                                        version="1.1"
                                        viewBox="0 0 512 512"
                                    >
                                        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                    </svg>
                                </div>
                                <div className="preview-container ">
                                    {file.type.includes("image") && (
                                        <img
                                            src={URL.createObjectURL(file)}
                                            alt="img-preview"
                                        />
                                    )}
                                    {file.type.includes("video") && (
                                    <video src={URL.createObjectURL(file)} controls>

                                    </video>
                                    )}
                                    {(file.name.endsWith(".doc") ||
                                        file.name.endsWith(".docx")) && (
                                            <img
                                                src={`/assets/img/icon/word_icon.png`}
                                                className="chat-file-icons"
                                                alt="img-preview"
                                            />
                                        )}
                                    {file.name.endsWith(".xlsx") && (
                                        <img
                                            src={`/assets/img/icon/excel_icon.png`}
                                            className="chat-file-icons"
                                            alt="img-preview"
                                        />
                                    )}

                                    {file.name.endsWith(".pdf") && (
                                        <img
                                            src="/assets/img/icon/pdf_icon.png"
                                            className="chat-file-icons"
                                            alt="img-preview"
                                        />
                                    )}
                                    {!file.type.includes("image") && (
                                        <a
                                            href={URL.createObjectURL(file)}
                                            style={{ fontWeight: "bold" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {file.name.length > 10 ? file.name.substring(0, 10) :  file.name}
                                        </a>
                                    )}

                                </div>
                            </div>
                        )}
                    <div  className="mt-2 w-100 rounded-pill send-msg bg-lightgreen d-flex align-items-center justify-content-between">

           
                            <input
                                type="text"
                                className="fs-13 border-0 bg-transparent text-green"
                                placeholder="Start typing..."
                                value={message}
                                onChange={(e) => {
                                    setMessageType("text")
                                    setMessage(e.target.value)
                                }}
                            />
                            <span className="d-flex">
                                <span className="me-3">
                                    <input type="file" id="feed-image" className="d-none" onChange={(e) => {
                                        handleChangeFile(e)
                                    }} />
                                    <label for="feed-image">
                                        <img src="assets/img/icon/attachment-line.svg" alt="" />
                                    </label>
                                </span>

                                <button type="submit" className="send border-0">
                                    <img src="assets/img/icon/send.svg" alt="" />
                                </button>

                            </span>
                        </div>
                        
                      
                    </form>
                    {messageError ?
                        <span className="text-danger px-4">
                            {messageError}
                        </span>
                        : ""}
                </div>
                :
                <div className="col-md-8 ps-md-4">
                    No chat message
                </div>

            }
        </>
    )
}
