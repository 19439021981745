import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import React, { useState } from "react";
import { useEffect } from "react";
import { Endpoints } from "../API/Endpoints";
import { FetchApi } from "../API/FetchApi";
import { formatTime, monthNames } from "../Utils/constants";
import ReactStars from "react-rating-stars-component";
import { CommonAPi } from "../API/CommonApi";
import ToastMessage from "../Utils/ToastMessage";

function SideBar(props) {
  const history = useNavigate();
  const [user, setUserInfo] = useState(props.user);
  const [scheduleList, setScheduleList] = useState([]);
  const [kidsList, setKidsList] = useState([]);
  const [jobList, setJobList] = useState();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [salaryOne, setSalaryOneValue] = useState(0);
  const [salaryTwo, setSalaryTwoValue] = useState(40000);
  const [radiusOne, setRadiusOneValue] = useState(0);
  const [radiusTwo, setRadiusTwoValue] = useState(50);
  const [updatedScheduleList, setUpdatedScheduleList] = useState([]);

  const userIsFacility =
    props.user?.user_role.name === "Facilities" ? true : false;

  const onLocationChanged = (data) => { setLat(data.lat); setLng(data.lng); getJobListing(null, null, data.lat, data.lng); };

  const getJobListing = async (lati, lngi) => {
    let obj = {
      data_for: userIsFacility ? "facility" : "all",
     
        latitude: !userIsFacility  ? lati ? lati : (lat ? lat : props?.user?.latitude) : "",
        longitude: !userIsFacility ? lngi ? lngi : (lng ? lng : props?.user?.longitude) : "",
        salary_from: !userIsFacility ? salaryOne : "",
        salary_to: !userIsFacility ? salaryTwo : "",
        radius_from: !userIsFacility ? radiusOne : "",
        radius_to: !userIsFacility ? radiusTwo : "",
      
     
    };
    try {
      let resp = await CommonAPi(Endpoints.getJobListing, obj);
      if (resp && resp.status === "success") {
        setJobList(resp?.data);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));
    }
  };

  const getScheduleList = async (dateString) => {
    try {
      const dateObject = dateString ? new Date(dateString) : "";
      const postURL = dateObject
        ? Endpoints.getBookings +
          `?status=upcoming&bookingData=my&filterDate=${
            dateObject.getDate() < 10
              ? "0" + dateObject.getDate()
              : dateObject.getDate()
          }/${
            dateObject.getMonth() + 1 < 10
              ? "0" + (dateObject.getMonth() + 1)
              : dateObject.getMonth() + 1
          }/${dateObject.getFullYear()}`
        : Endpoints.getBookings + `?status=upcoming&bookingData=my`;
      let resp = await FetchApi(postURL);
      if (resp && resp.type === "success") {
        let data = resp.data;
        data.forEach((item) => {
          const booked_date = new Date(item.availability_date);
          item["bookedDate"] = `${booked_date.getDate()} ${
            monthNames[booked_date.getMonth()]
          } ${booked_date.getFullYear()}`;
          item["start"] = item.time_slot
            ? formatTime(item.time_slot, false)
            : "";
          item["end"] = item.time_slot_end
            ? formatTime(item.time_slot_end, false)
            : "";
          if (
            props.user &&
            props.user?.user_role &&
            props.user?.user_role.name === "Coach"
          ) {
            item["image"] = item.coach_facility.image;
            item["name"] = item.coach_facility.name;
            item["user_sports"] = item.coach_facility.user_sports.name;
            item["user_star"] = item?.coach_facility?.reviews_rating;
            item["address"] = item.coach_facility.address;
          } else {
            item["image"] = item.coach_facility.image;
            item["name"] = item.coach_facility.name;
            item["user_sports"] = item.coach_facility.user_sports.name;
            item["user_star"] = item?.coach_facility?.reviews_rating;
            item["address"] = item.coach_facility.address;
          }
        });
        return new Promise((resolve, reject) => {
          console.log({ resp });
          resolve(resp.data);
        });
      }
    } catch (e) {
      console.log("AvailabilityList", JSON.stringify(e, null, 4));
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  };
  const getScheduleListByAt = async (dateString) => {
    if (
      props.user &&
      props.user?.user_role &&
      (props.user?.user_role.name === "Facilities" ||
        props.user?.user_role.name === "Coach")
    ) {
      try {
        const dateObject = dateString ? new Date(dateString) : "";
        const postURL = dateObject
          ? Endpoints.getBookings +
            `?status=upcoming&bookingData=athelete&filterDate=${
              dateObject.getDate() < 10
                ? "0" + dateObject.getDate()
                : dateObject.getDate()
            }/${
              dateObject.getMonth() + 1 < 10
                ? "0" + (dateObject.getMonth() + 1)
                : dateObject.getMonth() + 1
            }/${dateObject.getFullYear()}`
          : Endpoints.getBookings + `?status=upcoming&bookingData=athelete`;
        let resp = await FetchApi(postURL);
        if (resp && resp.type === "success") {
          let data = resp.data;
          data.forEach((item) => {
            const booked_date = new Date(item.availability_date);
            item["bookedDate"] = `${booked_date.getDate()} ${
              monthNames[booked_date.getMonth()]
            } ${booked_date.getFullYear()}`;
            item["start"] = item.time_slot
              ? formatTime(item.time_slot, true)
              : "";
            item["end"] = item.time_slot_end
              ? formatTime(item.time_slot_end, true)
              : "";
            if (
              props.user &&
              props.user?.user_role &&
              props.user?.user_role.name === "Facilities"
            ) {
              item["image"] = item.user?.image;
              item["name"] = item.user?.name;
              item["user_sports"] = item.user?.user_sports.name;
              item["user_star"] = item.user?.reviews_rating;
              item["address"] = item.user?.address;
            } else if (
              props.user &&
              props.user?.user_role &&
              props.user?.user_role.name === "Coach"
            ) {
              item["image"] = item.user?.image;
              item["name"] = item.user?.name;
              item["user_sports"] = item.user?.user_sports.name;
              item["user_star"] = item.user?.reviews_rating;
              item["address"] = item.user?.address;
            } else {
              item["image"] = item.coach_facility.image;
              item["name"] = item.coach_facility.name;
              item["user_sports"] = item.coach_facility.user_sports.name;
              item["user_star"] = item?.coach_facility?.reviews_rating;
              item["address"] = item.coach_facility.address;
            }
          });
          //setScheduleList([...scheduleList,...resp.data])
          return new Promise((resolve, reject) => {
            resolve(resp.data);
          });
        }
      } catch (e) {
        return new Promise((resolve, reject) => {
          resolve([]);
        });
      }
    } else {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  };
  const getKidsList = async () => {
    try {
      let resp = await FetchApi(Endpoints.getUserKids);
      if (resp && resp.type === "success") {
        const kidsList = resp.data;
        if (kidsList.length > 0) {
          const data = kidsList[0].user_kids;
          setKidsList(data);
        }
      }
    } catch (e) {
      console.log("AvailabilityList", JSON.stringify(e, null, 4));
    }
  };
  useEffect(() => {
    Promise.all([getScheduleList(), getScheduleListByAt()]).then((values) => {
      console.log(values );
      // let arr = values.flat();
      // console.log(arr);
      // [].flatMap(())
      let dt = Object.keys(values).map((key)=>{
        return values[key];
      }).filter((item)=> item);
      console.log('dt',dt);
      setScheduleList([...values?.[0], ...values?.[1]]);
      // setScheduleList(dt)
    });
    if (
      props?.user &&
      props.user?.user_role &&
      props.user?.user_role?.name === "User"
    ) {
      getKidsList();
    }
    getJobListing();
  }, []);

  useEffect(()=>{
    const NewscheduleList  = scheduleList.filter((item)=> item?.status == "confirmed");
    // console.log(NewscheduleList)
    setUpdatedScheduleList(NewscheduleList);
  }, [scheduleList]);

  // console.log('user__', JSON.stringify(user, null, 4))
  return (
    <div className="col-lg-3 left-sidebar">
      <div className="user-box py-2 px-3 rounded-8 bg-lightgreen text-center mb-4 ">
        <span className="user-pp-left-nav mt-2">
          <img
            src={user?.image ? user?.image : "../assets/img/edit-profile.png"}
            alt=""
            className="rounded-pill"
          />
        </span>
        <h4 className="semibold pt-2">
          {user?.name} {user?.last_name}
        </h4>
        <h5 className="text-lightgray regular pb-2">
          {user?.user_role?.id == 4 ? 
          user?.facility_selected_amenity?.map((item, index)=>{
            return(
              item?.amenity+(index!=user?.facility_selected_amenity?.length-1? ", ": '')
            )
          })
          :
          user?.user_role?.id == 3 ? 
          user?.coach_detail?.category?.name? user?.coach_detail?.category?.name : ""
          // user?.user_speciality?.map((item, index)=>{
          //   return(
          //     item?.speciality+(index!=user?.user_speciality?.length-1? ", ": '')
          //   )
          // })
          :
          user?.user_sports ? user?.user_sports.name : ""}
        </h5>
        {props.user?.user_role.name !== "Facilities" && (
          <React.Fragment>
            <div className="d-flex border-top border-green align-items-center justify-content-between pt-1">
              <span className="fs-14 text-green">
                <i className="fal fa-star fs-16 pe-1"></i>Reward Points
              </span>
              <span className="fs-22 semibold text-green3">
                {user?.total_reward}
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
      {/* {scheduleList.length > 0 && ( */}
      {updatedScheduleList.length > 0 && (
        <div className="d-flex justify-content-between mb-3">
          <div>
            <h4 className="medium fs-18">Upcoming Bookings</h4>
          </div>
          <div className="text-end">
            <Link className="fs-14 text-green" to={"/my-schedule"}>View All</Link>
          </div>
        </div>
       
      )}
      {/* {scheduleList?.map((item, i) => { */}
      {updatedScheduleList?.map((item, i) => {
        if(i > 1) return null;
        if(!item?.id) return null;
        return (
          <div
            className="booking-box rounded-8 border mb-4 overflow-hidden"
            key={i}
          >
            <div className="bg-lightgreen d-flex p-2 rounded-8 justify-content-between">
              <span className="fs-13 semibold">
                <img
                  src="../assets/img/icon/calendar.svg"
                  className="pe-2"
                  alt=""
                />
                {item.bookedDate}
              </span>
              <span className="fs-13 semibold time-star">
                <img src="../assets/img/icon/clock.svg" className="pe-2" alt="" />
                {item.start} to {item.end}
              </span>
            </div>

            <div className="p-2 mt-1">
              <div className="d-flex align-items-center gap-2 mb-2">
                <img
                  src={item.image ? item.image : "../assets/img/edit-profile.png"}
                  style={{
                    width: '40px',
                    height: '40px', 
                    objectFit: 'cover',
                    borderRadius: '50%'
                  }}
                  // width="40"
                  // height="40"
                  // className="rounded-pill dp-border w-40"
                  alt=""
                />
                <div>
                  <h6 className="fs-14 semibold">{item.name}</h6>
                  <div className="fs-13 text-lightgray m-0 d-flex align-items-center gap-1">
                    {item.user_sports}
                    <div className="d-inline-flex">
                      <ReactStars
                        count={5}
                        onChange={(e) => {}}
                        size={20}
                        isHalf={true}
                        edit={false}
                        value={item.user_star ? parseFloat(item.user_star) : 0}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p className="fs-12 pt-2">
                <i className="fa fa-map-marker-alt text-green pe-1 fs-16"></i>{" "}
                {item.address}
              </p>
            </div>
          </div>
        );
      })}
      {props.user?.user_role.name === "User" && (
        <React.Fragment>
          {kidsList.length > 0 && (
            <h4 className="medium fs-20 pb-3 pt-2">Manage Athletes Profile</h4>
          )}
          {kidsList.length > 0 && (
            <div className="mb-4">
              {kidsList.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="rounded-3 bg-lightgreen p-2 d-flex align-items-center justify-content-between gap-1 mb-1"
                  >
                    <img
                      src={item.image}
                      style={{
                        width: '45px',
                        height: '45px', 
                        objectFit: 'cover',
                        borderRadius: '50%'
                      }}
                      // width="45"
                      // height="45"
                      // className="rounded-50"
                      alt=""
                    />
                    <div className="w-75">
                      <h6 className="semibold">
                        {item.first_name} {item.last_name}
                      </h6>
                      <h6 className="fs-11 text-lightgray regular">
                        Age: {item.age}yrs
                      </h6>
                    </div>
                    <Link to="/manage-kids" className="fs-18 pe-3 text-green2">
                      <i className="far fa-chevron-right"></i>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
           <div className="d-flex justify-content-between">
            <div>
                <h4 className="medium fs-20 pb-2">Latest Jobs</h4>
            </div>
            <div>
              <Link to={"/job-listing"} className="text-green">
                View All Jobs
              </Link>
            </div>
          </div>
          {!jobList?.data?.length && <div>No Job Available</div>}
          {jobList?.data?.slice(0, 2).map((item, index) => {
            const createdDate = new Date(item?.created_at);
            const currentDate = new Date();
            // Calculate the difference in days
            const timeDifference = currentDate - createdDate;
            const daysDifference = Math.floor(
              timeDifference / (1000 * 3600 * 24)
            );
            if(index > 1) return null;
            return (
              <div key={index} className="border rounded-8 p-2 job-box mb-2">
                <div className="d-flex align-items-center gap-2 mb-2 pb-2">
                  <img
                    src={item?.user?.image}
                    style={{
                      width: '40px',
                      height: '40px', 
                      objectFit: 'cover',
                      borderRadius: '100%'
                    }}
                    // width="40"
                    // height="40"
                    // className="rounded-pill w-40"
                    alt=""
                  />
                  <h6 className="fs-14 semibold">{item?.user?.name}</h6>
                </div>

                <div className="">
                  <h5 className="semibold pb-2">{item?.job_title}</h5>
                  <h5 className="text-green medium">
                  ${item?.salary_from.toLocaleString("ja-JP")} - ${item?.salary_to.toLocaleString("ja-JP")}{" "}
                    <span className="fs-14 text-lightgray regular">{item?.salary_type === "monthly" ? "month" : "yearly"}</span>
                  </h5>
                  <h6 className="light py-2">
                    Job type: <span className="medium">  {item?.job_type === "full_time"
                        ? "Full Time"
                        : item?.job_type === "part_time"
                        ? "Part Time"
                        : item?.job_type === "freelance"
                        ? "Freelance"
                        : ""}</span>
                  </h6>
                  <h6 className="fs-13 regular mb-2">Posted {daysDifference} days ago</h6>

                  <Link to={`/job-details/${item?.id}`} className="butn butn-green fs-13 py-2 px-3">
                    View Detail
                  </Link>
                </div>
              </div>
            );
          })}
          {/* {jobList?.data?.length  > 1 ? 
          <Link to={'/job-listing'} className="text-green">View All Jobs</Link>
          : "" } */}
        </React.Fragment>
      )}

      {props.user?.user_role.name === "Coach" && (
        <React.Fragment>
          <div className="d-flex justify-content-between">
            <div>
                <h4 className="medium fs-20 pb-2">Latest Jobs</h4>
            </div>
            <div>
              <Link to={"/job-listing"} className="text-green">
                View All Jobs
              </Link>
            </div>
          </div>
         

          {!jobList?.data?.length && <div>No Job Available</div>}
          {jobList?.data?.slice(0, 2).map((item, index) => {
            const createdDate = new Date(item?.created_at);
            const currentDate = new Date();
            // Calculate the difference in days
            const timeDifference = currentDate - createdDate;
            const daysDifference = Math.floor(
              timeDifference / (1000 * 3600 * 24)
            );
            return (
              <div key={index} className="border rounded-8 p-2 job-box mb-2">
                <div className="d-flex align-items-center gap-2 mb-2 pb-2">
                  <img
                    src={item?.user?.image}
                    style={{
                      width: '40px',
                      height: '40px', 
                      objectFit: 'cover',
                      borderRadius: '100%'
                    }}
                    // width="40"
                    // height="40"
                    // className="rounded-pill w-40"
                    alt=""
                  />
                  <h6 className="fs-14 semibold">{item?.user?.name}</h6>
                </div>

                <div className="">
                  <h5 className="semibold pb-2">{item?.job_title}</h5>
                  <h5 className="text-green medium">
                    ${item?.salary_from.toLocaleString("ja-JP")} - ${item?.salary_to.toLocaleString("ja-JP")}{" "}
                    <span className="fs-14 text-lightgray regular">
                      {item?.salary_type === "monthly" ? "month" : "yearly"}
                    </span>
                  </h5>
                  <h6 className="light py-2">
                    Job type:{" "}
                    <span className="medium">
                      {" "}
                      {item?.job_type === "full_time"
                        ? "Full Time"
                        : item?.job_type === "part_time"
                        ? "Part Time"
                        : item?.job_type === "freelance"
                        ? "Freelance"
                        : ""}
                    </span>
                  </h6>
                  <h6 className="fs-13 regular mb-2">
                    Posted {daysDifference} days ago
                  </h6>

                  <Link to={`/job-details/${item?.id}`} className="butn butn-green fs-13 py-2 px-3">
                    View Detail
                  </Link>
                </div>
              </div>
            );
          })}
          {/* {jobList?.data?.length > 1 ? 
          <Link to={"/job-listing"} className="text-green">
            View All Jobs
          </Link>
          : "" } */}
        </React.Fragment>
      )}

      {props.user?.user_role.name === "Facilities" && (
        <React.Fragment>
          <h4 className="medium fs-20 pb-2">Open Jobs</h4>
          {!jobList?.data?.length && <div>No Job Open</div>}
          {jobList?.data?.slice(0, 2).map((item, index) => {
            const createdDate = new Date(item?.created_at);
            const currentDate = new Date();
            // Calculate the difference in days
            const timeDifference = currentDate - createdDate;
            const daysDifference = Math.floor(
              timeDifference / (1000 * 3600 * 24)
            );
            return (
              <div key={index} className="border rounded-8 p-2 job-box mb-2">
                <div className="d-flex align-items-center gap-2 mb-2 pb-2">
                  <img
                    src={item?.user?.image}
                    style={{
                      width: '40px',
                      height: '40px', 
                      objectFit: 'cover',
                      borderRadius: '100%'
                    }}
                    // width="40"
                    // height="40"
                    // className="rounded-pill w-40"
                    alt=""
                  />
                  <h6 className="fs-14 semibold">{item?.user?.name}</h6>
                </div>

                <div className="">
                  <h5 className="semibold pb-2">{item?.job_title}</h5>
                  <h5 className="text-green medium">
                    ${item?.salary_from.toLocaleString("ja-JP")} - ${item?.salary_to.toLocaleString("ja-JP")}{" "}
                    <span className="fs-14 text-lightgray regular">
                      {item?.salary_type === "monthly" ? "month" : "yearly"}
                    </span>
                  </h5>
                  <h6 className="light py-2">
                    Job type:{" "}
                    <span className="medium">
                      {" "}
                      {item?.job_type === "full_time"
                        ? "Full Time"
                        : item?.job_type === "part_time"
                        ? "Part Time"
                        : item?.job_type === "freelance"
                        ? "Freelance"
                        : ""}
                    </span>
                  </h6>
                  <h6 className="fs-13 regular mb-2">
                    Posted {daysDifference} days ago
                  </h6>
                  <Link to={`/job-details/${item?.id}`} className="butn butn-green fs-13 py-2 px-3">
                    View Detail
                  </Link>
                
                </div>
              </div>
            );
          })}
          {jobList?.data?.length > 1 ? 
          <Link to={"/manage-job"} className="text-green">
            Manage Jobs
          </Link>
          : ""}
        </React.Fragment>
      )}
      
    </div>
  );
}
const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(SideBar);
