import React, {useCallback, useState} from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { CommonAPi } from "../../API/CommonApi";
import ReactStars from "react-rating-stars-component";
import MessageRequestModal from '../../Components/messageRequestModal';
export default function CoachItem({ item, index, isFacilitie }) {
    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [messageDescription, setMsgDescription] = useState('');

    const history = useNavigate();
    const handleSendMessage = async (id, chatId) => {
        let data = {
            chat_receiver_id: id,
            message_description : messageDescription
        }
        try {
            let resp = await CommonAPi(Endpoints.CreateMessageRoomId, data);
            if (resp) {
                setModalIsOpen(false);
                if(resp.data?.status == "active"){
                    history("/message", {state: {chatId: chatId}});
                }else{
                    history("/message-request", {state: {chatId: chatId}});
                }
               
            }
        } catch (e) {
            if (e && e.response && e.response.message && e.response.message.email) {
                Swal.fire({
                    text: "This email is already used",
                    icon: "error",
                });
            }
        }
    };
    const newRating = item.reviews_rating;
    const rating = useCallback(() => {
        return (
            <ReactStars
                count={5}
                size={20}
                isHalf={true}
                edit={false}
                value={parseFloat(item.reviews_rating)}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
            />
        )
      }, [item, index, newRating]);
    
    return (
        <>
        {item ? 
        <div className="col-xl-3 col-lg-4  col-md-6 col-sm-6  mb-3 pe-sm-3" key={index}>
            <div className="border p-2 py-3 coach-box text-center">
                <span className="user-pp-80 mb-2">
                    <img
                        onClick={() =>
                            history(isFacilitie ? "/facilities-details/" + item?.slug : '/coach-details/' + item?.slug)
                        }
                        src={
                            item.image
                                ? item.image
                                : '../assets/img/edit-profile.png'
                        }

                        className="rounded-pill dp-border  c-p"
                        alt=""
                    />
                </span>


                <h5 className="semibold pb-2 c-p" 
                 onClick={() =>
                    history(isFacilitie ? "/facilities-details/" + item?.slug : '/coach-details/' + item?.slug)
                }
                >{item.name} {item.last_name}</h5>
                <h6 className="light text-lightgray d-flex gap-2 align-content-center pb-2 justify-content-center fs-12">
                {item?.role_id == 4 ?
                item?.facility_selected_amenity?.[0]?.amenity
                    // item?.facility_selected_amenity?.map((ite, ind)=>{
                    //     return(
                    //     ite?.amenity+(ind!=item?.facility_selected_amenity?.length-1? ", ": '')
                    //     )
                    // })
                    :
                    item?.role_id == 3 ? 
                    item?.coach_detail?.category?.name? item?.coach_detail?.category?.name : ""
                    // item?.user_speciality?.[0]?.speciality
                    // item?.user_speciality?.map((ite, ind)=>{
                    //     return(
                    //     ite?.speciality+(ind!=item?.user_speciality?.length-1? ", ": '')
                    //     )
                    // })
                    :
                    item?.user_sports ? item?.user_sports.name : ""}
                    {/* {isFacilitie ? item.user_sports && item.user_sports.name ? truncate(item.user_sports.name, 14) : '' : (item.coach_detail && item.coach_detail.category ? truncate(item.coach_detail.category.name) : '')} */}
                    <span className="d-inline-flex">
                        {rating()}
                        {/* {item.reviews_rating !== null ?
                            <ReactStars
                                count={5}
                                size={20}
                                isHalf={true}
                                edit={false}
                                value={item.reviews_rating}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                            />
                            :
                            <ReactStars
                                count={5}
                                size={20}
                                isHalf={true}
                                edit={false}
                                value={0}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                            />
                        } */}
                    </span>
                </h6>

                {/* <h4 className="fs-20 text-green semibold pb-2">
                    {item.profileData && item.profileData.price
                        ? "$" + item.profileData.price.toLocaleString("ja-JP") + "/"
                        : 0}
                    <span className="text-lightgray regular fs-14">
                        per slot
                    </span>
                </h4> */}

                <h6 className="fs-13 regular mb-2">
                    {item.user_booking_coach_facility_count} People coached
                </h6>

                <div className="d-flex gap-3 align-items-center justify-content-center">
                    <Link className="butn fs-13  px-3" to={isFacilitie ? '/facilities-details/' + item?.slug : '/coach-details/' + item?.slug} >View and Book</Link>
                    <Link onClick={() => {
                        if(item?.isMessageSend?.status==='active' || item?.isMessageSend?.status==='pending'){
                        handleSendMessage(item.id, item?.isMessageSend?.id)
                        }
                        else{
                            setModalIsOpen(true);
                        }
                    }}>
                        <img src="../assets/img/icon/messages2.svg" alt="" />
                    </Link>
                </div>
            </div>
        </div>
        : "" }

    <MessageRequestModal
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      coachInfo={item}
      handleSendMessage={handleSendMessage}
      messageDescription={messageDescription}
      setMsgDescription={setMsgDescription}
      />
        </>
    )
}
