import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { Endpoints } from "../../API/Endpoints";
import { ValidateList, ValidationTypes } from "../../Utils/ValidationHelper";
import { FetchApi } from "../../API/FetchApi";
import ToastMessage from "../../Utils/ToastMessage";
import Swal from "sweetalert2";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const history = useNavigate();

  const forgot = async (e) => {
    e.preventDefault();
    let validate_arr = [
      [email, ValidationTypes.Email, "Please enter a valid email"],
    ];
    let validate = await ValidateList(validate_arr);
    console.log(validate);
    if (!validate) {
     
      return;
    }
    let obj = {
      email: email,
      stage: "send",
    };

    try {
      let resp = await FetchApi(Endpoints.forgotPassword, obj);
      if (resp && resp.status === "success") {
        ToastMessage.Success(resp.message);
        history("/verify-otp", { state: email });
      }
      if (resp.status === "false") {
        Swal.fire({
          text: 'Please enter valid email',
          icon: "error",
        });
      }
    } catch (e) {
      console.log("forgotErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };

  return (
    <div className="main py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <h1 className="text-darkgray semibold pb-2 text-center">
              Forgot Password
            </h1>
            <p className="text-green2 text-center mb-3">
              No worries, we’ll send you reset instructions.
            </p>

            <form onSubmit={forgot}  className="site-form pt-2">
              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Email 
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  placeholder="Please Enter Your Email"
                />
              </div>
              <button
                type="submit"
                className="butn w-100 rounded-pill border-0"
                // disabled={!email}
              >
                Reset Password
              </button>
              {/* <input type="submit" className="butn w-100 rounded-pill border-0" value="Reset Password" /> */}
            </form>

            <div className="text-center pt-3">
              {/* <a href="#" className="text-green py-3 medium"> */}
              <Link className="text-green py-3 medium" to={"/Login"}>
                Back to Login
              </Link>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
