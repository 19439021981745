import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ToastMessage from '../Utils/ToastMessage';

const ShareModal = ({ show, onClose, slug }) => {

    const [url, setUrl] = useState(slug=='user'? "http://atrio.dignitech.com/" : `http://atrio.dignitech.com/view-availability/` + slug);

    const handleCopy = () => {
        navigator.clipboard.writeText(url);
        ToastMessage.Success('Link Copied');
    };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{
            // display: 'flex',
            // alignSelf: 'center'
        }}>Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='d-flex w-100 site-form'>
          <input
          style={{
            backgroundColor: '#EDF4E7',
            border: 'none',
            borderRadius: '30px'
          }}
            className='p-2'
            placeholder="Enter URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <Button 
          className='input-btn me-2'
        //   variant="primary" 
          onClick={handleCopy}>
            Copy
          </Button>
          </div>
          <div className='d-flex justify-content-center py-3'>
            <p style={{
                 color:'#909C88'
            }}>Or share via:</p>
          </div>
          <div style={{
                display: 'flex',
                justifyContent: 'center'
            }} className="mb-4">
                <div style={{backgroundColor: '#EDF4E7', borderRadius: '100%', padding: '10px'}} className="my-i">
                    <a href="https://www.facebook.com/" target='_blank'><img src="../assets/img/Facebook.png" alt="" /></a>
                </div>
                <div 
                style={{backgroundColor: '#EDF4E7', borderRadius: '100%', padding: '10px'}}
                className="my-i ms-4">
                    <a href="https://www.instagram.com/" target='_blank'><img src="../assets/img/Instagram.png" alt="" /></a>
                </div>
                <div
                style={{backgroundColor: '#EDF4E7', borderRadius: '100%', padding: '10px'}}
                // style={{
                //     paddingLeft: '15px'
                // }}
                className="my-i ms-4">
                    <a href="https://www.linkedin.com/" target='_blank'><img src="../assets/img/LinkedIn.png" alt="" /></a>
                </div>
                <div 
                style={{backgroundColor: '#EDF4E7', borderRadius: '100%', padding: '10px'}}
                // style={{
                //     paddingLeft: '15px'
                // }}
                className="my-i ms-4">
                    <a href="https://web.whatsapp.com/" target='_blank'><img src="../assets/img/Whatsapp.png" alt="" /></a>
                </div>
            </div>
      </Modal.Body>
      {/* Optional footer for additional actions */}
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default ShareModal;