import Swal from "sweetalert2";

function Message({type, text}){
    if(type=="Success"){
        Swal.fire({
            text,
            // type : 'success'
        })

    }
    if(type=="Error"){
        Swal.fire({
            text,
            icon : 'warning'
            // type : 'danger'
        })
    }
    // alert(message);
}
export default {
    Success : (text)=> Message({type:'Success',text}),
    Error : (text) =>Message({type : 'Error',text})
}

// export default ToastMessage;