import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
import { useState } from "react";
import { monthNames } from "../Utils/constants";
const $ = window.jQuery;
function RatingView(props) {
  const [rating, setRating] = useState('');
  const [message, setMessage] = useState('');
	const history = useNavigate();

  const ratingChanged = (newRating) => {
    setRating(newRating)
  };
  const saveReview = async (e) => {
    e.preventDefault();
    const dataObject = {
      user_id: props.userInfo.id,
      rate: rating,
      review: message
    }
    if(!rating){
      ToastMessage.Error('Rating is required');
      return
    }
    try {
      let resp = await FetchApi(Endpoints.userReview, dataObject);
      if (resp && resp.type === "success") {
        $('#addreview').modal('hide');
        ToastMessage.Success(resp.message);
      }
      else {
        ToastMessage.Error(JSON.stringify(resp.message));
      }
    } catch (e) {
      console.log("availability__err", JSON.stringify(e.response, null, 4));
    }
  };
  return (
    <div>
      <div className="customer-rating pt-3 pb-4">
        <div className="d-sm-flex align-items-center gap-2">
          <h3 className="medium pb-2">Customer Rating & Reviews</h3>
          {props.pageFrom !== 'profile' && <a onClick={(e) => e.preventDefault()} className="gradient-butn butn c-p" data-bs-toggle="modal" data-bs-target="#addreview">Write Review</a>}
        </div>
        <div className="d-flex align-items-center gap-2 pb-2">
          <span className="d-inline-flex">
            <ReactStars
              count={5}
              onChange={(e) => { }}
              size={20}
              isHalf={true}
              edit={false}
              value={props?.userInfo?.reviews_rating ? parseFloat(props.userInfo.reviews_rating) : 0}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
          </span>
          <span className="fs-13 medium">
            {props?.userInfo?.reviews_rating ? parseFloat(props.userInfo.reviews_rating) : 0}{" "}
            <span className="text-lightgray regular">Ratings</span>{" "}
            | {props.userInfo.user_reviews.length > 100 ? '100+' : props.userInfo.user_reviews.length}{" "}
            <span className="text-lightgray regular">Reviews</span>
          </span>
        </div>
          {props.userInfo.user_reviews.length == 0  ? 
                <p className="customer-feedback">No review found</p>
          : "" }
        <div className="customer-feedback pt-4">
          {props.userInfo.user_reviews && props.userInfo.user_reviews.length > 0 && <React.Fragment>
            {props.userInfo.user_reviews.slice(0, 4).map((item) => {
              return (<div 
              className="d-flex align-items-start gap-3 mb-4" 
              key={item.id}>
                <div className="w-100">
                  <div
                  className="c-p"
                  onClick={()=>{
                    if (item.review_given_by.user_role.name === 'Coach') {
                      history('/coach-details/' + item.review_given_by?.slug);
                    } else if (item.review_given_by.user_role.name === 'Facilities') {
                      history('/facilities-details/' + item.review_given_by?.slug);
                    } else {
                      history('/user-details/' + item.review_given_by?.slug);
                    }
                  }}
                  >
                  <img
                    src={item.review_given_by.image}
                    style={{
                      width: '50px',
                      height: '50px', 
                      objectFit: 'cover',
                      borderRadius: '50%',
                      // backgroundColor: 'red'
                    }}
                    // className="rounded-pill rounded-circle object-cover customer-pic mw-50"
                    // width="50"
                    // height="50"
                    alt=""
                  />
                  </div>
                </div>
                <div>
                  <h4 
                  onClick={()=>{
                    if (item.review_given_by.user_role.name === 'Coach') {
                      history('/coach-details/' + item.review_given_by?.slug);
                    } else if (item.review_given_by.user_role.name === 'Facilities') {
                      history('/facilities-details/' + item.review_given_by?.slug);
                    } else {
                      history('/user-details/' + item.review_given_by?.slug);
                    }
                  }}
                  className="fs-16 pb-1 c-p">{item.review_given_by.name} {item.review_given_by.last_name}</h4>
                  <h6 className="fs-14 text-lightgray regular pb-2">
                    {`${new Date(item.created_at).getDate()} ${monthNames[new Date(item.created_at).getMonth()]} ${new Date(item.created_at).getFullYear()}`}
                  </h6>
                  <div className="d-flex align-items-center gap-2 pb-2">
                    <span className="d-inline-flex">
                      <ReactStars
                        count={5}
                        onChange={(e) => { }}
                        size={20}
                        isHalf={true}
                        edit={false}
                        value={item.rate_value ? parseFloat(item.rate_value) : 0}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                      />
                    </span>
                    <span className="fs-13 medium">{item.rate_value}</span>
                  </div>

                  <p className="light text-justify mb-2">{item.review}.
                  </p>
                </div>
              </div>)
            })}
          </React.Fragment>}
          

          {props.userInfo.user_reviews.length > 4 && <a className="text-green c-p" onClick={(e) => {
            e.preventDefault();
            $('#viewAllReviews').modal('show');
          }}>View All Ratings & Reviews</a>}
        </div>
      </div>
      <div className="modal fade" id="addreview" tabIndex="-1" aria-labelledby="addreviewLabel" aria-hidden="true">
        <div className="modal-dialog ">
          <div className="modal-content p-lg-3">
            <div className="modal-header border-0 p-2">
              <button type="button" className="modal-close bg-transparent border-0" data-bs-dismiss="modal" aria-label="Close">
                <img src="../assets/img/icon/close-circle.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <h3 className="medium pb-3">Rating & Review</h3>
              <form onSubmit={saveReview} className="">
                <div className="form-field mb-3">
                  <label className="d-block pb-1">Leave rating</label>
                  <div className="d-flex gap-1">
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={24}
                      isHalf={true}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ffd700"
                    />
                  </div>
                </div>
                <div className="form-field mb-2">
                  <label className="d-block pb-1">Write your review</label>
                  <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} rows="4" className="border p-3 fs-14 rounded-8" placeholder="Write Something"></textarea>
                </div>
                <button className="butn rounded-pill fs-13 regular border-0" type="submit">Submit</button>
              </form>
            </div>

          </div>
        </div>
      </div>
      <div
        className="modal fade "
        id="viewAllReviews"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content border-0 rounded-10">
            <div className="modal-header border-0 p-2">
              <button
                type="button"
                className="modal-close bg-transparent border-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="../assets/img/icon/close-circle.svg" alt="" />
              </button>
            </div>

            <div className="modal-body">
              <h3 className="text-darkgray pb-3">
                All Reviews
              </h3>
              {props.userInfo.user_reviews && props.userInfo.user_reviews.length > 0 && <React.Fragment>
                {props.userInfo.user_reviews.map((item) => {
                  return (<div key={item.id} className="d-flex justify-content-between align-items-center people-list rounded-10 mb-3">
                    <div className="d-flex align-items-start gap-3 mb-4" >
                      <div>
                        <img
                          src={item.review_given_by.image}
                          style={{
                            width: '50px',
                            height: '50px', 
                            objectFit: 'cover',
                            borderRadius: '50%'
                          }}
                          // className="rounded-pill rounded-circle object-cover customer-pic mw-50"
                          // width="50"
                          // height="50"
                          alt=""
                        />
                      </div>
                      <div>
                        <h4 className="fs-16 pb-1">{item.review_given_by.name} {item.review_given_by.last_name}</h4>
                        <h6 className="fs-14 text-lightgray regular pb-2">
                          {`${new Date(item.created_at).getDate()} ${monthNames[new Date(item.created_at).getMonth()]} ${new Date(item.created_at).getFullYear()}`}
                        </h6>
                        <div className="d-flex align-items-center gap-2 pb-2">
                          <span className="d-inline-flex">
                            <ReactStars
                              count={5}
                              onChange={(e) => { }}
                              size={20}
                              isHalf={true}
                              edit={false}
                              value={item.rate_value ? parseFloat(item.rate_value) : 0}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          </span>
                          <span className="fs-13 medium">{item.rate_value}</span>
                        </div>

                        <p className="light text-justify mb-2">{item.review}.
                        </p>
                      </div>
                    </div>
                  </div>)
                })}
              </React.Fragment>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingView;
