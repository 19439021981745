import React, { useEffect, useState } from "react";
import { ValidateList, ValidationTypes } from "../../Utils/ValidationHelper";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Swal from "sweetalert2";
// import CKEditor from "ckeditor4-react";
import { formatTime } from "../../Utils/constants";
import { useNavigate } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { connect } from "react-redux";
import { CKEditor } from "ckeditor4-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from "../../Components/loader";

function JobCreation(props) {
  const [values, setValues] = useState("");
  const [availabilityList, setAvailabilityList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate()

  /// editor configuration  ///
  const editorConfig = {
    toolbar: [
      {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Strike"],
      },
      {
        name: "lists",
        items: ["NumberedList", "BulletedList"],
      },
      {
        name: "blockquote",
        items: ["Blockquote"],
      },
      {
        name: "insert",
        items: ["Link", "Unlink"],
      },
      {
        name: "maximize",
        items: ["Maximize"],
      },
    ],
    extraPlugins: "editorplaceholder",
    editorplaceholder: "Write job roles and responsiblites",
  };

  const editorConfig2 = {
    toolbar: [
      {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Strike"],
      },
      {
        name: "lists",
        items: ["NumberedList", "BulletedList"],
      },
      {
        name: "blockquote",
        items: ["Blockquote"],
      },
      {
        name: "insert",
        items: ["Link", "Unlink"],
      },
      {
        name: "maximize",
        items: ["Maximize"],
      },
    ],
    extraPlugins: "editorplaceholder",
    editorplaceholder: "Write job description",
    removePlugins: 'elementspath'
  };

  /// editor configuration  ///

  const getAvailability = async (slug) => {
    try {
      let resp = await FetchApi(Endpoints.getUserAvailability, { user_slug: props.user.slug });
      if (resp && resp.type === "success") {
        let avaailabilityList = resp.data
        avaailabilityList.forEach((item) => {
          item.availability_timing.forEach((time) => {
            time['start'] = time.time_from ? formatTime(time.time_from) : ''
            time['end'] = time.time_to ? formatTime(time.time_to) : ''
            time['show'] = time.time_from ? true : false
          })
        })
        setAvailabilityList(avaailabilityList)
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }

  const handleChange = (event, data) => {
    if (event.persist) event.persist();
    if (typeof data !== "undefined") {
      setValues((values) => ({
        ...values,
        [event]: data,
      }));
    } else {
      setValues((values) => ({
        ...values,

        [event?.target?.name]: event?.target?.value,
      }));
    }
  };
  const createJobOnSubmit = async (e) => {
    e.preventDefault();
    let validate_arr = [
      [values.job_title, ValidationTypes.Empty, "Please enter title"],
      [
        values.job_description,
        ValidationTypes.Empty,
        "Please enter description",
      ],
      // [
      //   values.role_responsibility,
      //   ValidationTypes.Empty,
      //   "Please enter responsibility",
      // ],
      [values.job_type, ValidationTypes.Empty, "Please Select Job Type"],
      [values.salary_type, ValidationTypes.Empty, "Please Select Salary Type"],
      [values.salary_from, ValidationTypes.Empty, "Please Enter Salary"],
      [values.salary_to, ValidationTypes.Empty, "Please Enter Salary"],
      [
        values.job_expire_date,
        ValidationTypes.Empty,
        "Please enter job valid date to",
      ],
      // [
      //   values.availability_id,
      //   ValidationTypes.Empty,
      //   "Please select Location",
      // ],
    ];

    let validate = await ValidateList(validate_arr);
    if (!validate) {
      return;
    }
    let obj = {
      job_title: values.job_title,
      job_description: values.job_description,
      role_responsibility: values.role_responsibility,
      job_type: values.job_type,
      salary_type: values.salary_type,
      salary_from: values.salary_from,
      salary_to: values.salary_to,
      availability_id: values.availability_id,
      job_expire_date: values.job_expire_date.replaceAll("-", "/"),
    };

    try {
      setLoading(true);
      let resp = await CommonAPi(Endpoints.createJob, obj);
      if (resp && resp.status === "success") {
        setLoading(false);
        navigate('/manage-job')
        ToastMessage.Success(resp.message);
      }
      if (resp && resp.status === "false") {
        setLoading(false);
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      setLoading(false);
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message) {
        Swal.fire({
          text: "Failed to delete the post",
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    getAvailability()
  },[])

  const handleCancel = () =>{
    Swal.fire({
        text: "You have unsaved changes, do you still want to exit?",
        icon: "question",
        customClass: {
          confirmButton: "btn px-3 mx-2",
          cancelButton: "btn btn-br-blue px-3",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, discard it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setValues("");
          navigate('/manage-job')
        }
      });
};

  return (
    <div className="wrapper">
      <div className="main py-5">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-6 col-md-8">
              <h1 className="text-darkgray semibold pb-2 text-center fs-24">
                Create Job Listing
              </h1>

              <form onSubmit={createJobOnSubmit} className="site-form pt-2">
                <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Job Title
                  </label>
                  <input
                    onChange={handleChange}
                    value={values?.job_title}
                    name="job_title"
                    type="text"
                    placeholder="Enter Job Title"
                  />
                </div>

                <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Job Description
                  </label>

                  <ReactQuill 
                  theme="snow" 
                  style={{
                    height: '180px',
                    marginBottom: '70px'
                  }}
                  value={values?.job_description} 
                  onChange={(event) => {
                    // console.log('efgh__', event)
                    handleChange("job_description", event);
                  }}
                  placeholder={values?.job_description ? "Placeholder for job description" : "Write job roles and responsibilities"}
                  />

                  {/* <CKEditor
                    config={editorConfig2}
                  //   config={{
                  //     removePlugins: 'elementspath', // Remove the elementspath plugin which usually contains the footer bar
                  //     // Other configuration options as needed
                  // }}
                    data={values?.job_description}
                    onChange={(event) => {
                      handleChange("job_description", event.editor.getData());
                    }}
                    placeholder={values?.job_description ? "Placeholder for job description" : "Write job roles and responsibilities"}
                  /> */}
                </div>

                {/* <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Roles and Responsibilities
                  </label>
                  <div>
                    <CKEditor
                      config={editorConfig}
                      data={values?.role_responsibility}
                      onChange={(event) => {
                        handleChange(
                          "role_responsibility",
                          event.editor.getData()
                        );
                      }}
                      placeholder={values?.role_responsibility ? "Placeholder for role responsibilities" : "Write job roles and responsibilities"}
                    />
                  </div>
                </div> */}

                {/* <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Location
                  </label>
                  <select
                    onChange={handleChange}
                    value={values?.availability_id}
                    name="availability_id"
                    id=""
                  >
                    <option value="Select Sports">Location Available</option>
                    {availabilityList.map((item, index) => {
                      return (
                        <option style={{
                          // borderBottomWidth: '10px',
                          // borderBottomStyle:'solid',
                          // borderBottomColor: 'red'
                        }} key={index} value={item?.id}>{
                          item?.location_name
                          // item?.location_name.length > 80 ? item?.location_name.substring(0, 80) : item?.location_name.length 
                          }
                        </option>
                      )
                    })}
              
                  </select>
                </div> */}

                <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Job Type
                  </label>
                  <select
                    onChange={handleChange}
                    value={values?.job_type}
                    name="job_type"
                    id=""
                  >
                    <option value="Select Sports">Job Type</option>
                    <option value="full_time">Full Time</option>
                    <option value="part_time">Part Time</option>
                    <option value="freelance">Freelance</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="" className="pb-1">
                    Salary (Optional)
                  </label>
                  <div className="monthly d-flex align-items-center overflow-hidden">
                    <select
                      onChange={handleChange}
                      value={values?.salary_type}
                      name="salary_type"
                      id=""
                      className="border-0 w-26 ps-md-3 ps-1 "
                    >
                      <option value="Select Salary">Salary Type</option>
                      <option value="hourly">Hourly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                    <input
                      onChange={handleChange}
                      value={values?.salary_from?.toLocaleString("ja-JP")}
                      name="salary_from"
                      type="number"
                      className="border-0 w-75 border-start rounded-0"
                      placeholder="00"
                    />
                    <input
                      onChange={handleChange}
                      value={values?.salary_to}
                      name="salary_to"
                      type="number"
                      className="border-0 w-75 border-start rounded-0"
                      placeholder="00"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="" className="pb-1">
                    Job Valid Until
                  </label>
                  <div className="monthly d-flex align-items-center overflow-hidden">
                    <input
                      onChange={handleChange}
                      value={values?.job_expire_date}
                      name="job_expire_date"
                      type="date"
                      className="border-0  custom-date rounded-0"
                      placeholder="00"
                    />
                  </div>
                </div>

                <div className="d-flex gap-2">
                <a
                href='#'
                    onClick={()=>{
                    handleCancel()
                    }}
                    className="gray-butn butn w-50 rounded-pill border-0"
                >
                    Cancel
                </a>
                <button
                  disabled={isLoading? true : false}
                  type="submit"
                  className="butn w-100 rounded-pill border-0"
                >
                  {isLoading? <Loader isButton={true}/> : "Post Job Opening"}
                </button>

                </div>

              
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, {})(JobCreation);
// export default JobCreation;
