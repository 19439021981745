import React, { useEffect, useState } from 'react'
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
const $ = window.jQuery;

export default function Promotions() {
    const [data, setData] = useState([]);
    const [Id, setId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            let resp = await CommonAPi(Endpoints.GetPromotions, {});
            if (resp && resp.status === "success") {
                setData(resp.data);
            }
        } catch (e) {
            if (e && e.response && e.response.message) {
                Swal.fire({
                    text: e.response.message,
                    icon: "error",
                });
            }
        }
    };

    const handleOpenModel = (id) => {
        setId(id);
        $('#createpromotion').modal('show');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            advertisement_id: Id,
            name: name,
            phone: phone,
            email: email,
            message: message
        }
        try {
            let resp = await CommonAPi(Endpoints.AddAdvertisementLead, data);
            if (resp && resp.status === "success") {
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                $('#createpromotion').modal('hide');
                Swal.fire({
                    text: resp.message,
                    icon: "success",
                });
            }else{
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                $('#createpromotion').modal('hide');
                Swal.fire({
                    text: resp.message,
                    icon: "info",
                });
            }
        } catch (e) {
            if (e && e.response && e.response.message) {
                Swal.fire({
                    text: e.response.message,
                    icon: "error",
                });
            }
        }
    };

    const handleClickPromotion = async(Id) =>{
        let data = {
            advertisement_id:Id
        }
        try {
            let resp = await CommonAPi(Endpoints.AddAdvertisementClick, data);
        } catch (e) {
            if (e && e.response && e.response.message) {
                Swal.fire({
                    text: e.response.message,
                    icon: "error",
                });
            }
        }
    };
    return (
        <>
            {data.length > 0 ?
                <div className="col-lg-3 right-sidebar">
                    <h4 className="fs-20 medium pb-2">Promotions</h4>
                    {data.map((item, index) => {
                        return (
                            <>
                                {item?.add_type == "lead" ?
                                   
                                        <div className="border rounded-8 p-2 promo-box mb-3" key={index}>
                                            <div className="d-flex align-items-center gap-2 mb-2">
                                                <img
                                                    src={item?.user?.image}
                                                    width="40"
                                                    height="40"
                                                    className="rounded-pill w-40"
                                                    alt=""
                                                />
                                                <div>
                                                    <h6 className="fs-14 semibold">{item?.user?.name} {item?.user?.last_name}</h6>
                                                    <p className="fs-13 text-lightgray m-0">{item?.user?.user_sports?.name}</p>
                                                </div>
                                            </div>
                                            {item?.image ?
                                                <img
                                                    src={item?.image}
                                                    className="w-100 rounded-8"
                                                    alt=""
                                                />
                                                : ""}

                                            <div className="mt-2">
                                                <p className="fs-14 text-green">
                                                    {item?.add_title}
                                                </p>
                                                {item?.add_type == "lead" ?
                                                    <Link className="butn butn-green fs-13 py-2 px-3" onClick={() => {
                                                        handleOpenModel(item?.id)
                                                    }}>
                                                        Get Offer
                                                    </Link>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                
                                    :

                                    <Link to={"/facilities-details/"+item?.user?.slug} onClick={()=>{
                                        handleClickPromotion(item?.id)
                                    }}>
                                        <div className="border rounded-8 p-2 promo-box mb-3" key={index}>
                                            <div className="d-flex align-items-center gap-2 mb-2">
                                                <img
                                                    src={item?.user?.image}
                                                    width="40"
                                                    height="40"
                                                    className="rounded-pill w-40"
                                                    alt=""
                                                />
                                                <div>
                                                    <h6 className="fs-14 semibold">{item?.user?.name} {item?.user?.last_name}</h6>
                                                    <p className="fs-13 text-lightgray m-0">{item?.user?.user_sports?.name}</p>
                                                </div>
                                            </div>
                                            {item?.image ?
                                                <img
                                                    src={item?.image}
                                                    className="w-100 rounded-8"
                                                    alt=""
                                                />
                                                : ""}

                                            <div className="mt-2">
                                                <p className="fs-14 text-green">
                                                    {item?.add_title}
                                                </p>
                                                {item?.add_type == "lead" ?
                                                    <Link className="butn butn-green fs-13 py-2 px-3" onClick={() => {
                                                        handleOpenModel(item?.id)
                                                    }}>
                                                        Get Offer
                                                    </Link>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    </Link>
                                }
                            </>

                        )
                    })}




                </div>
                : ""}

            {/* <!-- Modal --> */}
            <div
                className='modal fade '
                id='createpromotion'
                tabIndex='-1'
                aria-hidden='true'
                data-bs-backdrop='static'
                data-bs-keyboard='false'
            >
                <div className='modal-dialog modal-dialog-scrollable'>
                    <div className='modal-content border-0 rounded-10'>
                        <div className='modal-header border-0 p-2'>
                            <button
                                type='button'
                                className='modal-close bg-transparent border-0'
                                onClick={(e) => {
                                    if (
                                        name || email || phone || message
                                    ) {
                                        Swal.fire({
                                            title: 'Are you sure?',
                                            text: 'Are you sure you want to discard this post',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            confirmButtonText: 'Yes',
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                setName("");
                                                setEmail("");
                                                setPhone("");
                                                setMessage("");
                                                $('#createpromotion').modal('hide');
                                            }
                                        });
                                    } else {
                                        $('#createpromotion').modal('hide');
                                    }
                                }}
                            >
                                <img src='assets/img/icon/close-circle.svg' alt='' />
                            </button>
                        </div>
                        <div className='modal-body'>
                            <h3 className='text-darkgray pb-3 text-center'>
                                Add Advertisement Lead
                            </h3>
                            <form className='site-form' onSubmit={handleSubmit}>
                                <div className="form-field mb-3">
                                    <label className="pb-1">
                                        Name
                                    </label>
                                    <input
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                        type="text"
                                        placeholder="Enter Name"
                                    />

                                </div>

                                <div className="form-field mb-3">
                                    <label className="pb-1">
                                        Phone
                                    </label>
                                    <input
                                        value={phone}
                                        onChange={(e) => {
                                            setPhone(e.target.value)
                                        }}
                                        type="text"
                                        placeholder="Enter Phone"
                                    />

                                </div>
                                <div className="form-field mb-3">
                                    <label className="pb-1">
                                        Email
                                    </label>
                                    <input
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                        type="text"
                                        placeholder="Enter Email"
                                    />

                                </div>
                                <div className="form-field mb-3">
                                    <label className="pb-1">
                                        Message
                                    </label>
                                    <textarea
                                        placeholder="Enter Message"
                                        onChange={(e) => {
                                            setMessage(e.target.value)
                                        }}
                                        value={message}
                                    >

                                    </textarea>

                                </div>


                                <button
                                    className="butn butn-green fs-13 py-2 px-3"
                                    type='submit'
                                >
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Modal --> */}

        </>
    )
}
