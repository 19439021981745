import React, { useEffect, useState } from 'react'
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";

export default function RewardPoints() {
  const [List, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [Pagination, setPagination] = useState({});


  useEffect(() => {
    getRewardInfo();
    getUserInfo();
  }, []);
  const getRewardInfo = async () => {
    try {
      let resp = await FetchApi(Endpoints.UserRewardTransactions, {});
      if (resp && resp.type === "success") {
        setList(resp?.data?.data);
        setTotal(resp?.data?.total_reward);
        setPagination(resp.data);
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  };
  const getUserInfo = async () => {
    try {
      let resp = await FetchApi(Endpoints.userDetail, {});
      if (resp && resp.type === "success") {
        setTotal(resp?.data?.total_reward);
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  };


  return (
    <div className="wrapper">
      <div className="main py-5">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div>
              <h1 className="fs-24 medium pb-2">
                Reward & Points
              </h1>
            </div>
            <div className="text-end">
              <div className="d-flex  border-green align-items-center justify-content-between pt-1">
                <span className="fs-14 text-green">
                  <i className="fal fa-star fs-16 pe-1"></i>Reward Points :
                </span>
                <span className="fs-22 semibold text-green3 mx-2">
                  {total}
                </span>
              </div>
            </div>
          </div>


          <table className="job-table w-100">
            <thead>
              <tr className="fs-14 text-green table-head bg-lightgreen">
                <th>Amount</th>
                <th>Description</th>
                <th>Reward Type</th>

                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {List?.map((item, index) => {
                return (
                  <tr key={index} className="border">
                    <td>
                      <h5 className="medium pb-2">{item?.amount}</h5>

                    </td>

                    <td>
                      <h6 className="medium pb-2 ">
                        {item?.description}
                      </h6>


                    </td>

                    <td>
                      <h6 className="medium">
                        {item?.reward_type == "cr" ? "Credit" : "Debit"}
                      </h6>
                    </td>

                  </tr>
                );
              })}
            </tbody>
          </table>
          {Pagination.length > 0 && (
            <div className="paginations text-start py-3">
              <span className="me-2">
                Page 1 of{" "}
                {Math.ceil(Pagination.total / Pagination.per_page)}
              </span>
              {Pagination.links.map((item, i) => {
                return item.url ? (
                  <React.Fragment key={i}>
                    <Link
                      className={item.active ? "page-active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        // getData(item.url);
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: item.label }}
                      ></span>
                    </Link>
                  </React.Fragment>
                ) : null;
              })}
            </div>
          )}

        </div>
      </div>
    </div>
  )
}
