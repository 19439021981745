import React, { useEffect, useState } from "react";
import { Button, Modal, Toast } from "react-bootstrap";
import ToastMessage from "../Utils/ToastMessage";
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import moment from "moment";
import { ValidateList, ValidationTypes } from "../Utils/ValidationHelper";
import WeekdayButtons from "./weekdayButtons";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import Loader from "./loader";

export default function CreateAvailabilityModal({
    handleSendMessage,
    coachInfo,
    modalIsOpen,
    setModalIsOpen,
    messageDescription, 
    setMsgDescription,
    eventDetails,
    setEventDetails,
    getUserAvailability
}) {
    const [selectedDay, setSelectedDay] = useState(0);
    const [value, setValue] = React.useState(dayjs('2024-07-05T15:30'));
    const [initialData, setInitialData] = useState(eventDetails);
    const [isLoading, setLoading] = useState(false);

    const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  
    const handleButtonClick = (day) => {
      setSelectedDay(day);
    };

    // console.log('initicancel__', eventDetails?.is_want_cancellation_charge);
    // console.log('chnaged__', eventDetails?.endTime);
    // console.log('chnage2__', eventDetails?.startTime);

    const [userDetail, setUserDetail] = useState({});

    const saveAvailability = async()=>{
        let validate_arr = [
        [
            eventDetails?.title,
            ValidationTypes.Empty,
            "Event name is required",
        ],
        [eventDetails?.recurrences, ValidationTypes.Empty, "Recurrence is required"],
        [eventDetails.startDate, ValidationTypes.Empty, "Start Date is required"],
        [eventDetails.endDate, ValidationTypes.Empty, "End Date is required"],
        [eventDetails.startTime, ValidationTypes.Empty, "Start time is required"],
        [eventDetails.endTime, ValidationTypes.Empty, "End time is required"],
        // [eventDetails?.is_want_cancellation_charge, ValidationTypes.Empty, "Select cancellation charge"],
        // [eventDetails?.slot_type, (ValidationTypes.Empty && userDetail?.user_role?.id != 4), "Slot type is required"],
        // [facilityFee, ValidationTypes.Empty && slotType=='fully' , "Fee is required"],
        ];
        let validate = await ValidateList(validate_arr);
        if (!validate) {
        return;
        }
        if(!eventDetails?.slot_type && userDetail?.user_role?.id == 4){
            ToastMessage.Error("Slot type is required");
            return
        }
        if(eventDetails?.recurrences == 'weekly' && !eventDetails?.repeat_days){
            ToastMessage?.Error('Select Repeat Days');
            return
        }
        if(eventDetails?.slot_type=='fully' && !eventDetails?.facility_fully_fee){
            ToastMessage?.Error('Enter Facility fee');
            return
        }
        if(moment(eventDetails.endDate)?.isBefore(eventDetails?.startDate)){
            ToastMessage.Error("The end date can't be before the start date.")
            return
        }
        if(userDetail?.user_role?.id != 4 && !eventDetails?.max_athlete){
            ToastMessage.Error('Maximum athletes field is required');
            return
        }
        if(userDetail?.user_role?.id != 4 && !eventDetails?.athlete_fee){
            ToastMessage.Error('Athlete fee is required');
            return
        }
        if(userDetail?.user_role?.id == 4 && !eventDetails?.facility_selected_amenity_id){
            ToastMessage.Error('Amenity is required');
            return
        }
        if(userDetail?.user_role?.id == 4 && eventDetails?.slot_type=='fully' && !eventDetails?.max_occupancy){
            ToastMessage.Error('Maximum occupancy is required');
            return
        }
        if(userDetail?.user_role?.id == 4 && eventDetails?.slot_type!='fully' && !eventDetails?.max_athlete){
            ToastMessage.Error('Max athletes field is required');
            return
        }
        if(userDetail?.user_role?.id == 4 && eventDetails?.slot_type!='fully' && !eventDetails?.max_coaches){
            ToastMessage.Error('Max coaches field is required');
            return
        }
        if(userDetail?.user_role?.id == 4 && eventDetails?.slot_type!='fully' && !eventDetails?.athlete_fee){
            ToastMessage.Error('Athlete fee is required');
            return
        }
        if(userDetail?.user_role?.id == 4 && eventDetails?.slot_type!='fully' && !eventDetails?.coach_fee){
            ToastMessage.Error('Coach fee is required');
            return
        }
        if(
            eventDetails?.slot_type=='fragmented' && 
            eventDetails?.user_bookings?.filter((item)=> item?.user?.user_role?.id == 3)?.length > eventDetails?.max_coaches
            // eventDetails?.max_coaches < initialData?.max_coaches
        ){
            ToastMessage.Error('Maximum coaches number cannot be less than previously added number because those slots are booked.');
            return
        }
        if(
            eventDetails?.slot_type=='fragmented' && 
            eventDetails?.user_bookings?.filter((item)=> item?.user?.user_role?.id == 2)?.length > eventDetails?.max_athlete
            // eventDetails?.max_coaches < initialData?.max_coaches
        ){
            ToastMessage.Error('Maximum athlete number cannot be less than previously added number because those slots are booked.');
            return
        }
        if(!eventDetails?.id && !eventDetails?.is_want_cancellation_charge){
            ToastMessage?.Error('Select cancellation charge');
            return
        }
        if(eventDetails?.is_want_cancellation_charge == 1 && !eventDetails?.cancellation_charge){
            ToastMessage.Error('Cancellation charge field is required');
            return
        }
        if(moment(eventDetails.startDate)?.isSame(eventDetails.endDate)){
            const today = new Date().toISOString().split('T')[0];
            const startDateTime = new Date(`${today}T${dayjs(eventDetails.startTime)?.format('HH:mm')}`);
            const endDateTime = new Date(`${today}T${dayjs(eventDetails.endTime)?.format('HH:mm')}`);
      
            if(startDateTime > endDateTime){
                ToastMessage.Error("The end time can't be before the start time on the same day.");
                return
            }
        }
        if(eventDetails?.cancellation_charge){
            if(eventDetails?.slot_type=='fully' && parseFloat(eventDetails?.cancellation_charge) > parseFloat(eventDetails?.facility_fully_fee)){
                ToastMessage.Error("Cancellation charge can't be more than facility fee");
                return
            }
            else if(
                (eventDetails?.coach_fee && (parseFloat(eventDetails?.cancellation_charge)> parseFloat(eventDetails?.coach_fee))) 
                || 
                (eventDetails?.athlete_fee && (parseFloat(eventDetails?.cancellation_charge) > parseFloat(eventDetails?.athlete_fee)))){
                    // console.log('eve__', eventDetails)
                ToastMessage.Error("Cancellation charge can't be more than coach/athlete fee");
                return
            }
        }
        let obj = {
            // "id":"69",
            facility_selected_amenity_id: eventDetails?.facility_selected_amenity_id,
            event_name: eventDetails?.title,
            slot_type: userDetail?.user_role?.id != 4 ? "fragmented" : eventDetails?.slot_type,
            slot_start_date: moment(eventDetails.startDate)?.format('YYYY/MM/DD'),
            slot_start_time: dayjs(eventDetails.startTime)?.format('HH:mm'), 
            // slot_start_time: eventDetails.startTime,    
            slot_end_date: moment(eventDetails.endDate)?.format('YYYY/MM/DD'),    
            slot_end_date_time: dayjs(eventDetails.endTime)?.format('HH:mm'),
            // slot_end_date_time: eventDetails.endTime,    
            recurrences: eventDetails?.recurrences,    
            repeat_no: "",    
            repeat_days: "",
            is_marked_availability: eventDetails?.is_marked_availability || 0,
            max_athlete: eventDetails?.max_athlete,
            max_coaches: eventDetails?.max_coaches,    
            facility_fully_fee: eventDetails?.slot_type=='fully'? eventDetails?.facility_fully_fee : null,
            coach_fee: eventDetails?.coach_fee,
            athlete_fee: eventDetails?.athlete_fee,
            max_occupancy: eventDetails?.max_occupancy,
            repeat_days: eventDetails?.repeat_days,
            is_want_cancellation_charge: eventDetails?.is_want_cancellation_charge,
            cancellation_charge: eventDetails?.cancellation_charge
        }
        if(eventDetails?.id){
            obj.id = eventDetails?.id
        }
        try {
            // return
            setLoading(true);
            let resp = await FetchApi(Endpoints.availability, obj);
            if (resp && resp.type === "success") {
                ToastMessage.Success(resp.message);
                setModalIsOpen(false);
                getUserAvailability();
            }
            else {
                ToastMessage.Error(JSON.stringify(resp.message));
            }
            setLoading(false);
            } catch (e) {
            setLoading(false);
            console.log("availability__err", JSON.stringify(e.response, null, 4));
        }
    }

    const getUserDetail = async () => {
		try {
			let resp = await FetchApi(Endpoints.userDetail, {});
			if (resp && resp.type === 'success') {
                setUserDetail(resp?.data);
			}
		} catch (e) {
		}
	};

    useEffect(()=>{
        getUserDetail();
    },[]);

    const handleClose = () => {
        setModalIsOpen(false);
    };

    const handleChange = (e, isMark, newTime, isEndTime) => {
        if(isMark){
            // const { name, value } = e.target;
            const { name, checked } = e.target;
            setEventDetails((prevDetails) => ({
            ...prevDetails,
            [name]: checked ? 1 : 0,
            }));
            // setEventDetails({ ...eventDetails, ["is_marked_availability"]: e.target?.value=="on"? 1 : 0 });
        }
        else if (newTime) {
            if(isEndTime){
                setEventDetails((prevDetails) => ({
                    ...prevDetails,
                    endTime: newTime.format('HH:mm'),
                }));
            }
            else{
                setEventDetails((prevDetails) => ({
                    ...prevDetails,
                    startTime: newTime.format('HH:mm'),
                }));
            }
            // setValue(newTime);
        }
        else{
            const { name, value } = e.target;
            setEventDetails({ ...eventDetails, [name]: value });
        }
    };

    useEffect(()=>{
        if(eventDetails?.recurrences=='no_repeat'){
            setEventDetails(prevDetails => ({
                ...prevDetails,
                endDate: eventDetails?.startDate,
            }));
        }
        else if(!eventDetails?.id){
            setEventDetails(prevDetails => ({
                ...prevDetails,
                endDate: '',
            }));
        }
    },[eventDetails?.recurrences, eventDetails?.startDate])

    // console.log('ismark__', eventDetails?.is_marked_availability)
    
    return(
        <Modal
        show={modalIsOpen}
        onHide={handleClose}>
            <Modal.Header>
            <Modal.Title className="fs-18">Publication of Time Slots</Modal.Title>
            <div
            className="c-p"
            onClick={()=>{
                handleClose();
            }}
            >
                X
            </div>
            </Modal.Header>
            <Modal.Body 
            // className="site-form"
            >
                <div 
                className="site-form"
                >
                <label className="mb-2">Event Name</label>
                <input 
                name="title"
                className="p-2 mb-3"
                style={{
                    // borderColor: '#EAEAEA',
                    borderWidth: '1px',
                    borderRadius: '5px'
                }} 
                type="text"
                placeholder="Enter event name"
                value={eventDetails?.title}
                // onChange={(e)=>{
                //     setEventName(e.target.value)
                // }}
                onChange={handleChange}
                />

                {
                    userDetail?.user_role?.id == 4 &&
                    <>
                    <label className="mb-2">Amenity Offered</label>
                    <select
                    name="facility_selected_amenity_id"
                    defaultValue={eventDetails?.facility_selected_amenity_id}
                    // onChange={(e)=>{
                    //     setAmenityId(e.target.value)
                    // }}
                    onChange={handleChange}
                    className="p-2 mb-3"
                    style={{
                        // borderColor: '#EAEAEA',
                        borderWidth: '1px',
                        borderRadius: '5px'
                    }} 
                    >
                        <option>Select Amenity</option>
                        {
                            userDetail?.facility_selected_amenity?.map((item, index)=>{
                                return(
                                    <option key={index} value={item?.id}>{item?.amenity}</option>
                                )
                            })
                        }
                    </select>
                    </>
                }

                <label className="mb-2">Slot Type</label>
                <div className="d-flex align-items-center mb-2">
                    {userDetail?.user_role?.id == 4 &&
                    <div className="d-flex align-items-center me-4">
                    <input
                    name="slot_type"
                    type='radio'
                    disabled={eventDetails?.is_booked? true: false}
                    value={'fully'}
                    checked={eventDetails?.slot_type=='fully'}
                    onChange={handleChange}
                    />
                    <span className="ms-1">Fully</span>
                    </div>}

                    <div className="d-flex align-items-center">
                    <input
                    name="slot_type"
                    type='radio'
                    value={'fragmented'}
                    disabled={eventDetails?.is_booked? true: false}
                    checked={eventDetails?.slot_type=='fragmented' || userDetail?.user_role?.id != 4}
                    onChange={handleChange}
                    />
                    <span className="ms-1">Fragmented</span>
                    </div>
                </div>

                {
                    eventDetails?.slot_type=='fragmented' && userDetail?.user_role?.id == 4 &&
                    <div 
                    // onClick={()=>{
                    //     setMarkedAvail(!isMarkedAvail);
                    // }}
                    className="d-flex align-items-center mb-3 mt-3">
                        <input 
                        name="is_marked_availability"
                        disabled={eventDetails?.is_booked? true: false}
                        onChange={(e) => handleChange(e, true)}
                        // onChange={handleChange}
                        // checked={isMarkedAvail}
                        checked={eventDetails?.is_marked_availability === 1}
                        type='checkbox'/>
                        <span className="ms-2">Marked Availability</span>
                    </div>
                }

                {
                    // eventDetails?.is_marked_availability?
                    eventDetails?.slot_type=="fragmented" || userDetail?.user_role?.id != 4 ?
                    // userDetail?.user_role?.id != 4?
                    <div className="d-flex align-items-center mb-2 mt-3">
                        <div className={`me-2 ${userDetail?.user_role?.id != 4 ?'w-100': ''}`}>
                            <label>Maximum Athletes</label>
                            <input 
                            name="max_athlete"
                            className="p-2"
                            style={{
                                // borderColor: '#EAEAEA',
                                borderWidth: '1px',
                                borderRadius: '5px'
                            }} 
                            type="number"
                            placeholder="Enter number"
                            value={eventDetails?.max_athlete}
                            // onChange={(e)=>{
                            //     setMaxAthletes(e.target.value)
                            // }}
                            onChange={handleChange}
                            />
                        </div>

                        {userDetail?.user_role?.id == 4 &&
                        <div className="me-2">
                            <label>Maximum coaches</label>
                            <input 
                            name="max_coaches"
                            className="p-2"
                            style={{
                                // borderColor: '#EAEAEA',
                                borderWidth: '1px',
                                borderRadius: '5px'
                            }} 
                            type="number"
                            placeholder="Enter number"
                            value={eventDetails?.max_coaches}
                            // onChange={(e)=>{
                            //     setMaxCoaches(e.target.value)
                            // }}
                            onChange={handleChange}
                            />
                        </div>}
                    </div>
                    : 

                    (eventDetails?.slot_type=="fully"?
                    <div className="mt-3">
                        <label className="mb-2">Maximum Occupancy</label>
                        <input 
                        name="max_occupancy"
                        className="p-2 mb-3"
                        style={{
                            borderWidth: '1px',
                            borderRadius: '5px'
                        }} 
                        type="text"
                        placeholder="Enter number"
                        value={eventDetails?.max_occupancy}
                        onChange={handleChange}
                        />
                    </div>:null)
                }

                <label className="mb-2">Recurrences</label>
                <select
                name="recurrences"
                defaultValue={eventDetails?.recurrences}
                // onChange={(e)=>{
                //     setRecurrences(e.target.value)
                // }}
                onChange={handleChange}
                className="p-2 mb-3"
                style={{
                    // borderColor: '#EAEAEA',
                    borderWidth: '1px',
                    borderRadius: '5px'
                }} 
                >
                    <option value={''}>Select</option>
                    <option value={'weekly'}>Weekly</option>
                    <option value={'daily'}>Daily</option>
                    <option value={'no_repeat'}>Does Not Repeat</option>
                </select>

                {eventDetails?.recurrences=='weekly' &&
                <WeekdayButtons
                selectedDays={eventDetails.repeat_days}
                handleChange={handleChange}
                />
                }

                {
                    eventDetails?.is_booked
                    &&
                    <span 
                    style={{fontSize: '12px', fontWeight: 'lighter'}} 
                    className="my-2">
                        * You can't update date and time of this availability because some of the slots are booked.
                    </span>
                }


                <div className="d-flex align-items-center mb-2 mt-2">
                    <div>
                        <label className="mb-2">Start Date</label>
                        <input 
                        disabled={eventDetails?.is_booked? true : false}
                        name="startDate"
                        className="p-2 mb-3"
                        style={{
                            // borderColor: '#EAEAEA',
                            borderWidth: '1px',
                            borderRadius: '5px'
                        }} 
                        value={eventDetails.startDate}
                        type="date"
                        // onChange={(e)=>{
                        //     setSlotStartDate(e.target.value)
                        // }}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="ms-2">
                        <label className="mb-2">End Date</label>
                        <input 
                        name="endDate"
                        disabled={eventDetails?.is_booked || 
                            eventDetails?.recurrences=='no_repeat'? true : false}
                        className="p-2 mb-3"
                        style={{
                            // borderColor: '#EAEAEA',
                            borderWidth: '1px',
                            borderRadius: '5px'
                        }} 
                        type="date"
                        value={eventDetails.endDate}
                        // onChange={(e)=>{
                        //     setSlotEndDate(e.target.value)
                        // }}
                        onChange={handleChange}
                        />
                    </div>
                </div>
                </div>

                {(eventDetails?.startTime || eventDetails?.endTime) &&
                <div className="d-flex align-items-center mb-2 mt-2">
                    <div>
                        <label className="mb-0">Start Time</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                        <TimePicker 
                        ampm={false}
                        name="startTime"
                        disabled={eventDetails?.is_booked? true : false}
                        // minutesStep={1}
                        // skipDisabled
                        minTime={moment(eventDetails?.startDate).isSame(moment(), 'day')? dayjs().add(60, 'minutes'): null}
                        // value={value}
                        // value={dayjs(eventDetails.startTime)}
                        value={eventDetails?.startTime}
                        // onChange={(newValue) => setValue(newValue)}
                        onChange={(newValue) => {
                            setEventDetails(prevDetails => ({
                                ...prevDetails,
                                startTime: newValue,
                            }));
                        }}
                        // onChange={(newValue) => handleChange(null, false, newValue)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'gray',
                              },
                              '&:hover fieldset': {
                                borderColor: 'gray',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'gray', 
                                borderWidth: 1,
                              },
                              '& .MuiInputBase-input': {
                                padding: '10px 4px', 
                                fontSize: '0.875rem',
                              },
                            },
                          }}
                        />
                        </DemoContainer>
                        </LocalizationProvider>
                        {/* <input 
                        name="startTime"
                        disabled={eventDetails?.is_booked? true : false}
                        className="p-2 mb-3"
                        style={{
                            // borderColor: '#EAEAEA',
                            borderWidth: '1px',
                            borderRadius: '5px'
                        }} 
                        type="time"
                        value={eventDetails.startTime}
                        onChange={handleChange}
                        /> */}
                    </div>
                    <div className="ms-2">
                        <label className="mb-0">End Time</label>
                        {/* <input type='time'/> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                        <TimePicker 
                        // ampmInClock={false}
                        ampm={false}
                        name="endTime"
                        disabled={eventDetails?.is_booked? true : false}
                        // className="p-2 mb-3"
                        // value={dayjs(eventDetails.endTime)}
                        value={eventDetails?.endTime}
                        onChange={(newValue) => {
                            setEventDetails(prevDetails => ({
                                ...prevDetails,
                                endTime: newValue,
                            }));
                        }}
                        // onChange={(newValue) => handleChange(null, false, newValue, true)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'gray',
                              },
                              '&:hover fieldset': {
                                borderColor: 'gray',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'gray', 
                                borderWidth: 1,
                              },
                              '& .MuiInputBase-input': {
                                padding: '10px 4px', 
                                fontSize: '0.875rem',
                              },
                            },
                          }}
                        />
                        </DemoContainer>
                        </LocalizationProvider>
                        {/* <input 
                        name="endTime"
                        disabled={eventDetails?.is_booked? true : false}
                        className="p-2 mb-3"
                        style={{
                            borderWidth: '1px',
                            borderRadius: '5px'
                        }} 
                        value={eventDetails.endTime}
                        type='time'
                        onChange={handleChange}
                        /> */}
                    </div>
                </div>}

                <div className="site-form">
                {
                    eventDetails?.slot_type!="fragmented" && userDetail?.user_role?.id == 4 ?
                    <>
                    <label className="mb-2 mt-2">Facility Fee</label>
                    <input 
                    name="facility_fully_fee"
                    className="p-2 mb-3"
                    style={{
                        // borderColor: '#EAEAEA',
                        borderWidth: '1px',
                        borderRadius: '5px'
                    }} 
                    type="number"
                    placeholder="Enter amount"
                    value={eventDetails?.facility_fully_fee || ''}
                    // onChange={(e)=>{
                    //     setFacilityFee(e.target.value)
                    // }}
                    onChange={handleChange}
                    />
                    </>
                    :
                    <div className="d-flex align-items-center mb-2 mb-3 mt-2">
                        {userDetail?.user_role?.id == 4 &&
                        <div className="me-2">
                            <label>Fee for Coaches</label>
                            <input 
                            name="coach_fee"
                            className="p-2"
                            style={{
                                // borderColor: '#EAEAEA',
                                borderWidth: '1px',
                                borderRadius: '5px'
                            }} 
                            type="number"
                            value={eventDetails?.coach_fee}
                            placeholder="Enter amount"
                            // onChange={(e)=>{
                            //     setCoachFee(e.target.value)
                            // }}
                            onChange={handleChange}
                            />
                        </div>}

                        <div className={`me-2 mt-2 ${userDetail?.user_role?.id != 4 ?'w-100': ''}`}>
                            <label>Fee for Athletes</label>
                            <input 
                            name="athlete_fee"
                            className="p-2"
                            style={{
                                // borderColor: '#EAEAEA',
                                borderWidth: '1px',
                                borderRadius: '5px'
                            }} 
                            type="number"
                            value={eventDetails?.athlete_fee}
                            placeholder="Enter amount"
                            // onChange={(e)=>{
                            //     setAthleteFee(e.target.value)
                            // }}
                            onChange={handleChange}
                            />
                        </div>
                    </div>
                }    
                <label className="mb-2">Want to apply cancellation charges?</label>
                <select
                name="is_want_cancellation_charge"
                defaultValue={eventDetails?.is_want_cancellation_charge}
                onChange={handleChange}
                className="p-2 mb-3"
                style={{
                    borderWidth: '1px',
                    borderRadius: '5px'
                }} 
                >
                    <option value={''}>Select</option>
                    <option value={1}>{"Yes"}</option>
                    <option value={0}>{"No"}</option>
                </select>

                {eventDetails?.is_want_cancellation_charge==1?
                    <>
                    <label className="mt-2">Cancellation Charge</label>
                    <input 
                    name="cancellation_charge"
                    className="p-2 mb-3"
                    style={{
                        borderWidth: '1px',
                        borderRadius: '5px'
                    }} 
                    type="number"
                    placeholder="Enter amount"
                    value={eventDetails?.cancellation_charge || ''}
                    onChange={handleChange}
                    />
                    </>
                    : null
                    }
                </div>

                
            </Modal.Body>
            <Modal.Footer>
                <Button
                style={{
                  backgroundColor: 'gray'
                }}
                onClick={()=>{
                  setModalIsOpen(false);
                }}
                >
                  Cancel
                </Button>
                <Button
                disabled={isLoading}
                style={{
                  backgroundColor: 'green'
                }}
                // className="bg-green"
                onClick={()=>{
                    saveAvailability();
                }}
                >
                  {isLoading?  <Loader isButton={true}/> : "Save"}
                  </Button>
            </Modal.Footer>

        </Modal>
    )
}