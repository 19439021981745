/* import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ForgotPassword from "./Pages/auth/ForgotPassword";
import Login from "./Pages/auth/Login";
import SetNewPassword from "./Pages/auth/SetNewPassword";
import Signup from "./Pages/auth/Signup";
import VerifyOtp from "./Pages/auth/VerifyOtp";
import UserCompleteProfile from "./Pages/Profile/UserCompleteProfile";
import UserLandingPage from "./Pages/Profile/UserLandingPage";
import AllNotifications from "./Pages/Profile/AllNotifications";
import UserProfile from "./Pages/Profile/UserProfile";
import UserEditProfile from "./Pages/Profile/UserEditProfile";
import UserProfileOthers from "./Pages/Profile/UserProfileOthers";
import BookingKidSummary from "./Pages/Booking/BookingKidSummary";
import CoachDetails from "./Pages/CoachPages/CoachDetails";
import CoachProfile from "./Pages/CoachPages/CoachProfile";
import CoachProfileComplete from "./Pages/CoachPages/CoachProfileComplete";
import EditCoachProfile from "./Pages/CoachPages/EditCoachProfile";
import FacilityLanding from "./Pages/Facility/FacilityLanding";
import FacilityProfile from "./Pages/Facility/FacilityProfile";
import FacilityProfileComplete from "./Pages/Facility/FacilityProfileComplete";
import FacilityProfileOthersView from "./Pages/Facility/FacilityProfileOthersView";
import JobCreation from "./Pages/JobFeaturesPages/JobCreation";
import JobListing from "./Pages/JobFeaturesPages/JobListing";
import ParentUserLanding from "./Pages/ParentUser/ParentUserLanding";
import JobDetails from "./Pages/JobFeaturesPages/JobDetails";
import MyBookingUpcoming from "./Pages/Booking/MyBookingUpcoming";
import MySheduleUser from "./Pages/MyShedule/MySheduleUser";
import MyShedulePlayer from "./Pages/MyShedule/MyShedulePlayer";
import Coaches from "./Pages/CoachPages/Coaches";
import Facilities from "./Pages/Facility/Facilities";
import JobDetailsKids from "./Pages/JobFeaturesPages/JobDetailsKids";
import ManageAvailabilityCoach from "./Pages/ManagesPages/ManageAvailabilityCoach";
import ManageAvailibilityFacility from "./Pages/ManagesPages/ManageAvailibilityFacility";
import ManageGallery from "./Pages/ManagesPages/ManageGallery";
import ManageJobs from "./Pages/ManagesPages/ManageJobs";
import LandingPage from "./Pages/Profile/LandingPage";
import BookingSummary from "./Pages/Booking/BookingSummary";
import StripePaymentForm from "./Pages/Booking/StripePaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BookingSummaryFacility from "./Pages/Booking/BookingSummaryFacility";

function App() {
  const stripePromise = loadStripe("YOUR_STRIPE_PUBLISHABLE_KEY");

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/Login" element={<Login />} />
          <Route path="Signup" element={<Signup />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route path="VerifyOtp" element={<VerifyOtp />} />
          <Route path="SetNewPassword" element={<SetNewPassword />} />
          <Route path="UserCompleteProfile" element={<UserCompleteProfile />} />
          <Route path="UserLandingPage" element={<UserLandingPage />} />
          <Route path="AllNotifications" element={<AllNotifications />} />
          <Route path="UserProfile" element={<UserProfile />} />
          <Route path="UserEditProfile" element={<UserEditProfile />} />
          <Route path="UserProfileOthers" element={<UserProfileOthers />} />
          <Route path="BookingKidSummery" element={<BookingKidSummary />} />
          <Route path="BookingSummary" element={<BookingSummary />} />
          <Route
            path="BookingSummaryFacility"
            element={<BookingSummaryFacility />}
          />
          <Route path="MyBookingUpcoming" element={<MyBookingUpcoming />} />
          <Route path="CoachDetails" element={<CoachDetails />} />
          <Route path="CoachProfile" element={<CoachProfile />} />
          <Route path="Coaches" element={<Coaches />} />
          <Route
            path="CoachProfileComplete"
            element={<CoachProfileComplete />}
          />
          <Route path="EditCoachProfile" element={<EditCoachProfile />} />
          <Route path="FacilityLanding" element={<FacilityLanding />} />
          <Route path="FacilityProfile" element={<FacilityProfile />} />
          <Route
            path="FacilityProfileComplete"
            element={<FacilityProfileComplete />}
          />
          <Route path="Facilities" element={<Facilities />} />
          <Route
            path="FacilityProfileOthersView"
            element={<FacilityProfileOthersView />}
          />
          <Route path="JobCreation" element={<JobCreation />} />
          <Route path="JobListing" element={<JobListing />} />
          <Route path="JobDetailsKid" element={<JobDetailsKids />} />
          <Route path="ParentUserLanding" element={<ParentUserLanding />} />
          <Route path="JobDetails" element={<JobDetails />} />
          <Route path="MyBookingUpcoming" element={<MyBookingUpcoming />} />
          <Route path="MySheduleUser" element={<MySheduleUser />} />
          <Route path="MyShedulePlayer" element={<MyShedulePlayer />} />
          <Route
            path="ManageAvailabilityCoach"
            element={<ManageAvailabilityCoach />}
          />
          <Route
            path="ManageAvailabilityFacility"
            element={<ManageAvailibilityFacility />}
          />
          <Route path="ManageGallery" element={<ManageGallery />} />
          <Route path="ManageJobs" element={<ManageJobs />} />

          <Route path="StripePaymentForm" element={<StripePaymentForm />} />
        </Routes>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
 */
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppRoutes from './lazy-routes';
import { setLoadingData, loginUserSuccess } from './redux/actions';
import { axiosRequestInterceptor, axiosResponseInterceptor } from './helpers/axiosInterceptors'
/* import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js"; */
/**
 * Main app component
 */
class App extends Component {
  //stripePromise = loadStripe("pk_test_51Lj5wfJeJnNOHvBiVdooKqk8eYPZ5xpJVxv4rg12puvJM3Yn6CYlFZVGRK1MOiD7eJIWBwOsx6wLFb2UxIoTGv5F00f0xh2bVW");
  /**
* Returns the layout component based on different properties
* @param {*} props 
*/
  constructor(props) {
    super(props);
    this.props.loginUserSuccess(props.userData);
  }
  render() {
    return (
      // rendering the router with layout
      <BrowserRouter>
        {/* <Elements stripe={this.stripePromise}>
          <AppRoutes />

        </Elements> */}
        <AppRoutes />
      </BrowserRouter>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    isLoading: state.Auth.isLoading,
  }
}

export default connect(mapStateToProps, { setLoadingData, loginUserSuccess })(App);