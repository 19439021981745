import { getUserToken } from './authUtils';
//import { setLoadingData } from '../redux/actions';
//import { connect } from 'react-redux';
import axios from 'axios';

export const axiosRequestInterceptor = axios.interceptors.request.use(function (config) {
  const token = getUserToken();
  if(!config.url.includes('likePost')){
    document.body.classList.add('custom-loader');
  }  
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  if (config.url.includes('categories') && (config.method == 'post' || config.method == 'POST')) {
  }
  else {
    config.headers['Content-Type'] = 'Application/json';

  }
  return config;
}, function (error) {
  // Do something with request error
  document.body.classList.remove('custom-loader');
  return Promise.reject(error);
});

// Add a response interceptor
export const axiosResponseInterceptor = axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  //put(setLoadingData(false));
  document.body.classList.remove('custom-loader');
  return response;
}, function (error) {
  //put(setLoadingData(false));
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  document.body.classList.remove('custom-loader');
  return Promise.reject(error);
});

