import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Posts } from "../../Layout/Posts";
import { connect } from "react-redux";
import { useState } from "react";
import SideBar from "../../Layout/SideBar";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import Promotions from "./Promotions";
import { Button, Modal } from "react-bootstrap";
import ToastMessage from "../../Utils/ToastMessage";
import MessageRequestModal from "../../Components/messageRequestModal";
function UserProfileOthers(props) {
  const editProperty = { ...{ edit: true }, ...props }
  const [editProps, editPros] = useState(editProperty)
  const data = useLocation();
  const [coachInfo, setCoachInfo] = useState("");
  const [user, setUserInfo] = useState(props.user)
  const params = useParams();

  const [messageDescription, setMsgDescription] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClose = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    getUserInfo();
  }, [params?.slug]);
  const getUserInfo = async () => {
    try {
      let resp = await FetchApi(Endpoints.userDetail, { slug: params?.slug });
      if (resp && resp.type === "success") {
        setCoachInfo(resp?.data);
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  };
  const handleSendMessage = async (id) => {
    let data = {
      chat_receiver_id: id,
      message_description : messageDescription
    }
    try {
      let resp = await CommonAPi(Endpoints.CreateMessageRoomId, data);
      if (resp && resp.status === "success") {
        setModalIsOpen(false)
        getUserInfo();
        Swal.fire({
          text: resp.message,
          icon: "success",
        });
      }
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };

  const changeStatus = async (Id, status) => {
    let data = {
      message_room_id: Id,
      status: status
    }
    try {
      let resp = await CommonAPi(Endpoints.AcceptDeclineChatRequest, data);
      if (resp && resp.status === "success") {
        getUserInfo();
      }
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  return (
    <div className="main py-4 ">
      <div className="container">
        <div className="row feed-page">
          <SideBar />
          {/* <!-- END LEFT COLUMN --> */}

          <div className="col-lg-6 middle-area">
            <div className="border rounded-8 p-3">
              <div className="d-flex gap-3 mb-3 profile-info">
                <img
                  src={
                    coachInfo?.image
                      ? coachInfo?.image
                      : "assets/img/coach-detail.png"
                  }
                  width="80"
                  height="80"
                  className="rounded-pill dp-border"
                  alt=""
                />
                <div>
                  <h5 className="semibold">{coachInfo?.name}</h5>
                  <h6 className="regular text-lightgray pb-3">{coachInfo?.user_sports?.name}</h6>
                  {props?.user?.slug != params.slug ?
                    <>
                      {coachInfo.isMessageSend == null ?
                        <Link className="butn gradient-butn" onClick={() => {
                          // handleSendMessage(coachInfo?.id)
                          setModalIsOpen(true);
                        }}>
                          Send Message Request
                        </Link>
                        : ""}
                      {coachInfo.isMessageSend == null ?
                        ""
                        :
                        <>
                          {coachInfo.id != coachInfo.isMessageSend?.user_id && coachInfo.isMessageSend?.status == "pending" ?
                            <Link className="butn gradient-butn">
                              Request sent
                            </Link>
                            :
                            ""}
                          {coachInfo.id == coachInfo.isMessageSend?.user_id ?
                            <>
                              {coachInfo.isMessageSend?.status == "pending" ?
                                <>
                                  <Link className="butn gradient-butn" onClick={() => {
                                    changeStatus(coachInfo.isMessageSend?.id, "active")
                                  }}>
                                    Accept message request
                                  </Link>
                                  {" "}
                                  <Link className="butn gradient-butn" onClick={() => {
                                    changeStatus(coachInfo.isMessageSend?.id, "decline")
                                  }}>
                                    Reject message request
                                  </Link>
                                </>
                                : ""}

                            </>
                            : ""}

                          {coachInfo.isMessageSend?.status == "active" ?
                            <Link className="butn gradient-butn" to={"/message"}>
                              Send message
                            </Link>
                            : ""}
                        </>


                      }
                    </>
                    :
                    <Link to={'/update-user-profile'} state={editProps} className="butn py-2 fs-15">
                      Edit Profile
                    </Link>
                  }
                </div>
              </div>

              <div className="row pe-xl-5 m-0 profile-data">
                <div className="col-3 pb-3 ps-0">
                  <h3 className="semibold text-green d-flex align-items-center gap-1 ">
                    <img src="assets/img/icon/clipboardtick.svg" alt="" />
                    {coachInfo?.user_bookings_count}
                  </h3>
                  <h6 className="regular text-green">Bookings</h6>
                </div>

                <div className="col-3 text-center pb-3 ps-0">
                  <h3 className="semibold text-green d-flex align-items-center gap-1 justify-content-center">
                    <img src="assets/img/icon/documenttext.svg" alt="" />
                    {coachInfo?.user_posts_count}
                  </h3>
                  <h6 className="regular text-green">Posts</h6>
                </div>

                <div className="col-3 text-center pb-3">
                  <h3 className="semibold text-green d-flex align-items-center gap-1 justify-content-center">
                    <img src="assets/img/icon/briefcase.svg" alt="" />
                    {coachInfo?.userJobsApplied}
                  </h3>
                  <h6 className="regular text-green">Jobs Applied</h6>
                </div>
              </div>

              <h3 className="medium">About</h3>
              <p className="text-justify text-696969">
                {coachInfo?.showFull ? coachInfo?.about : coachInfo?.about?.slice(0, 140)} {coachInfo?.about?.length > 140 &&
                  <a onClick={(e) => {
                    e.preventDefault();
                    let newCoachInfo = { ...coachInfo }
                    newCoachInfo['showFull'] = !newCoachInfo?.showFull;
                    setCoachInfo(newCoachInfo)
                  }} className="text-green c-p"
                    style={{
                      textDecoration: "underline"
                    }}
                  >{coachInfo?.showFull ? 'Read Less' : 'Read More'}</a>}
              </p>
            </div>
            {coachInfo ?
              <Posts user={coachInfo} pageFrom='view' viewUser={coachInfo} />
              : ""}
          </div>
          {/* <!-- END MIDDLE COLUMN --> */}

          <Promotions />
          {/* <!-- END RIGHT COLUMN --> */}
        </div>
      </div>

        <MessageRequestModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        coachInfo={coachInfo}
        handleSendMessage={handleSendMessage}
        messageDescription={messageDescription}
        setMsgDescription={setMsgDescription}
        />
    </div>
  );
}
const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, {})(UserProfileOthers);
