//import jwtDecode from 'jwt-decode';
import { Cookies } from "react-cookie";

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const token = getUserToken();
    if (!token) {
        return false;
    }
    //const decoded = jwtDecode(token);
    //const currentTime = Date.now() / 1000;
    return true;
}
/**
 * Returns the logged in user
 */
const getUserToken = () => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    return token ? token : null;
}
/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get("user");
    return user ? (typeof (user) == 'object' ? user : JSON.parse(user)) : null;
}
/**
 * create the cookie value based what params passed
 */
const setCookie = (key, value) => {
    const cookies = new Cookies();
    cookies.set(key, value, { path: "/" });
}
/**
 * Returns the customer selected country
 */
const getSelectedCountry = () => {
    const cookies = new Cookies();
    const countryId = cookies.get("countryId");
    return countryId;
}

const getCountChangeStatus = () => {
    const cookies = new Cookies();
    const countChangeStatus = cookies.get("countchangestatus");
    return countChangeStatus;
}
const getAddressDetails = (ac) => {
    let mapObject = {}
    for (let i = 0; i < ac.length; i++) {
        switch (ac[i].types[0]) {
            case 'country':
                mapObject['country'] = ac[i].long_name;
                break;
            case 'administrative_area_level_1':
                mapObject['state'] = ac[i].long_name;
                break;
            case 'street_number':
                mapObject['street_number'] = ac[i].long_name;
            case 'administrative_area_level_2':
                mapObject['city'] = ac[i].long_name;
                break;
            case 'administrative_area_level_3':
                mapObject['city'] = ac[i].long_name;
                break;
            case 'route':
                mapObject['street'] = ac[i].long_name;
                break;
            case 'postal_code':
                mapObject['zipcode'] = ac[i].long_name;
                break;
        }
    }
    return mapObject;
}
const mapConfigs = () => {
    let mapObject = {}
    const libraries = ["places"];
    mapObject['containerStyle'] = {
        width: '100%',
        height: '250px'
    }
    mapObject['currentPoint'] = {
        lat: 42.331429,
        lng: -83.045753,
    }
    mapObject['configKey'] = 'AIzaSyC2FCeXYnCdxsV02btm8ImfUtyOn0_WqIE'
    mapObject['config'] = {
        id: 'google-map-script',
        libraries: libraries,
        googleMapsApiKey: mapObject['configKey']
    }
    return mapObject;
}

export { isUserAuthenticated, getLoggedInUser, getUserToken, getSelectedCountry, setCookie, getAddressDetails, mapConfigs, getCountChangeStatus };