import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const CustomToolbar = ({ label, onNavigate, onView, view }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentView, setCurrentView] = useState(view);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
    onView(view);
  };

  useEffect(() => {
    setCurrentView(view);
  }, [view]);

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" className="custom-toolbar-button" onClick={() => onNavigate('PREV')}>
          Back
        </button>
        <button type="button" className="custom-toolbar-button" onClick={() => onNavigate('TODAY')}>
          Today
        </button>
        <button type="button" className="custom-toolbar-button" onClick={() => onNavigate('NEXT')}>
          Next
        </button>
      </span>
      <span className="rbc-toolbar-label">{label}</span>
      <span className="rbc-btn-group">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <Dropdown.Toggle variant="success" id="dropdown-basic" className='custom-dropdown-toggle'>
            {currentView.charAt(0).toUpperCase() + currentView.slice(1)}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleViewChange('day')}>Day</Dropdown.Item>
            <Dropdown.Item onClick={() => handleViewChange('week')}>Week</Dropdown.Item>
            <Dropdown.Item onClick={() => handleViewChange('month')}>Month</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </span>
    </div>
  );
};

export default CustomToolbar;
