import React, { useEffect, useState } from "react";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Swal from "sweetalert2";
// import CKEditor from "ckeditor4-react";
import { CKEditor } from "ckeditor4-react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { FetchApi } from "../../API/FetchApi";
import { formatTime } from "../../Utils/constants";
import ReactQuill from "react-quill";

const ViewJob = (props) => {
  const navigate = useNavigate();
  const [jobList, setJobList] = useState();
  const [availabilityList, setAvailabilityList] = useState([]);
  const { id } = useParams();


  const getJobListing = async () => {
    let obj = {
      job_id: id,
    };
    try {
      let resp = await CommonAPi(Endpoints.getJobDetail, obj);

      if (resp && resp.status === "success") {
        setJobList(resp?.data);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));
    }
  };

  /// editor configuration  ///
  const editorConfig = {
    toolbar: [
      {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Strike"],
      },
      {
        name: "lists",
        items: ["NumberedList", "BulletedList"],
      },
      {
        name: "blockquote",
        items: ["Blockquote"],
      },
      {
        name: "insert",
        items: ["Link", "Unlink"],
      },
      {
        name: "maximize",
        items: ["Maximize"],
      },
    ],
    extraPlugins: "editorplaceholder",
    editorplaceholder: "Write job roles and responsiblites",
    readOnly: true,
  };

  const editorConfig2 = {
    toolbar: [
      {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Strike"],
      },
      {
        name: "lists",
        items: ["NumberedList", "BulletedList"],
      },
      {
        name: "blockquote",
        items: ["Blockquote"],
      },
      {
        name: "insert",
        items: ["Link", "Unlink"],
      },
      {
        name: "maximize",
        items: ["Maximize"],
      },
    ],
    extraPlugins: "editorplaceholder",
    editorplaceholder: "Write job description",
    removePlugins: 'elementspath'
  };

  /// editor configuration  ///

  const getAvailability = async (slug) => {
    try {
      let resp = await FetchApi(Endpoints.getUserAvailability, {
        user_slug: props.user.slug,
      });
      if (resp && resp.type === "success") {
        let avaailabilityList = resp.data;
        avaailabilityList.forEach((item) => {
          item.availability_timing.forEach((time) => {
            time["start"] = time.time_from ? formatTime(time.time_from) : "";
            time["end"] = time.time_to ? formatTime(time.time_to) : "";
            time["show"] = time.time_from ? true : false;
          });
        });
        setAvailabilityList(avaailabilityList);
      }
    } catch (e) {
      console.log("AvailabilityList", JSON.stringify(e, null, 4));
    }
  };

  useEffect(() => {
    getJobListing();
    getAvailability();
  }, []);

  return (
    <div className="wrapper">
      <div className="main py-5">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-6 col-md-8">
              <h1 className="text-darkgray semibold pb-2 text-center fs-24">
                View Job Listing
              </h1>

              <form className="site-form pt-2">
                <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Job Title
                  </label>
                  <input
                    value={jobList?.job_title}
                    disabled={true}
                    name="job_title"
                    type="text"
                    placeholder="Enter Job Title"
                  />
                </div>

                <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Job Description
                  </label>

                  <ReactQuill
                  readOnly={true}
                  theme="snow" 
                  style={{
                    height: '180px',
                    marginBottom: '70px'
                  }}
                  value={jobList?.job_description || ""} 
                  />

                  <CKEditor
                    config={editorConfig2}
                    readOnly
                    data={jobList?.job_description || ""}
                  />
                </div>

                {/* <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Roles and Responsibilities
                  </label>
                  <div>
                    <CKEditor
                      config={editorConfig}
                      readOnly
                      data={jobList?.role_responsibility || ""}
                    />
                  </div>
                </div> */}

                {/* <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Location
                  </label>
                  <select
                    disabled={true}
                    value={jobList?.availability_id}
                    name="availability_id"
                    id=""
                  >
                    <option value="Select Sports">Location Available</option>
                    {availabilityList.map((item, index) => {
                      return (
                        <option key={index} value={item?.id}>
                          {item?.location_name}
                        </option>
                      );
                    })}
                  </select>
                </div> */}

                <div className="form-field mb-3">
                  <label htmlFor="" className="pb-1">
                    Job Type
                  </label>
                  <select
                    disabled={true}
                    value={jobList?.job_type || ""}
                    name="job_type"
                    id=""
                  >
                    <option value="Select Sports">Job Type</option>
                    <option value="full_time">Full Time</option>
                    <option value="part_time">Part Time</option>
                    <option value="freelance">Freelance</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="" className="pb-1">
                    Salary (Optional)
                  </label>
                  <div className="monthly d-flex align-items-center overflow-hidden">
                    <select
                      disabled={true}
                      value={jobList?.salary_type || ""}
                      name="salary_type"
                      id=""
                      className="border-0 w-26 ps-md-3 ps-1 "
                    >
                      <option value="Select Salary">Salary Type</option>
                      <option value="hourly">Hourly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                    <input
                      value={jobList?.salary_from}
                      disabled={true}
                      name="salary_from"
                      type="number"
                      className="border-0 w-75 border-start rounded-0"
                      placeholder="00"
                    />
                    <input
                      value={jobList?.salary_to || ""}
                      disabled={true}
                      name="salary_to"
                      type="number"
                      className="border-0 w-75 border-start rounded-0"
                      placeholder="00"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="" className="pb-1">
                    Job Valid Till
                  </label>
                  <div className="monthly d-flex align-items-center overflow-hidden">
                    <input
                      value={jobList?.job_expire_date || ""}
                      disabled={true}
                      name="job_expire_date"
                      type="date"
                      className="border-0  custom-date rounded-0"
                      placeholder="00"
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <button
                    type="button"
                    onClick={() => navigate("/manage-job")}
                    className="w-100 gray-butn butn  rounded-pill border-0"
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, {})(ViewJob);

// export default ViewJob;
