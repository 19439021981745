import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
// import HeaderUser from "../../Layout/HeaderUser";
import Footer from "../../Layout/Footer";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Swal from "sweetalert2";
import { useState } from "react";
import { connect } from "react-redux";
import LocationAutocomplete from "../../Components/LocationAutocomplete";
import { FetchApi } from "../../API/FetchApi";
// import { Slider } from "@material-ui/core";
// import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Slider } from "@mui/material";
// const useStyles = makeStyles({
//   // valueLabel: {
//   //   '& span': {
//   //     padding: '10px',
//   //     // padding: '24px',
//   //   },
//   // },
//   valueLabel: {
//     '& span': {
//           // padding: '10px',
//           backgroundColor: 'transparent'
//         },
    
//     backgroundColor: '#548235', 
//     color: 'white', 
//     paddingInline: '8px', 
//     borderRadius: '0px', 
//   },
// });
// const useStyles = makeStyles((theme) => ({
//   customValueLabel: {
//     whiteSpace: 'nowrap', // Prevent text wrapping
//     overflow: 'hidden', // Hide overflow content
//     textOverflow: 'ellipsis', // Ellipsis for long content
//     width: 'fit-content', // Adjust width as needed
//     // Add other styles for positioning and margin
//   },
// }));
function JobListing(props) {
  // const classes = useStyles();
  const locationRef = useRef(null);
  const [salaryOne, setSalaryOneValue] = useState(0);
  const [salaryTwo, setSalaryTwoValue] = useState(0);
  const [salaryTwoCopy, setSalaryTwoCopy] = useState(0);
  const [salaryStaticTwo, setSalaryStaticTwoValue] = useState(0);
  const [oldSalaryOne, setOldSalaryOneValue] = useState(0);
  const [oldSalaryTwo, setOldSalaryTwoValue] = useState(0);
  const [radiusOne, setRadiusOneValue] = useState(0);
  const [radiusTwo, setRadiusTwoValue] = useState(50);
  const [radiusTwoCopy, setRadiusTwoCopy] = useState(50);
  const [oldRadiusOne, setOldRadiusOneValue] = useState(0);
  const [oldRadiusTwo, setOldRadiusTwoValue] = useState(50);
  const [jobInfo, setJobInfo] = useState([]);
  const [salaryInfo, setSalaryInfo] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [data, setData] = useState();
  const [add, setAdd] = useState("");
  const [jobPagination, setJobPagination] = useState({});
  const [value, setValue] =  React.useState([0,1000000]);
  const [locValue, setLocValue] =  React.useState([0,1000000]);
  const [isFilterCleared, setFilterCleared] = useState(true);

  const onLocationChanged = (data) => { setLat(data.lat); setLng(data.lng); getJobListing(data?.lat, data?.lng); getFilterNewData(data.lat, data.lng);};
  
  const rangeSelector = (event, newValue) => {
    setValue(newValue);
  };
  const rangeSelector2 = (event, newValue) => {
    setFilterCleared(false);
    setSalaryOneValue(newValue?.[0])
    setSalaryTwoValue(newValue?.[1])
  };

  const rangeSelectorLoc = (event, newValue) => {
    setLocValue(newValue);
  };
  const rangeSelectorLoc2 = (event, newValue) => {
    setFilterCleared(false);
    setRadiusOneValue(newValue?.[0])
    setRadiusTwoValue(newValue?.[1])
  };
  const addRemoveJobType = (itemId, e) => {
    const newJobId = [...jobInfo];
    const index = newJobId.indexOf(itemId);
    if (e && e.target && e.target.checked) {
      setFilterCleared(false);
      newJobId.push(itemId);
      setJobInfo(newJobId);
    } else {
      newJobId.splice(index, 1);
      setJobInfo(newJobId);
    }
  };


  const addRemoveSalaryType = (itemId, e) => {
    const newJobId = [...salaryInfo];
    const index = newJobId.indexOf(itemId);
    if (e && e.target && e.target.checked) {
      setFilterCleared(false);
      newJobId.push(itemId);
      setSalaryInfo(newJobId);
    } else {
      newJobId.splice(index, 1);
      setSalaryInfo(newJobId);
    }
  };

  // const onLocationChanged = (data) => { 
  //   // setLat(data.lat); 
  //   // setLng(data.lng); 
  //   CoachList(null, null, data.lat, data.lng); 
  //   getFilterNewData(data.lat, data.lng);
  // };

  const getFilterData = async (e) => {
    try {
      let postPrams = {}
      if (lat && props.user.latitude && props.user.latitude != lat) {
        postPrams['latitude'] = ''
        postPrams['longitude'] = ''
      }
      let resp = await CommonAPi(Endpoints.getFiltersData + `/${'jobs'}?latitude=${lat && props.user.latitude && props.user.latitude != lat ? '' : props.user.latitude}&longitude=${lng && props.user.longitude && props.user.longitude != lng ? '' : props.user.longitude}`);
      if (resp && resp.status === "success") {
        setFilterData(resp.data);
        setSalaryTwoValue(resp.data.price_to);
        setSalaryTwoCopy(parseInt(resp.data.price_to));
        // setPriceOneCopy(resp.data.price_from);
        setValue([0, parseInt(resp.data.price_to)])
        setOldSalaryTwoValue(resp.data.price_to);
        setSalaryStaticTwoValue(resp.data.price_to);

      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };

  const getFilterNewData = async (lat, long) => {
    try {
   
      let resp = await FetchApi(Endpoints.getFiltersData + `/jobs?latitude=${lat}&longitude=${long}`);
      if (resp && resp.status === "success") {
        setFilterData(resp.data);
        setSalaryTwoValue(resp.data.price_to);
        setOldSalaryTwoValue(resp.data.price_to);
        setSalaryStaticTwoValue(resp.data.price_to);
        setSalaryTwoValue(resp.data.price_to);
        setSalaryTwoCopy(resp.data.price_to);
        setValue([0, resp.data.price_to])
      }
      
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
      }
    }
  };


  const getFilterCount = (item, key) => {
    let return_value = 0;
    if (item === "jobTypes" && filterData.jobTypes) {
      return_value = filterData.jobTypes[key];
    }
    return return_value;
  };

  const getFilterSalaryCount = (item, key) => {
    let return_value = 0;
    if (item === "SalaryTypes" && filterData.jobSalaryTypes) {
      return_value = filterData.jobSalaryTypes[key];
    }
    return return_value;
  };

  const clearFilters = () => {
    setFilterCleared(true);
    setJobInfo([])
    setSalaryInfo([])
    setRadiusOneValue(0)
    setRadiusTwoValue(50)
    setSalaryOneValue(0)
    // setSalaryTwoValue(40000)
    setOldRadiusOneValue(0)
    setOldRadiusTwoValue(50)
    setOldSalaryOneValue(0);
    setSalaryTwoValue(salaryTwoCopy);
    setValue([0, salaryTwoCopy]);
    setRadiusTwoValue(50)
    setLocValue([0, 50]);  
    // setOldSalaryTwoValue(40000)
    setLat(null);
    setLng(null);
    getFilterData();
    locationRef.current.clearAddress();
  }

  console.log('salaryinfo__', salaryInfo)

  const getJobListing = async (lati, lngi) => {
    let obj = {
      latitude: lati ? lati : (lat ? lat : props.user.latitude),
      longitude: lngi ? lngi : (lng ? lng : props.user.longitude),
      salary_from: parseInt(salaryOne),
      salary_to: parseInt(salaryTwo),
      radius_from: radiusOne,
      radius_to: radiusTwo,
      data_for: "all",
    };

    if (jobInfo?.length) {
      obj["job_type"] = jobInfo;
    }
    if (salaryInfo?.length) {
      obj["salary_type"] = salaryInfo;
    }

    try {
      let resp = await CommonAPi(Endpoints.getJobListing, obj);
      if (resp && resp.status === "success") {
        setJobPagination(resp.data);
        setData(resp?.data);
        // setOldSalaryOneValue(salaryOne);
        // setOldSalaryTwoValue(salaryTwo);
        // setOldRadiusOneValue(radiusOne);
        // setOldRadiusTwoValue(radiusTwo);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));
    }
  };

  useEffect(() => {
    getJobListing();
  }, [jobInfo, salaryInfo, parseInt(salaryTwo), parseInt(radiusOne), parseInt(radiusTwo)]);

  useEffect(() => {
    getFilterData();
    clearFilters()
  }, [])

  const styles = {
    valueLabel: {
      padding: '8px',
      backgroundColor: 'red',
      color: 'white',
    },
  };
  return (
    <div className="wrapper">
      {/* <HeaderUser /> */}
      <div className="main py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 listing-sidebar">
              <div className="d-flex align-items-center justify-content-between pb-3">
                <h4 className="medium">Filters</h4>
                {!isFilterCleared && 
                <Link className="text-green" onClick={(e) => {
                  e.preventDefault();
                  clearFilters()
                }}>Clear filters</Link>}
              </div>

              <div className="border p-3 widget-box slider-box mb-3 rounded-8">
                <h5 className="medium pb-4">Salary Range</h5>
                <Slider
                sx={{
                  '& .MuiSlider-valueLabel': {
                    color: 'white',
                    backgroundColor: '#548235'
                  },
                }}
                // classes={{
                //   valueLabel: classes.valueLabel,
                // }}
                // aria-labelledby="discrete-slider"
                    style={{ marginTop: '30px', color: '#548235' }}
                    value={value}
                    onChange={rangeSelector}
                    onChangeCommitted={rangeSelector2}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => `$${value?.toLocaleString("ja-JP")}`}
                    defaultValue={50}
                    min={0}
                    max={salaryTwoCopy}
                    disabled={salaryTwoCopy==0? true : false}
                />
                {/* <div className="range-slider salary-range position-relative">
                  <span
                    className="output outputOne"
                    style={{
                      left: `${(salaryOne / parseInt(salaryStaticTwo)) * 100}%`,
                    }}
                  >
                    ${salaryOne}
                  </span>
                  <span
                    className="output outputTwo"
                    style={{
                      left: `${(salaryTwo / parseInt(salaryStaticTwo)) * 100}%`,
                    }}
                  >
                    ${salaryTwo}
                  </span>
                  <span className="full-range"></span>
                  <span
                    className="incl-range"
                    style={{
                      left: `${(salaryOne / (parseInt(salaryStaticTwo))) * 100}%`,
                      width: `${((salaryTwo - salaryOne) / parseInt(salaryStaticTwo)) * 100  }%`,
                    }}
                  ></span>

                  <input
                    id="rangeOne"
                    onMouseLeave={(e) => {
                      if (oldSalaryOne != salaryTwo) {
                        getJobListing();
                      }
                    }}
                    value={salaryOne}
                    min="0"
                    max={salaryStaticTwo-50}
                    step={1}
                    type="range"
                    onChange={(e) => setSalaryOneValue(Math.min(e.target.value, salaryTwo - 1))}
                  />
                  <input
                    id="rangeTwo"
                    onMouseLeave={(e) => {
                      if (oldSalaryTwo != salaryTwo) {
                        getJobListing();
                      }
                    }}
                    value={salaryTwo}
                    min={0}
                    max={salaryStaticTwo}
                    step={1}
                    type="range"
                    onChange={(e) => setSalaryTwoValue(Math.max(e.target.value, salaryOne - (-1)))}
                  />
                </div> */}
              </div>

              <div className="border p-3 widget-box slider-box mb-3 rounded-8">
                <h5 className="medium pb-4">Location Radius</h5>
                <Slider
                sx={{
                  '& .MuiSlider-valueLabel': {
                    color: 'white',
                    backgroundColor: '#548235'
                  },
                }}
                // classes={{
                //   valueLabel: classes.valueLabel,
                // }}
                    style={{ marginTop: '30px', color: '#548235' }}
                    value={locValue}
                    onChange={rangeSelectorLoc}
                    onChangeCommitted={rangeSelectorLoc2}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => `${value}Km`}
                    defaultValue={50}
                    min={0}
                    max={radiusTwoCopy}
                    disabled={radiusTwoCopy==0? true : false}
                />
                {/* <div className="range-slider radius-slider position-relative">
                  <span
                    className="output radiusOne"
                    style={{
                      left: `${radiusOne}%`,
                    }}
                  >
                    {radiusOne}Km
                  </span>
                  <span
                    className="output radiusTwo"
                    style={{
                      left: `${radiusTwo}%`,
                    }}
                  >
                    {radiusTwo}Km
                  </span>
                  <span className="full-range"></span>
                  <span
                    className="incl-radius"
                    style={{
                      left: `${radiusOne}%`,
                      width: `${parseInt(radiusTwo) - parseInt(radiusOne)}%`,
                    }}
                  ></span>
                  <input
                    className="radiusOne"
                    id="radiusOne"
                    onMouseLeave={(e) => {
                      if (oldRadiusOne != radiusOne) {
                        getJobListing();
                      }
                    }}
                    value={radiusOne}
                    min="0"
                    max={100}
                    step="1"
                    type="range"
                    onChange={(e) => setRadiusOneValue(Math.min(e.target.value, radiusTwo - 1))}
                  />
                  <input
                    className="radiusTwo"
                    id="radiusTwo"
                    onMouseLeave={(e) => {
                      if (oldRadiusTwo != radiusTwo) {
                        getJobListing();
                      }
                    }}
                    value={radiusTwo}
                    min="0"
                    max="100"
                    step="1"
                    type="range"
                    onChange={(e) => setRadiusTwoValue(Math.max(e.target.value, radiusOne - (-1)))}
                  />

                </div> */}
              </div>

              <div className="border p-3 widget-box mb-3 rounded-8">
                <h5 className="text-black medium pb-3">Job Type</h5>

                <div className="d-flex align-items-center gap-3 pb-3">
                  <input
                    type="checkbox"
                    id="type1"
                    checked={jobInfo.includes("full_time")}
                    onChange={(e) => {
                      addRemoveJobType("full_time", e);
                    }}
                  />
                  <label
                    htmlFor="type1"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    Full Time{" "}
                    <span>{getFilterCount("jobTypes", "full_time")}</span>
                  </label>
                </div>

                <div className="d-flex align-items-center gap-3 pb-3">
                  <input
                    type="checkbox"
                    id="type2"
                    checked={jobInfo.includes("part_time")}
                    onChange={(e) => {
                      addRemoveJobType("part_time", e);
                    }}
                  />
                  <label
                    htmlFor="type2"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    Part Time <span>{getFilterCount("jobTypes", "part_time")}</span>
                  </label>
                </div>
                <div className="d-flex align-items-center gap-3 ">
                  <input
                    type="checkbox"
                    id="type6"
                    checked={jobInfo.includes("freelance")}
                    onChange={(e) => {
                      addRemoveJobType("freelance", e);
                    }}
                  />
                  <label
                    htmlFor="type6"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    Freelance<span>{getFilterCount("jobTypes", "freelance")}</span>
                  </label>
                </div>
              </div>

              <div className="border p-3 widget-box mb-3 rounded-8">
                <h5 className="text-black medium pb-3">Salary Type</h5>

                <div className="d-flex align-items-center gap-3 pb-3">
                  <input
                    type="checkbox"
                    id="typeyearly"
                    checked={salaryInfo.includes("yearly")}
                    onChange={(e) => {
                      addRemoveSalaryType("yearly", e);
                    }}
                  />
                  <label
                    htmlFor="typeyearly"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    Yearly{" "}
                    <span>{filterData?.jobSalaryTypes?.yearly}</span>
                  </label>
                </div>

                <div className="d-flex align-items-center gap-3 pb-3">
                  <input
                    type="checkbox"
                    id="typemonthly"
                    checked={salaryInfo.includes("monthly")}
                    onChange={(e) => {
                      addRemoveSalaryType("monthly", e);
                    }}
                  />
                  <label
                    htmlFor="typemonthly"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    Monthly <span>{filterData?.jobSalaryTypes?.monthly}</span>
                  </label>
                </div>
                <div className="d-flex align-items-center gap-3 ">
                  <input
                    type="checkbox"
                    id="typeHourly"
                    checked={salaryInfo.includes("hourly")}
                    onChange={(e) => {
                      addRemoveSalaryType("hourly", e);
                    }}
                  />
                  <label
                    htmlFor="typeHourly"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    Hourly <span>{filterData?.jobSalaryTypes?.hourly}</span>
                  </label>
                </div>
              </div>
            </div>


            <div className="col-lg-9">
              <div className="d-md-flex align-items-center gap-2 pb-3">
                <h1 className="medium fs-24 pb-3 w-50">
                  Latest Jobs in Your Location
                </h1>
                <div className="border rounded-3 px-3 d-flex py-2 search-location-input">
                  <LocationAutocomplete
                    ref={locationRef}
                    onLocationChanged={onLocationChanged}
                    address={add}
                  ></LocationAutocomplete>
                </div>
              </div>
              <div className="row m-0 gx-0">
                {data?.data?.map((item, index) => {
                  const createdDate = new Date(item?.created_at);
                  const currentDate = new Date();
                  // Calculate the difference in days
                  const timeDifference = currentDate - createdDate;
                  const daysDifference = Math.floor(
                    timeDifference / (1000 * 3600 * 24)
                  );
                  if (item?.status === "inactive" || new Date(item?.job_expire_date) < new Date()) return null;
                  return (
                    <div key={index} className="col-lg-4 col-md-6 mb-3 pe-3">
                      <div className="border rounded-8 p-2 job-box">
                        <div className="d-flex align-items-center gap-2 mb-2 pb-2">
                          <Link 
                            to={"/facilities-details/" + item?.user?.slug}
                            >
                            <img
                            src={item?.user?.image}
                            width="40"
                            height="40"
                            className="rounded-pill w-40"
                            alt=""
                          />
                          </Link>
                          <Link 
                          to={"/facilities-details/" + item?.user?.slug}
                          >
                          <h6 className="fs-14 semibold">
                            {item?.user?.name}
                          </h6>
                          </Link>
                        </div>

                        <div className="">
                          <h5 className="semibold pb-2">
                            {item?.job_title} - {item?.user?.state}
                          </h5>
                          <h5 className="text-green medium">
                            ${item?.salary_from.toLocaleString("ja-JP")} - ${item?.salary_to.toLocaleString("ja-JP")}{" "}
                            <span className="fs-14 text-lightgray regular">
                              {item?.salary_type === "monthly"
                                ? "month"
                                : item?.salary_type}
                            </span>
                          </h5>
                          <h6 className="light py-2">
                            Job type:{" "}
                            <span className="medium">
                              {item?.job_type === "full_time"
                                ? "Full Time"
                                : item?.job_type === "part_time"
                                  ? "Part Time"
                                  : item?.job_type === "freelance"
                                    ? "Freelance"
                                    : ""}
                            </span>
                          </h6>
                          <h6 className="fs-13 regular mb-2">
                            {daysDifference} days ago
                          </h6>

                          <Link
                            to={`/job-details/${item?.id}`}
                            className="butn butn-green fs-13 py-2 px-3"
                          >
                            View Detail
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {data?.data.length === 0 && <React.Fragment>
                    <div className="col-lg-4 col-md-6 mb-3">
                            <div className="px-3">
                                <p className="fs-16 pt-2">
                                    No job found
                                </p>
                            </div>
                       
                    </div>
                </React.Fragment>}
              </div>



            </div>
          </div>
        </div>
      </div>
      {/* <!-- END MAIN --> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, {})(JobListing);
// export default JobListing;
