import React, { useEffect } from "react";
import RatingView from "../../Layout/RatingView";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { useState } from "react";
import SideBar from "../../Layout/SideBar";
import { formatAMPM, monthNames } from "../../Utils/constants";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import { Posts } from "../../Layout/Posts";
import ReactStars from "react-rating-stars-component";
import Promotions from "../Profile/Promotions";
const $ = window.jQuery;

function CoachProfile(props) {
  const editProperty = { ...{ edit: true }, ...props }
  const [editProps, editPros] = useState(editProperty)
  const [user, setUserInfo] = useState(props.user)
  const [postList, setPostList] = useState([]);
  const [postInfo, setPostInfo] = useState({ total: 10 })
  const [selectedPost, setSelectedPost] = useState(null);
  const [modalView, setModalView] = useState('');
  const loadMoreData = async (e) => {
    if (postInfo.next_page_url) {
      getPostList(`${Endpoints.getPosts}?page=${postInfo.current_page + 1}`)
    }
  }
  const getPostList = async (url) => {
    try {
      let resp = await FetchApi(url ? url : Endpoints.getPosts, {});
      if (resp && resp.type === "success") {
        setPostInfo(resp.data)
        const data = resp.data.data;
        data.forEach((item) => {
          const created_at = new Date(item.created_at)
          item['created_date'] = `${created_at.getDate()} ${monthNames[created_at.getMonth()]} ${created_at.getFullYear()}`
          item['time'] = formatAMPM(created_at)
          item['videos'] = item.user_post_file.filter(x => x.file_type === 'video')
          item['images'] = item.user_post_file.filter(x => x.file_type !== 'video')
          if (item.post_comments) {
            item.post_comments.forEach((comment) => {
              const comment_created_at = new Date(comment.created_at)
              comment['created_date'] = `${comment_created_at.getDate()} ${monthNames[comment_created_at.getMonth()]} ${comment_created_at.getFullYear()}`
              comment['time'] = formatAMPM(comment_created_at)
              //comment['hasVideo'] = comment.post_comment_file.find(x => x.file_type === 'video')
            })
          }
          if (item.shared_post && item.shared_post.shared_user_post) {
            let sharedPost = item.shared_post.shared_user_post
            const shared_post_created_at = new Date(sharedPost.created_at)
            sharedPost['created_date'] = `${shared_post_created_at.getDate()} ${monthNames[shared_post_created_at.getMonth()]} ${shared_post_created_at.getFullYear()}`
            sharedPost['time'] = formatAMPM(shared_post_created_at)
            sharedPost['videos'] = sharedPost.user_post_file.filter(x => x.file_type === 'video')
            sharedPost['images'] = sharedPost.user_post_file.filter(x => x.file_type !== 'video')
            item.shared_post.shared_user_post = sharedPost;
          }
        })
        if (url) {
          setPostList([...postList, ...data]);
        }
        else {
          setPostList(data)
          setTimeout(() => {
            $(".play-video").click(function (e) {
              var a = e.currentTarget.id.split('_');
              var video_id = `video_${a[1]}`
              $(`#${video_id}`).trigger('play').attr('controls', '');
              $(this).fadeOut();
            });
          })
        }
      }
    }
    catch (e) {
      console.log(e)
      console.log('PostList', JSON.stringify(e, null, 4));
    }
  }
  useEffect(() => {
    getPostList();
  }, []);
  const modalWindowCallBack = (data) => {
    console.log(data)
    setSelectedPost(data.post);
    setModalView(data.viewModal)
  }
  return (
    <div className="main py-4 ">
      <div className="container">
        <div className="row feed-page">
          <SideBar />
          {/* <!-- END LEFT COLUMN --> */}
          <div className="col-lg-6 middle-area">
            <div className="border rounded-8 p-3">
              <div className="d-flex gap-3 mb-3 profile-info">
                <span className="user-pp-80">
                  <img
                    src={user.image ? user.image : '../assets/img/edit-profile.png'}

                    className="rounded-pill dp-border"
                    alt=""
                  />
                </span>

                <div>
                  <h5 className="semibold">{user.name} {user.last_name ? user.last_name : ''}</h5>
                  <div className="d-flex align-items-center gap-2 pb-2">
                    <span className="d-inline-flex">
                      <ReactStars
                        count={5}
                        onChange={(e) => { }}
                        size={20}
                        isHalf={true}
                        edit={false}
                        value={props?.user?.reviews_rating ? parseFloat(props?.user?.reviews_rating) : 0}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                      />
                    </span>
                    <span className="fs-13 medium">
                      {props?.user?.reviews_rating ? parseFloat(props?.user?.reviews_rating) : 0}{" "}
                      <span className="text-lightgray regular">Ratings</span>{" "}
                      | {props.user.user_reviews.length > 100 ? '100+' : props?.user?.user_reviews?.length}{" "}
                      <span className="text-lightgray regular">Reviews</span>
                    </span>
                  </div>

                  <div className="d-flex gap-2 pb-1 align-items-center">
                    {/* <h6 className="regular text-lightgray">{user.user_sports.name}</h6> */}
                    <h6 className="regular text-lightgray">{user?.coach_detail?.category?.name}</h6>
                    <i className=" text-lightgray">•</i>
                    <p className="fs-12">
                      <i className="fa fa-map-marker-alt text-green pe-1 fs-16"></i>{" "}
                      {user.address}
                    </p>
                  </div>
                  <div className="d-sm-flex align-items-center gap-2">
                    <p className="fs-12 m-0 pb-2">
                      <i className="fa fa-envelope text-green pe-1 fs-14"></i>{" "}
                      {user.email}
                    </p>
                    <p className="fs-12 m-0 pb-2">
                      <i className="fa fa-phone text-green pe-1 ps-sm-2 fs-13"></i>{" "}
                      +{user.phone}
                    </p>
                  </div>
                  <Link to={'/update-coach-profile'} state={editProps} className="butn py-2 fs-15">
                    Edit Profile
                  </Link>
                </div>
              </div>

              <h3 className="medium pb-1">About</h3>
              <p className="text-justify">
                {user.showFull ? user.about : user.about.slice(0, 140)} {user.about.length > 140 &&
                  <a onClick={(e) => {
                    e.preventDefault();
                    let newCoachInfo = { ...user }
                    newCoachInfo['showFull'] = !newCoachInfo.showFull;
                    setUserInfo(newCoachInfo)
                  }} className="text-green c-p"
                    style={{
                      textDecoration: "underline"
                    }}
                  >{user.showFull ? 'Read Less' : 'Read More'}</a>}
                {/* <Link className="text-green medium">Read More</Link> */}
              </p>

              <h3 className="medium pb-2">Specialities</h3>
              <ul className="taglist d-flex align-items-start gap-2 flex-wrap pb-3">
                {user.user_speciality.map((item, i) => {
                  return (<li key={i}>{item.speciality}</li>)
                })}
              </ul>
              <h3 className="medium pb-2">Certificates</h3>
              <ul className="taglist d-flex align-items-start gap-2 flex-wrap">
                {user.user_certificate.map((item, i) => {
                  return (<li key={i}>
                    <a href={item.image} target="_blank" className="d-flex align-items-start gap-1">
                      <img src="../assets/img/icon/certificate.svg" alt="" />{item.image_name}
                    </a>
                  </li>)
                })
                }
              </ul>
            </div>
            <Posts user={props.user} pageFrom='coach-profile' />
            <div className="review-section">
              <RatingView pageFrom="profile" userInfo={props.user} />
            </div>
          </div>
          {/* <!-- END MIDDLE COLUMN --> */}
          <Promotions />
          {/* <div className="col-lg-3 right-sidebar">
            <h4 className="fs-20 medium pb-2">Promotions</h4>
            <div className="border rounded-8 p-2 promo-box mb-3">
              <div className="d-flex align-items-center gap-2 mb-2">
                <img
                  src="../assets/img/stadium.png"
                  width="40"
                  height="40"
                  className="rounded-pill w-40"
                  alt=""
                />
                <div>
                  <h6 className="fs-14 semibold">National Stadium</h6>
                  <p className="fs-13 text-lightgray m-0">Sponsored</p>
                </div>
              </div>

              <img
                src="../assets/img/big-stadium.png"
                className="w-100 rounded-8"
                alt=""
              />

              <div className="mt-2">
                <p className="fs-14 text-green">
                  Sed ut perspiciatis unde iste natus error sit voptatem
                  acantium
                </p>
              </div>
            </div>

            <div className="border rounded-8 p-2 promo-box mb-3">
              <div className="d-flex align-items-center gap-2 mb-2">
                <img
                  src="../assets/img/stadium.png"
                  width="40"
                  height="40"
                  className="rounded-pill w-40"
                  alt=""
                />
                <div>
                  <h6 className="fs-14 semibold">National Stadium</h6>
                  <p className="fs-13 text-lightgray m-0">Sponsored</p>
                </div>
              </div>

              <div className="">
                <p className="fs-14">
                  Sed ut perspiciatis unde iste natus error sit voptatem
                  acantium
                </p>
                <Link className="butn butn-green fs-13 py-2 px-3">
                  Get Offer
                </Link>
              </div>
            </div>

            <div className="border rounded-8 p-2 promo-box mb-3">
              <div className="d-flex align-items-center gap-2 mb-2">
                <img
                  src="../assets/img/stadium.png"
                  width="40"
                  height="40"
                  className="rounded-pill w-40"
                  alt=""
                />
                <div>
                  <h6 className="fs-14 semibold">Stadium for You</h6>
                  <p className="fs-13 text-lightgray m-0">Sponsored</p>
                </div>
              </div>

              <img
                src="../assets/img/big-stadium.png"
                className="w-100 rounded-8"
                alt=""
              />

              <div className="mt-2">
                <p className="fs-14 text-green">
                  Sed ut perspiciatis unde iste natus error sit voptatem
                  acantium
                </p>
              </div>
            </div>
          </div> */}
          {/* <!-- END RIGHT COLUMN --> */}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(CoachProfile);
