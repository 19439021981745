import { Endpoints } from "../../API/Endpoints";
import { FetchApi } from "../../API/FetchApi";

export const stripePaymentMethodHandler = async (data, cb) => {
  const { amount, result, payment_intent_client_secret } = data;
  // console.log('amt__',amount)
  if (result?.error) {
    // show error in payment form
    cb(result);
  } else {
    let obj = null;
    // if (payment_intent_client_secret) {
    //   obj = {
    //     payment_intent_id: payment_intent_client_secret,
    //    //payment_method_id: result.paymentIntent.id,
    //     amount: result.paymentIntent.amount
    //   }
    // }
    if (amount) {
      obj = {
        // payment_intent_id: payment_intent_client_secret,
        amount: amount
      }
    }
    else {
      obj = {
        payment_method_id: result?.paymentMethod?.id,
        name: result?.paymentMethod?.billing_details?.name,
        email: result?.paymentMethod?.billing_details?.email,
        amount: amount
      }
    }
    const paymentResponse = await stripePayment(obj);
    cb(paymentResponse);
  }
}

// place backend API call for payment
const stripePayment = async (data) => {
  return await FetchApi(Endpoints.stripePay, data);
}