import React from "react";
import { Routes, Navigate, Route, Outlet } from "react-router-dom";
import { isUserAuthenticated } from "./helpers/authUtils";
import Container from "./Components/Container";
import Login from "./Pages/auth/Login";
import Register from "./Pages/auth/Signup";
import ForgetPassword from "./Pages/auth/ForgotPassword";
import VerifyOtp from "./Pages/auth/VerifyOtp";
import SetNewPassword from "./Pages/auth/SetNewPassword";
import UserCompleteProfile from "./Pages/Profile/UserCompleteProfile";
import CoachProfileComplete from "./Pages/CoachPages/CoachProfileComplete";
import FacilityProfileComplete from "./Pages/Facility/FacilityProfileComplete";
import LandingPage from "./Pages/Profile/LandingPage";
import UserHomePage from "./Pages/Profile/UserHomePage";
import ManageAvailability from "./Pages/ManagesPages/ManageAvailability";
import Coaches from "./Pages/CoachPages/Coaches";
import Profile from "./Pages/Profile/Profile";
import UserProfileOthers from "./Pages/Profile/UserProfileOthers";
import CoachProfile from "./Pages/CoachPages/CoachProfile";
import FacilityProfile from "./Pages/Facility/FacilityProfile";
import CoachDetails from "./Pages/CoachPages/CoachDetails";
import ManageKids from "./Pages/ManagesPages/ManageKids";
import ManageGallery from "./Pages/ManagesPages/ManageGallery";
import MyBookingUpcoming from "./Pages/Booking/MyBookingUpcoming";
import BookingSummary from "./Pages/Booking/BookingSummary";
import StripePaymentForm from "./Pages/Booking/StripePaymentForm";
import FacilityProfileOthersView from "./Pages/Facility/FacilityProfileOthersView";
import Schedule from "./Pages/MyShedule/schedule";
import JobCreation from "./Pages/JobFeaturesPages/JobCreation";
import JobDetails from "./Pages/JobFeaturesPages/JobDetails";
import JobListing from "./Pages/JobFeaturesPages/JobListing";
import ManageJobs from "./Pages/JobFeaturesPages/ManageJobs";
import UpdateJob from "./Pages/JobFeaturesPages/UpdateJob";
import ViewJob from "./Pages/JobFeaturesPages/ViewJob";
import UserMessages from "./Pages/Profile/UserMessages";
import UserMessagesRequest from "./Pages/Profile/UserMessagesRequest";
import Add from "./Pages/Ads/Add";
import AdaList from "./Pages/Ads/AdaList";
import Edit from "./Pages/Ads/Edit";
import RewardPoints from "./Pages/Profile/RewardPoints";
import ChangePassword from "./Pages/Profile/ChangePassword";
import JobApplication from "./Pages/JobFeaturesPages/JobApplication";
import Payment from "./Pages/Payment/Payment";
import LeadList from "./Pages/Ads/LeadList";
import ViewAda from "./Pages/Ads/ViewAda";
import ContactUs from "./Pages/Profile/ContactUs";
import CheckOthersAvail from "./Pages/Booking/CheckOthersAvail";
import PaymentSuccess from "./Pages/Payment/PaymentSuccess";

const pageRenderLoader = () => <div className="http-loader"></div>;
// authgurd defination
const ProtectedRoute = ({ isAllowed, redirectPath = "/login", children }) => {
  const isAuthTokenValid = isUserAuthenticated();
  const currentURL = window.location.href;
  if(currentURL.includes('view-availability') && !isAuthTokenValid){
    localStorage.setItem('redirectAfterLogin', currentURL);
  }
  if (!isAuthTokenValid) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
// un-authgurd
const UnAuthorizedRoute = ({
  redirectUrl,
  redirectPath = "/feeds",
  children,
}) => {
  const isAuthTokenValid = isUserAuthenticated();
  //const location = useLocation();
  if (isAuthTokenValid) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
export default function AppRoutes() {
  return (
    <Routes>
      <React.Fragment>
        <Route path="/" element={<Container />}>
          <Route
            index
            element={
              <UnAuthorizedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <LandingPage />
                </React.Suspense>
              </UnAuthorizedRoute>
            }
          />
          <Route
            path="feeds"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <UserHomePage />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="manage-availability"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <ManageAvailability />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="view-availability/:slug"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <CheckOthersAvail />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <Profile />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="my-schedule"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <Schedule />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="my-bookings"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <MyBookingUpcoming />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="manage-kids"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <ManageKids />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="update-user-profile"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <UserCompleteProfile />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="coache-profile"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <CoachProfile />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="update-coach-profile"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <CoachProfileComplete />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="payment"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <Payment />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="facility-profile"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <FacilityProfile />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="update-facility-profile"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <FacilityProfileComplete />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="coaches"
            element={
              <ProtectedRoute>
                {/* <React.Suspense fallback={pageRenderLoader()}> */}
                  <Coaches />
                {/* </React.Suspense> */}
              </ProtectedRoute>
            }
          />
          <Route
            path="facilities"
            element={
              <ProtectedRoute>
                {/* <React.Suspense fallback={pageRenderLoader()}> */}
                  <Coaches />
                {/* </React.Suspense> */}
              </ProtectedRoute>
            }
          />
          <Route
            path="facilities-details/:slug"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <FacilityProfileOthersView />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="manage-gallery"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <ManageGallery />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="coach-details/:slug"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <CoachDetails />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="user-details/:slug"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <UserProfileOthers />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="booking-summary"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <BookingSummary />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="stripe-payment-form"
            element={
              <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <StripePaymentForm />
                </React.Suspense>
              </ProtectedRoute>
            }
          />
          <Route
						path='payment-success'
						element={
							<ProtectedRoute>
								<React.Suspense fallback={pageRenderLoader()}>
									<PaymentSuccess />
								</React.Suspense>
							</ProtectedRoute>
						}
					/>
          <Route
            path="login"
            element={
              <UnAuthorizedRoute redirectUrl={"login"}>
                <React.Suspense fallback={pageRenderLoader()}>
                  <Login />
                </React.Suspense>
              </UnAuthorizedRoute>
            }
          />
          <Route
            path="signup"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <Register />
              </React.Suspense>
            }
          />
          <Route
            path="forgot-password"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <ForgetPassword />
              </React.Suspense>
            }
          />
          <Route
            path="verify-otp"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <VerifyOtp />
              </React.Suspense>
            }
          />
          <Route
            path="set-new-Password"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <SetNewPassword />
              </React.Suspense>
            }
          />
          <Route
            path="user-complete-profile"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <UserCompleteProfile />
              </React.Suspense>
            }
          />
          <Route
            path="coach-profile-complete"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <CoachProfileComplete />
              </React.Suspense>
            }
          />
          <Route
            path="facility-profile-complete"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <FacilityProfileComplete />
              </React.Suspense>
            }
          />
          <Route
            path="job-create"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <JobCreation />
              </React.Suspense>
            }
          />
          <Route
            path="job-details/:id"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <JobDetails />
              </React.Suspense>
            }
          />
          <Route
            path="job-listing"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <JobListing />
              </React.Suspense>
            }
          />
          {/* update-facility-profile */}
          <Route
            path="manage-job"
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <ManageJobs />
              </React.Suspense>
            }
          />

          <Route
            path={`update-job/:id`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <UpdateJob />
              </React.Suspense>
            }
          />
          <Route
            path={`message`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <UserMessages />
              </React.Suspense>
            }
          />
          <Route
            path={`message-request`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <UserMessagesRequest />
              </React.Suspense>
            }
          />

          <Route
            path={`job-view/:id`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <ViewJob />
              </React.Suspense>
            }
          />
          <Route
            path={`create-advertisement`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <Add />
              </React.Suspense>
            }
          />
          <Route
            path={`manage-advertisement`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <AdaList />
              </React.Suspense>
            }
          />
          <Route
            path={`edit-advertisement/:id`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <Edit />
              </React.Suspense>
            }
          />
          <Route
            path={`view-advertisement/:id`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <ViewAda />
              </React.Suspense>
            }
          />
          <Route
            path={`reward-points`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <RewardPoints />
              </React.Suspense>
            }
          />
          <Route
            path={`change-password`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <ChangePassword />
              </React.Suspense>
            }
          />
          <Route
            path={`job-application/:id`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <JobApplication />
              </React.Suspense>
            }
          />
        
          <Route
            path={`lead/:id`}
            element={
              <React.Suspense fallback={pageRenderLoader()}>
                <LeadList />
              </React.Suspense>
            }
          />
          <Route
            path="contact-us"
            element={
              // <ProtectedRoute>
                <React.Suspense fallback={pageRenderLoader()}>
                  <ContactUs />
                </React.Suspense>
              // </ProtectedRoute>
            }
          />
        </Route>

        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </React.Fragment>
    </Routes>
  );
}
