import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ToastMessage from "../Utils/ToastMessage";

export default function MessageRequestModal({
    handleSendMessage,
    coachInfo,
    modalIsOpen,
    setModalIsOpen,
    messageDescription, 
    setMsgDescription
}) {
    // const [messageDescription, setMsgDescription] = useState('');
    // const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleClose = () => {
        setModalIsOpen(false);
    };
    return(
        <Modal
        show={modalIsOpen}
        onHide={handleClose}>
            <Modal.Header>
            <Modal.Title className="fs-18">Add Message</Modal.Title>
            <div
            className="c-p"
            onClick={()=>{
                handleClose();
            }}
            >
                X
            </div>
            </Modal.Header>
            <Modal.Body 
            style={{
            }}
            >
                <textarea
                style={{
                  
                }}
                className="p-2 border-0"
                placeholder="Enter message"
                onChange={(e)=>{
                  setMsgDescription(e.target.value);
                }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                style={{
                  backgroundColor: 'green'
                }}
                // className="bg-green"
                onClick={()=>{
                  if(!messageDescription){
                    ToastMessage.Error('Please enter message');
                  }
                  else{
                    handleSendMessage(coachInfo?.id)
                  }
                }}
                >
                  Submit
                  </Button>
            </Modal.Footer>

        </Modal>
    )
}