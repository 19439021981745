import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
// import HeaderUser from "../../Layout/HeaderUser";
import Footer from "../../Layout/Footer";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { uuidv4 } from "../../Utils/constants";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { FetchApi } from "../../API/FetchApi";
import ReactStars from "react-rating-stars-component";
import MessageRequestModal from "../../Components/messageRequestModal";
function JobDetails(props) {

  const [data, setData] = useState();
  const [jobList, setJobList] = useState([]);
  const [similarJobList, setSimilarJobList] = useState([]);
  const [savedResume, setSavedResume] = useState([]);
  const [resumeData, setResumeData] = useState();
  const [resumeName, setresumeName] = useState();
  const [isSaveForFuture, setIsSaveForFuture] = useState(false);
  const [kidsList, setKidsList] = useState([]);
  const [user, setUserInfo] = useState(props.user);
  const [kidId, setKidId] = useState("");
  const [kidInfo, setKidInfo] = useState(null);
  const [message, setMessage] = useState("");
  const [messageDescription, setMsgDescription] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useNavigate();
  function stripHTMLAndFormatText(html) {
    const formattedText = [];
    let currentList = null;

    // Split the HTML into paragraphs
    const paragraphs = html?.split("<p>").filter(Boolean);

    // Process each paragraph
    paragraphs?.forEach((paragraph, index) => {
      const cleanedHTML = paragraph?.replace(/<\/?[^>]+(>|$)/g, "");

      if (cleanedHTML?.trim() === "<ul>" || cleanedHTML?.trim() === "<li>") {
        // Start of an unordered list
        currentList = {
          tag: "ul",
          style: "your-unordered-list-style",
          items: [],
        };
      } else if (cleanedHTML?.trim() === "</ul>") {
        // End of an unordered list, render it
        if (currentList && currentList.items.length > 0) {
          formattedText.push(
            <ul key={index} className={currentList?.style}>
              {currentList?.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          );
        }
        currentList = null;
      } else if (cleanedHTML?.trim() === "<li>") {
        // Start of a list item
        if (currentList) {
          const listText = paragraph?.replace(/<li>|<\/li>/g, "");
          currentList?.items.push(listText);
        } else {
          // Not inside a list, treat it as a regular paragraph
          formattedText.push(
            <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
          );
        }
      } else {
        // Regular paragraph
        formattedText.push(
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
        );
      }
    });

    return formattedText;
  }


  const descriptionWithoutPTags = stripHTMLAndFormatText(data?.job_description);
  const rolesWithoutPTags = stripHTMLAndFormatText(data?.role_responsibility);


  const createdDate = new Date(data?.created_at);
  const currentDate = new Date();
  // Calculate the difference in days
  const timeDifference = currentDate - createdDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

  const { id } = useParams();


  const getUserResume = async () => {
    try {
      let resp = await CommonAPi(Endpoints.userDetail, {
        slug: props.user.slug,
      });
      if (resp && resp.status === "success") {
        setSavedResume(resp?.data?.user_resumes);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));
    }
  };

  const removeUserResume = async (resumeList, selectedIndex) => {
    let resumeListInState = [...resumeList];
    const selectedItem = savedResume[selectedIndex];

    if (selectedItem?.id) {
      try {
        const postData = { id: selectedItem?.id };

        let resp = await CommonAPi(Endpoints.removeUserResume, postData);
        if (resp && resp.status === "success") {
          resumeListInState.splice(selectedIndex, 1);
          setSavedResume(resumeListInState);
        }
        if (resp && resp.status === "false") {
          ToastMessage.Error(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.message) {
          Swal.fire({
            text: "Failed to delete the resume pdf",
            icon: "error",
          });
        }
      }
    } else {
      resumeListInState.splice(selectedIndex, 1);
      setSavedResume(resumeListInState);
    }
    // let obj = {
    //   id: id,
    // };
    // try {
    //   // let resp = await CommonAPi(Endpoints.removeUserResume, obj);
    //   // if (resp && resp.status === "success") {
    //   //   setSavedResume(resp?.data);
    //   // }
    //   // if (resp && resp.status === "false") {
    //   //   ToastMessage.Error(resp.message);
    //   // }
    // } catch (e) {
    //   console.log("registerErr__", JSON.stringify(e.response, null, 4));
    // }
  };

  const getKidsList = async () => {
    try {
      let resp = await FetchApi(Endpoints.getUserKids);
      if (resp && resp.type === "success") {
        const kidsList = resp.data;
        if (kidsList.length > 0) {
          let data = kidsList[0].user_kids;
          if (data.length > 0) {
            data.push({
              id: "self",
              name: "Self",
            });
          }
          setKidsList(data);
        }
      }
    } catch (e) {
      console.log("AvailabilityList", JSON.stringify(e, null, 4));
    }
  };

  const getJobDetail = async () => {
    let obj = {
      job_id: id,
    };

    try {
      let resp = await CommonAPi(Endpoints.getJobDetail, obj);
      if (resp && resp.status === "success") {
        setData(resp?.data);
        setSimilarJobList(resp?.similarJobsData?.data);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));
    }
  };

  const getJobListing = async () => {
    let obj = {
      data_for: "all",
    };

    try {
      let resp = await CommonAPi(Endpoints.getJobListing, obj);
      if (resp && resp.status === "success") {
        setJobList(resp?.data?.data);
        // setSimilarJobList(resp?.data?.similarJobsData?.data);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));
    }
  };

  const onResume = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      const id = uuidv4();

      // const updatedResume = [{ file_name: url, id: id }];
      const updatedResumeData = [{ id: id, file: file }];

      // setResume(updatedResume);
      setResumeData(updatedResumeData);
    }
    // let resumeUrlList = [...resume];
    // let files = [...resumeData];
    // if (event.target.files && event.target.files.length > 0) {
    //   for (let i = 0; i < event.target.files.length; i++) {
    //     const file = event.target.files[i]
    //     const url = URL.createObjectURL(file)
    //     const id = uuidv4()
    //     resumeUrlList.push({ file_name: url, id: id })
    //     files.push({
    //       id: id,
    //       file: file
    //     })
    //   }
    //   setResume(resumeUrlList);
    //   setResumeData(files);
    // }
  };

  const applyForJob = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("job_id", id);

    if (props.user?.user_role.name === "Coach") {
      formData.append("apply_for", "self");
      if (!resumeData?.length) {

        formData.append("file_type", "old");
        formData.append("file_name", resumeName);
      } else {
        formData.append("file_type", "new");
        for (const image of resumeData) {
          formData.append("file", image?.file);
        }
      }
    } else {
      console.log("kidId_____", kidId);
      let applykid = "";
      if (kidId) {
        applykid = kidId !== "self" ? "kids" : "self";
      } else {
        applykid = "self"
      }

      formData.append("apply_for", applykid);
      if (applykid === "kids") {
        formData.append("kids_id", kidId);
      }
      if (!resumeData?.length) {

        formData.append("file_type", "old");
        formData.append("file_name", resumeName);
      } else {
        formData.append("file_type", "new");
        for (const image of resumeData) {
          formData.append("file", image?.file);
        }
      }
    }

    const saveForFuture = isSaveForFuture ? 1 : 0;
    formData.append("is_save_for_future", saveForFuture);
    formData.append("message", message);

    try {
      let resp = await CommonAPi(Endpoints.applyForJob, formData);
      if (resp && resp.status === "success") {
        getJobDetail();
        ToastMessage.Success(resp.message);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message) {
        Swal.fire({
          text: "Failed to upload resume",
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    getJobDetail();
    // getJobListing();
    getUserResume();
    getKidsList();
  }, [id]);


  const handleSendMessage = async (id) => {
    let data = {
      chat_receiver_id: id,
      message_description : messageDescription
    }
    try {
      let resp = await CommonAPi(Endpoints.CreateMessageRoomId, data);
      if (resp) {
        setModalIsOpen(false);
        if (resp.data?.status == "active") {
          history("/message");
        } else {
          history("/message-request");
        }

      }
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };

  return (
    <div className="wrapper">
      {
        data ?
          <div className="main py-4 ">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <div className="d-lg-flex justify-content-between align-items-start">
                    <div className="d-flex gap-3 mb-3 align-items-center">
                      <img
                        src={data?.user?.image}
                        width="60"
                        height="62"
                        className="rounded-pill dp-border"
                        alt=""
                      />
                      <div>
                        <h5 className="semibold">{data?.user?.name}</h5>
                        <h6 className="regular text-lightgray">
                          {data?.user?.user_sports?.name}
                        </h6>
                        <div className="d-flex align-items-center gap-2 pb-2">
                          {data?.user?.reviews_rating != null ?
                            <ReactStars
                              count={5}
                              size={20}
                              isHalf={true}
                              edit={false}
                              value={data?.user?.reviews_rating ? data?.user?.reviews_rating : 0}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                            :

                            <ReactStars
                              count={5}
                              size={20}
                              isHalf={true}
                              edit={false}
                              value={0}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />

                          }
                          <span className="fs-13 medium">
                            {data?.user?.reviews_rating}{" "}
                            <span className="text-lightgray regular">Ratings</span>{" "}
                            | {data?.user?.user_reviews_count}+{" "}
                            <span className="text-lightgray regular">Reviews</span>
                          </span>

                          {/* <Link> */}
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                    {(props?.user?.id != data?.user_id && props.user?.user_role.name != "Facilities")?
                      <div className="d-flex gap-2 mb-lg-0 mb-3">
                        <Link className="butn "
                          // onClick={() =>
                          //   history("/facilities-details", { state: data?.user })
                          // }
                          to={"/facilities-details/" + data?.user?.slug}
                        >
                          View Profile
                        </Link>
                        <Link className="butn gradient-butn" onClick={() => {
                          if(data?.user?.isMessageSend){
                            handleSendMessage(data?.user?.id)
                          }
                          else{
                            setModalIsOpen(true)
                          }
                        }}>Write Message</Link>
                      </div>
                      : ""}
                  </div>
                  <div className="border-top py-3 d-md-flex justify-content-between align-items-start">
                    <div>
                      <h4 className="fs-20 pb-2 semibold">{data?.job_title}</h4>
                      <h5 className="text-green fs-20 pb-2">
                        ${data?.salary_from.toLocaleString("ja-JP")} - ${data?.salary_to.toLocaleString("ja-JP")}{" "}
                        <span className="fs-16 medium text-lightgray">
                          {data?.salary_type === "monthly" ? "month" : "yearly"}
                        </span>
                      </h5>
                      <div className="d-flex gap-5 pb-3">
                        <h5 className="fs-16 text-gray">
                          Job type:{" "}
                          {data?.job_type === "full_time"
                            ? "Full Time"
                            : data?.job_type === "part_time"
                              ? "Part Time"
                              : data?.job_type === "freelance"
                                ? "Freelance"
                                : ""}
                        </h5>
                        <h5 className="fs-16 text-gray">
                          Posted {daysDifference} days ago
                        </h5>
                      </div>
                    </div>

                    {
                      (props?.user?.id != data?.user_id && 
                      props.user?.user_role.name != "Facilities")?
                        props.user?.user_role.name === "Coach" ? (
                          <>

                            {data?.isLogedInUserApplied == false ?
                              <Link
                                data-bs-toggle="modal"
                                data-bs-target="#jobapply"
                                className="butn butn-green fs-13"
                              >
                                Apply Now
                              </Link>
                              :
                              <Link className="butn butn-green fs-13">
                                Applied
                              </Link>

                            }
                          </>
                        ) : (
                          <>

                            {data?.isLogedInUserApplied == false ?
                              <Link
                                data-bs-toggle="modal"
                                data-bs-target="#jobapply2"
                                className="butn butn-green fs-13"
                              >
                                Apply Now
                              </Link>
                              :
                              <Link className="butn butn-green fs-13">
                                Applied
                              </Link>
                            }
                          </>
                        )
                        : ""
                    }
                  </div>
                  <div className="pb-4">
                    <h4 className="fs-20 pb-2 medium pb-2">Description</h4>
                    <p className="text-justify text-696969">
                      {descriptionWithoutPTags}
                    </p>
                  </div>
                  <div>
                    {/* <h4 className="fs-20 pb-2 medium pb-2">
                      Roles and responsibility
                    </h4> */}
                    {rolesWithoutPTags}
                  </div>
                  {

                    (props?.user?.id != data?.user_id && 
                      props.user?.user_role.name != "Facilities"
                      ) ?
                      props.user?.user_role.name === "Coach" ? (
                        <>
                          {
                            data?.isLogedInUserApplied == false ?
                              <Link
                                data-bs-toggle="modal"
                                data-bs-target="#jobapply"
                                className="butn-green fs-16 medium text-white butn mt-2 mb-3 job-detail-btn"
                              >
                                Apply Now
                              </Link>
                              :
                              <Link

                                className="butn-green fs-16 medium text-white butn mt-2 mb-3 job-detail-btn"
                              >
                                Applied
                              </Link>
                          }
                        </>

                      ) : (
                        <>
                          {
                            data?.isLogedInUserApplied == false ?
                              <Link
                                data-bs-toggle="modal"
                                data-bs-target="#jobapply2"
                                className="butn-green fs-16 medium text-white butn mt-2 mb-3 job-detail-btn"
                              >
                                Apply Now
                              </Link>
                              :
                              <Link

                                className="butn-green fs-16 medium text-white butn mt-2 mb-3 job-detail-btn"
                              >
                                Applied
                              </Link>
                          }
                        </>
                      )
                      : ""
                  }
                </div>
              </div>
              {
              // jobList?.filter(item => item?.id != id).length > 0 ?
              similarJobList?.length > 0 ?
                <div className="border-top py-3">
                  <h3 className="medium text-darkgray">Similar Jobs</h3>
                  <div className="row py-3">
                    {
                    // jobList?.filter(item => item?.id != id)?.slice(0, 4)?.map((item, index) => {
                    similarJobList.map((item, index) => {
                      const createdDate = new Date(item?.created_at);
                      const currentDate = new Date();
                      // Calculate the difference in days
                      const timeDifference = currentDate - createdDate;
                      const daysDifference = Math.floor(
                        timeDifference / (1000 * 3600 * 24)
                      );
                      return (
                        <div key={index} className="col-lg-3 col-md-6 mb-3">
                          <div className="border rounded-8 p-2 job-box">
                            <div className="d-flex align-items-center gap-2 mb-2 pb-2">
                              <img
                              onClick={()=>{
                                history('/facilities-details/' + item?.user?.slug);
                              }}
                                src={item?.user?.image}
                                width="40"
                                height="40"
                                className="rounded-pill w-40 c-p"
                                alt=""
                              />
                              <h6 
                              onClick={()=>{
                                history('/facilities-details/' + item?.user?.slug);
                              }}
                              className="fs-14 semibold c-p">{item?.user?.name}</h6>
                            </div>

                            <div className="">
                              <h5 className="semibold pb-2">
                                {item?.job_title} - {item?.user?.state}
                              </h5>
                              <h5 className="text-green medium">
                                ${item?.salary_from.toLocaleString("ja-JP")} - ${item?.salary_to.toLocaleString("ja-JP")}{" "}
                                <span className="fs-14 text-lightgray regular">
                                  {item?.salary_type === "monthly"
                                    ? "month"
                                    : "yearly"}
                                </span>
                              </h5>
                              <h6 className="light py-2">
                                Job type:{" "}
                                <span className="medium">
                                  {item?.job_type === "full_time"
                                    ? "Full Time"
                                    : data?.job_type === "part_time"
                                      ? "Part Time"
                                      : data?.job_type === "freelance"
                                        ? "Freelance"
                                        : ""}
                                </span>
                              </h6>
                              <h6 className="fs-13 regular mb-2">
                                Posted {daysDifference} days ago
                              </h6>

                              <Link
                                to={`/job-details/${item?.id}`}
                                onClick={()=>{
                                  window.scrollTo(0,0)
                                }}
                                className="butn butn-green fs-13 py-2 px-3"
                              >
                                View Detail
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                : ""}
            </div>

            {/* <!-- Modal --> */}
            {props.user?.user_role.name === "Coach" && (
              <div
                className="modal fade "
                id="jobapply"
                tabIndex="-1"
                aria-labelledby="createpostmodal"
                aria-hidden="true"
              >
                <div className="modal-dialog ">
                  <div className="modal-content border-0 rounded-10">
                    <div className="modal-header border-0 p-2">
                      <button
                        type="button"
                        className="modal-close bg-transparent border-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="/assets/img/icon/close-circle.svg" alt="" />
                      </button>
                    </div>

                    <div className="modal-body">
                      <h3 className="text-darkgray pb-3 text-center">Job Apply</h3>
                      {!savedResume?.length <= 0 ? (
                        <>
                          <h5 className="text-darkgray fs-16 pb-2 semibold">
                            Saved Resume
                          </h5>
                          <div className="pb-4">
                            {savedResume?.map((item, index) => {

                              return (
                                <div className="w-100 d-flex" key={index}>
                                  <input
                                    type="radio"
                                    name="resumegroup"
                                    className="resume-radio check-radio align-self-center me-1"
                                    value={item?.file_name}
                                    onChange={(e, resume) => {
                                      const item = savedResume.find(
                                        (x) => x.id == e.target.value
                                      );
                                      setresumeName(e.target.value);
                                      setKidInfo(item);
                                    }}
                                    checked={resumeName == item?.file_name}
                                  />
                                  <h6 className="align-self-center">
                                    {item?.file_name}
                                  </h6>
                                  <span className="align-self-center ms-1 pb-1">
                                    {" "}
                                    <Link>
                                      <img
                                        onClick={() =>
                                          removeUserResume(savedResume, index)
                                        }
                                        src="/assets/img/icon/trash-red.svg"
                                        alt=""
                                      />
                                    </Link>
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <form onSubmit={applyForJob} action="" className="site-form">
                        <div className="form-field">
                          <label htmlFor="" className="pb-1">
                            Upload Resume
                          </label>
                          <h6>
                            {resumeData?.map((file, index) => {
                              return (
                                <div className="mb-1" key={index}>
                                  {file?.file?.name}
                                </div>
                              );
                            })}
                          </h6>
                          <input
                            name="myImage"
                            type="file"
                            onChange={(e) => onResume(e)}
                            className="d-none"
                            id="file"
                          />
                          <label
                            htmlFor="file"
                            className="upload-file job-apply text-green w-100 rounded-8 d-inline-block fs-16 light text-center px-4"
                          >
                            <img
                              src="assets/img/icon/documentdownload.svg"
                              className="me-2"
                              alt=""
                            />
                            Upload file
                          </label>
                        </div>

                        <div className="form-field mb-3">
                          <label htmlFor="" className="pb-1">
                            Message
                          </label>
                          <textarea
                            rows="4"
                            cols="50"
                            className="border"
                            value={message}
                            onChange={(e) => {
                              setMessage(e.target.value)
                            }}
                          ></textarea>

                        </div>

                        <div className="d-flex align-items-center gap-2 py-2">
                          <input
                            type="checkbox"
                            id="01"
                            defaultChecked={false}
                            onChange={(e) => setIsSaveForFuture(e.target.checked)}
                          />
                          <label htmlFor="01">Save for future</label>
                        </div>

                        <button
                          type="submit"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          className="butn w-100 rounded-pill border-0"
                        >
                          Apply Now
                        </button>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <!-- End Modal --> */}

            {/* <!-- Modal --> */}
            {props.user?.user_role.name === "User" && (
              <div
                className="modal fade "
                id="jobapply2"
                tabIndex="-1"
                aria-labelledby="createpostmodal"
                aria-hidden="true"
              >
                <div className="modal-dialog ">
                  <div className="modal-content border-0 rounded-10">
                    <div className="modal-header border-0 p-2">
                      <button
                        type="button"
                        className="modal-close bg-transparent border-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="/assets/img/icon/close-circle.svg" alt="" />
                      </button>
                    </div>

                    <div className="modal-body">
                      <h3 className="text-darkgray pb-3 text-center">
                        Applying For
                      </h3>
                      <form onSubmit={applyForJob}>
                        {kidsList.length > 0 && (
                          <React.Fragment>
                            <h4 className="semibold pb-2">Choose Athletes</h4>
                            <div className="mb-2">
                              {kidsList.map((kid, i) => {
                                return (
                                  <div
                                    className="rounded-3 bg-lightgreen p-2 d-flex align-items-center gap-2 mb-1"
                                    key={i}
                                  >
                                    <input
                                      type="radio"
                                      name="kidgroup"
                                      className="location-radio check-radio"
                                      value={kid.id}
                                      onChange={(e, kid) => {
                                        const item = kidsList.find(
                                          (x) => x.id == e.target.value
                                        );
                                        setKidId(e.target.value);
                                        setKidInfo(item);
                                      }}
                                      checked={kidId == kid.id}
                                    />
                                    {kid.id != "self" && (
                                      <React.Fragment>
                                        <img
                                          src={
                                            kid.image
                                              ? kid.image
                                              : "assets/img/edit-profile.png"
                                          }
                                          width="45"
                                          height="45"
                                          className="rounded-50 rounded-pill"
                                          alt=""
                                        />
                                        <div className="w-75">
                                          <h6 className="semibold">
                                            {kid.first_name} {kid.last_name}
                                          </h6>
                                          <h6 className="fs-11 text-lightgray regular">
                                            Age: {kid.age}yrs
                                          </h6>
                                        </div>
                                      </React.Fragment>
                                    )}
                                    {kid.id === "self" && (
                                      <React.Fragment>
                                        <img
                                          src={
                                            user.image
                                              ? user.image
                                              : "assets/img/edit-profile.png"
                                          }
                                          width="45"
                                          height="45"
                                          className="rounded-50 rounded-pill"
                                          alt=""
                                        />
                                        <div className="w-75">
                                          <h6 className="semibold">{kid.name}</h6>
                                        </div>
                                      </React.Fragment>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                        {!savedResume?.length <= 0 ? (
                          <>
                            <h5 className="text-darkgray fs-16 pb-2 semibold">
                              Saved Resume
                            </h5>
                            <div className="pb-4">
                              {savedResume?.map((item, index) => {

                                return (
                                  <div className="w-100 d-flex" key={index}>
                                    <input
                                      type="radio"
                                      name="resumegroup2"
                                      className="resume-radio check-radio align-self-center me-1"
                                      value={item?.file_name}
                                      onChange={(e, resume) => {
                                        const item = savedResume.find(
                                          (x) => x.id == e.target.value
                                        );
                                        setresumeName(e.target.value);
                                        setKidInfo(item);
                                      }}
                                      checked={resumeName == item?.file_name}
                                    />
                                    <h6 className="align-self-center w-20">
                                      {item?.file_display_name}
                                    </h6>
                                    <span className="align-self-center w-25 ms-2 pb-1">
                                      {" "}
                                      <Link>
                                        <img

                                          onClick={() =>
                                            removeUserResume(savedResume, index)
                                          }
                                          src="/assets/img/icon/trash-red.svg"
                                          alt=""
                                        />
                                      </Link>
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="form-field">
                          <label htmlFor="" className="pb-1">
                            Upload Resume
                          </label>
                          <h6>
                            {resumeData?.map((file, index) => {
                              return (
                                <div className="mb-1" key={index}>
                                  {file?.file?.name}
                                </div>
                              );
                            })}
                          </h6>
                          <input
                            name="myImage"
                            type="file"
                            onChange={(e) => onResume(e)}
                            className="d-none"
                            id="file"
                          // accept="image/*"
                          />
                          <label
                            htmlFor="file"
                            className="upload-file job-apply text-green w-100 rounded-8 d-inline-block fs-16 light text-center px-4"
                          >
                            <img
                              src="assets/img/icon/documentdownload.svg"
                              className="me-2"
                              alt=""
                            />
                            Upload file
                          </label>
                        </div>
                        <div className="form-field mb-3">
                          <label htmlFor="" className="pb-1">
                            Message
                          </label>
                          <textarea
                            rows="4"
                            cols="50"
                            className="border"
                            value={message}
                            onChange={(e) => {
                              setMessage(e.target.value)
                            }}
                          ></textarea>

                        </div>
                        <div className="d-flex align-items-center gap-2 py-2">
                          <input
                            type="checkbox"
                            id="01"
                            defaultChecked={false}
                            onChange={(e) => setIsSaveForFuture(e.target.checked)}
                          />
                          <label htmlFor="01">Save for future</label>
                        </div>
                        <button
                          type="submit"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          className="butn w-100 rounded-pill border-0"
                        >
                          Apply Now
                        </button>
                      </form>
                      {/* <form onSubmit={applyForJob} action="" className="site-form">
                  </form> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <!-- Modal --> */}
          </div>
          : ""}

        <MessageRequestModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        coachInfo={data?.user}
        handleSendMessage={handleSendMessage}
        messageDescription={messageDescription}
        setMsgDescription={setMsgDescription}
        />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, {})(JobDetails);
// export default JobDetails;
