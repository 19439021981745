import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import LocationAutocomplete from '../../Components/LocationAutocomplete';
import { connect } from "react-redux";
// import RangeSlider from 'react-range-slider-input';
// import 'react-range-slider-input/dist/style.css';
// import Slider from '@material-ui/core/Slider';
import CoachItem from "./CoachItem";
// import { makeStyles } from "@material-ui/core";
import { Slider } from "@mui/material";
// const useStyles = makeStyles({
//   valueLabel: {
//     '& span': {
//           backgroundColor: 'transparent'
//         },
    
//     backgroundColor: '#548235', 
//     color: 'white', 
//     paddingInline: '8px', 
//     borderRadius: '0px', 
//     // marginRight: '10px'
//   },
// });
function Coaches(props) {
  // const onLoad = onload();
  const locationRef = useRef(null);
  let history = useNavigate();
  const data = useLocation();
  const isFacilitie = data.pathname === '/facilities' ? true : false
  const [coachData, setCoachData] = useState([]);
  const [sportsData, setSportsData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [sportsId, setSportsId] = useState([]);
  const [genderInfo, setGenderInfo] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [starCategory, setStarCategory] = useState([]);
  const [radiusOne, setRadiusOneValue] = useState(0);
  const [radiusTwo, setRadiusTwoValue] = useState(50);
  const [radiusTwoCopy, setRadiusTwoCopy] = useState(50);
  const [priceOne, setPriceOneValue] = useState(0);
  const [priceTwo, setPriceTwoValue] = useState(0);
  const [priceOneCopy, setPriceOneCopy] = useState(0);
  const [priceTwoCopy, setPriceTwoCopy] = useState(0);
  const [salaryStaticTwo, setSalaryStaticTwoValue] = useState(0);
  const [oldRadiusOne, setOldRadiusOneValue] = useState(0);
  const [oldRadiusTwo, setOldRadiusTwoValue] = useState(50);
  const [oldPriceOne, setOldPriceOneValue] = useState(0);
  const [oldPriceTwo, setOldPriceTwoValue] = useState(0);
  const [value, setValue] =  React.useState([0,1000000]);
  const [locValue, setLocValue] =  React.useState([0,1000000]);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [add, setAdd] = useState('');
  const [isPrice, setIsPrice] = useState(false);
  const [isFilterCleared, setFilterCleared] = useState(true);
  // const classes = useStyles();

  const rangeSelector = (event, newValue) => {
    setValue(newValue);
  };
  const rangeSelector2 = (event, newValue) => {
    setFilterCleared(false);
    // console.log('new__', newValue)
    // console.log('new1__', newValue?.[0])
    // console.log('new2__', newValue?.[1])
    setPriceOneValue(newValue?.[0])
    setPriceTwoValue(newValue?.[1])
      // setWeightId(newValue);
  };

  const rangeSelectorLoc = (event, newValue) => {
    setLocValue(newValue);
  };
  const rangeSelectorLoc2 = (event, newValue) => {
    setFilterCleared(false);
    setRadiusOneValue(newValue?.[0])
    setRadiusTwoValue(newValue?.[1])
  };

  // console.log('pricetwoCopy__', priceTwoCopy);
  // console.log('pricetwo__', priceTwo);

  // console.log('priceoneCopy__', priceOneCopy);
  // console.log('priceone__', priceOne);

  // console.log('val__', value);


  const addRemoveStarCategory = (itemId, e) => {
    const newStarCategoryId = [...starCategory];
    const index = newStarCategoryId.indexOf(itemId);
    if (e && e.target && e.target.checked) {
      setFilterCleared(false);
      newStarCategoryId.push(itemId);
      setStarCategory(newStarCategoryId);
    }
    else {
      // setFilterCleared(true);
      newStarCategoryId.splice(index, 1);
      setStarCategory(newStarCategoryId);
    }
  }
  const addRemoveGender = (itemId, e) => {
    const newGenderId = [...genderInfo];
    const index = newGenderId.indexOf(itemId);
    if (e && e.target && e.target.checked) {
      setFilterCleared(false);
      newGenderId.push(itemId);
      setGenderInfo(newGenderId);
    }
    else {
      newGenderId.splice(index, 1);
      setGenderInfo(newGenderId);
    }
  }
  const addRemoveSports = (item, e, updateUrlRequired = true) => {
    const itemId = item.id;
    const newSportsId = [...sportsId];
    const index = newSportsId.indexOf(itemId);
    if (e && e.target && e.target.checked) {
      setFilterCleared(false);
      newSportsId.push(itemId);
      setSportsId(newSportsId);
    }
    else {
      // setFilterCleared(false);
      newSportsId.splice(index, 1);
      setSportsId(newSportsId);
    }
  }

  const CoachList = async (e, urlValue, lati, lngi, price2) => {
    // e.preventDefault();
    let obj = {
      //latitude: "12122",
      //longitude: "32242",
      latitude: lati ? lati : (lat ? lat : props.user.latitude),
      longitude: lngi ? lngi : (lng ? lng : props.user.longitude),
      radius_from: radiusOne,
      radius_to: radiusTwo,
      price_from: priceOne,
      price_to: parseInt(priceTwo) || parseInt(price2) || 0,
      radius: radiusTwo
    };
    if (sportsId?.length) {
      obj['sport_id'] = sportsId
    }
    if (genderInfo?.length) {
      obj['gender'] = genderInfo
    }
    if (starCategory?.length) {
      obj['star_category'] = starCategory
    }
    setCoachData([]);
    try {
      let resp = await FetchApi(urlValue ? urlValue : (isFacilitie ? Endpoints.getFacilities : Endpoints.coaches), obj);
      if (resp && resp.status === "success") {
        //ToastMessage.Success(resp.message);
        setCoachData(resp.data.data);
        setPageInfo(resp.data)
        setIsPrice(true);
        // setOldRadiusOneValue(radiusOne)
        // setOldRadiusTwoValue(radiusTwo)
        // setOldPriceOneValue(priceOne)
        // setOldPriceTwoValue(priceTwo)
        // setPrice(resp.data.data);
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const getSportsList = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.getSports);
      if (resp && resp.status === "success") {
        setSportsData(resp.data);
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const getFilterData = async (e) => {
    try {
      let postPrams = {}
      if (lat && props.user.latitude && props.user.latitude != lat) {
        postPrams['latitude'] = ''
        postPrams['longitude'] = ''
      }
      let resp = await FetchApi(Endpoints.getFiltersData + `/${isFacilitie ? 'facility' : 'coach'}?latitude=${lat && props.user.latitude && props.user.latitude != lat ? '' : props.user.latitude}&longitude=${lng && props.user.longitude && props.user.longitude != lng ? '' : props.user.longitude}`);
      if (resp && resp.status === "success") {
        // console.log('respfilter__', JSON.stringify(resp.data, null, 4));
        setPriceTwoValue(resp.data.price_to);
        setPriceTwoCopy(parseInt(resp.data.price_to));
        setPriceOneCopy(resp.data.price_from);
        setValue([0, parseInt(resp.data.price_to)])
        setIsPrice(true)
        if(parseInt(resp.data.price_to > 0)){
          // setIsPrice(true)
          CoachList(null, null, null, null, parseInt(resp.data.price_to))
        }
        else{
          setIsPrice(true)
        }
        setFilterData(resp.data);
        setOldPriceTwoValue(resp.data.price_to);
        setSalaryStaticTwoValue(resp.data.price_to);
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };

  const getFilterNewData = async (lat, long) => {
    try {
   
      let resp = await FetchApi(Endpoints.getFiltersData + `/${isFacilitie ? 'facility' : 'coach'}?latitude=${lat}&longitude=${long}`);
      if (resp && resp.status === "success") {
        setFilterData(resp.data);
        setPriceTwoValue(resp.data.price_to);
        setPriceTwoCopy(resp.data.price_to);
        setValue([0, resp.data.price_to])
        // setPriceOneCopy(resp.data.price_from);
      }
      
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const getFilterCount = (item, key) => {
    let return_value = 0;
    if (item === 'starCategory' && filterData.starCategory) {
      return_value = filterData.starCategory[key]
    }
    else if (item === 'sports' && filterData.sports) {
      const sItem = filterData.sports.find((i) => i.id === key.id);
      return_value = sItem ? sItem.total_used : 0
    }
    else if (item === 'gender' && filterData.gender) {
      return_value = filterData.gender[key]
    }
    return return_value
  }
  const clearFilters = () => {
    setFilterCleared(true);
    setSportsId([])
    setGenderInfo([])
    setStarCategory([])
    setRadiusOneValue(0)
    setRadiusTwoValue(50)
    setPriceOneValue(0)
    // setPriceTwoValue(40000)
    setOldRadiusOneValue(0)
    setOldRadiusTwoValue(50)
    setOldPriceOneValue(0);
    setPriceTwoValue(priceTwoCopy);
    setValue([0, priceTwoCopy]);
    setRadiusTwoValue(50)
    setLocValue([0, 50]);  
    // setOldPriceTwoValue(40000)
    setLat(null);
    setLng(null);
    getFilterData();
    locationRef.current.clearAddress();
  }
  const onLocationChanged = (data) => { 
    setLat(data.lat); 
    setLng(data.lng); 
    CoachList(null, null, data.lat, data.lng); 
    getFilterNewData(data.lat, data.lng);
  };
 
  useEffect(() => {
    if(isPrice){
      CoachList();
    }
  }, [isPrice, locationRef, sportsId, genderInfo, starCategory, parseInt(priceTwo), parseInt(priceOne), parseInt(radiusTwo), parseInt(radiusOne)])
  useEffect(() => {
    getFilterData();
    clearFilters();
  }, [isFacilitie])
  useEffect(() => {
    //CoachList();
    getSportsList();
  }, []);

  const handleMinus = (value) =>{
    if((parseInt(value) + 50) == parseInt(priceTwo)){
      setPriceOneValue(Math.min(value-50, priceTwo - 1))
    }else{
      setPriceOneValue(Math.min(value, priceTwo - 1))
    }
  };
  const hanldePlus = (value) =>{
    if((parseInt(value) - 50) == parseInt(priceOne)){
      setPriceTwoValue(Math.max(parseInt(value) + 50, priceOne - (-1)))
    }else{
      setPriceTwoValue(Math.max(value, priceOne - (-1)))
    }
  };

 
  return (
    <div className="main py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 listing-sidebar">
            <h3
              className="sidebar-title d-lg-none mb-3 butn"
              data-bs-toggle="dropdown"
            //  aria-expanded="false"
            >
              Filter{" "}
              <i className="fal fa-chevron-down text-white ps-2 fs-16"></i>
            </h3>

            <div className="dropdown-menu border-0 w-100 position-relative d-lg-block">
              <div className="d-flex align-items-center justify-content-between pb-3">
                <h4 className="medium">Filters</h4>
                {!isFilterCleared && 
                <a className="text-green c-p" onClick={(e) => {
                  e.preventDefault();
                  clearFilters()
                }}>Clear filters</a>}
              </div>

              <div className="border p-3 widget-box slider-box mb-3 rounded-8">
                <h5 className="medium pb-4">Price Range</h5>
                <Slider
                sx={{
                  '& .MuiSlider-valueLabel': {
                    color: 'white',
                    backgroundColor: '#548235'
                  },
                }}
                // classes={{
                //   valueLabel: classes.valueLabel,
                // }}
                    style={{ marginTop: '30px', color: '#548235' }}
                    value={value}
                    onChange={rangeSelector}
                    onChangeCommitted={rangeSelector2}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => `$${value?.toLocaleString("ja-JP")}`}
                    defaultValue={50}
                    min={0}
                    max={priceTwoCopy}
                    disabled={priceTwoCopy==0? true : false}
                    // color="#548235"
                />

                {/* <div className="range-slider salary-range position-relative">
                  <span className="output outputOne" 
                  style={{
                    left: `${(priceOne / parseInt(salaryStaticTwo)) * 100}%`,
                  }}
                  >${priceOne}</span>
                  <span className="output outputTwo" style={{
                    left: `${(priceTwo / parseInt(salaryStaticTwo)) * 100 }%`
                  }}>${priceTwo}</span>
                  <span className="full-range"></span>
                  <span className="incl-range" style={{
                    left: `${(priceOne /parseInt(salaryStaticTwo)) * 100}%`,
                    width: `${((parseInt(priceTwo) - parseInt(priceOne)) / parseInt(salaryStaticTwo)) * 100}%`
                  }}></span>
                
                  
                  <input 
                    id="rangeOne" 
                    onMouseLeave={(e) => {
                      if (oldPriceOne != priceOne) {
                        CoachList()
                      }
                    }} 
                    value={priceOne} 
                    min="0" 
                    max={salaryStaticTwo-50} 
                    step="1" 
                    type="range" 
                    onChange={(e) =>{
                      handleMinus(e.target.value)
                    }} 
                  />
                  <input 
                    id="rangeTwo" 
                    onMouseLeave={(e) => {
                      if (oldPriceTwo != priceTwo) {
                        CoachList()
                      }
                    }} 
                    value={priceTwo} 
                    min={priceOne} 
                    max={salaryStaticTwo} 
                    step="1" 
                    type="range" 
                    onChange={(e) => {
                      hanldePlus(e.target.value)
                    }} />
                </div> */}
              </div>

              <div className="border p-3 widget-box slider-box mb-3 rounded-8">
                <h5 className="medium pb-4">Location Radius</h5>
                <Slider
                sx={{
                  '& .MuiSlider-valueLabel': {
                    color: 'white',
                    backgroundColor: '#548235'
                  },
                }}
                    style={{ marginTop: '30px', color: '#548235' }}
                    value={locValue}
                    onChange={rangeSelectorLoc}
                    onChangeCommitted={rangeSelectorLoc2}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => `${value}Km`}
                    defaultValue={50}
                    min={0}
                    max={radiusTwoCopy}
                    disabled={radiusTwoCopy==0? true : false}
                />
                {/* <div className="range-slider radius-slider position-relative">
                  <span className="output radiusOne" style={{
                    left: `${radiusOne}%`
                  }}>{radiusOne}Km</span>
                  <span className="output radiusTwo" style={{
                    left: `${radiusTwo}%`
                  }}>{radiusTwo}Km</span>
                  <span className="full-range"></span>
                  <span className="incl-radius" style={{
                    left: `${radiusOne}%`,
                    width: `${(parseInt(radiusTwo) - parseInt(radiusOne))}%`
                  }}></span>
                  <input className="radiusOne" id="radiusOne" onMouseLeave={(e) => {
                    if (oldRadiusOne != radiusOne) {
                      CoachList()
                    }
                  }} 
                  value={radiusOne} 
                  min="0" 
                  max="100" 
                  step="1" 
                  type="range" 
                  onChange={(e) => setRadiusOneValue(Math.min(e.target.value, radiusTwo - 1))} />
                  <input className="radiusTwo" id="radiusTwo" onMouseLeave={(e) => {
                    if (oldRadiusTwo != radiusTwo) {
                      CoachList()
                    }
                  }} value={radiusTwo} 
                  min="0" 
                  max="100" 
                  step="1" 
                  type="range" 
                  onChange={(e) => setRadiusTwoValue(Math.max(e.target.value, radiusOne - (-1) ))} />
                </div> */}
              </div>

              <div className="border p-3 widget-box mb-3 rounded-8">
                <h5 className="text-black medium pb-3">Star Category</h5>
                <div className="d-flex align-items-center gap-3 pb-2 ">
                  <input type="checkbox" id="cat6" checked={starCategory.includes('6')}
                    onChange={(e) => {
                      addRemoveStarCategory('6', e);
                    }} />
                  <label
                    htmlFor="cat6"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    Unrated <span>{getFilterCount('starCategory', 'reviews_rating_6')}</span>
                  </label>
                </div>
                
                <div className="d-flex align-items-center gap-3 pb-2">
                  <input type="checkbox" id="cat5" checked={starCategory.includes('5')}
                    onChange={(e) => {
                      addRemoveStarCategory('5', e);
                    }} />
                  <label
                    htmlFor="cat5"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    5 Star <span>{getFilterCount('starCategory', 'reviews_rating_5')}</span>
                  </label>
                </div>

                <div className="d-flex align-items-center gap-3 pb-2">
                  <input type="checkbox" id="cat4" checked={starCategory.includes('4')}
                    onChange={(e) => {
                      addRemoveStarCategory('4', e);
                    }} />
                  <label
                    htmlFor="cat4"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    4 Star <span>{getFilterCount('starCategory', 'reviews_rating_4')}</span>
                  </label>
                </div>
                
                <div className="d-flex align-items-center gap-3 pb-2">
                  <input type="checkbox" id="cat3" checked={starCategory.includes('3')}
                    onChange={(e) => {
                      addRemoveStarCategory('3', e);
                    }} />
                  <label
                    htmlFor="cat3"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    3 Star <span>{getFilterCount('starCategory', 'reviews_rating_3')}</span>
                  </label>
                </div>

                <div className="d-flex align-items-center gap-3 pb-2">
                  <input type="checkbox" id="cat2" checked={starCategory.includes('2')}
                    onChange={(e) => {
                      addRemoveStarCategory('2', e);
                    }} />
                  <label
                    htmlFor="cat2"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    2 Star <span>{getFilterCount('starCategory', 'reviews_rating_2')}</span>
                  </label>
                </div>

                <div className="d-flex align-items-center gap-3 pb-2">
                  <input type="checkbox" id="cat1" checked={starCategory.includes('1')}
                    onChange={(e) => {
                      addRemoveStarCategory('1', e);
                    }} />
                  <label
                    htmlFor="cat1"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    1 Star <span>{getFilterCount('starCategory', 'reviews_rating_1')}</span>
                  </label>
                </div>
               
               
               
                {/* <div className="d-flex align-items-center gap-3 ">
                  <input type="checkbox" id="cat6" checked={starCategory.includes('6')}
                    onChange={(e) => {
                      addRemoveStarCategory('6', e);
                    }} />
                  <label
                    htmlFor="cat6"
                    className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                  >
                    Unrated <span>{getFilterCount('starCategory', 'reviews_rating_6')}</span>
                  </label>
                </div> */}
              </div>

              <div className="border p-3 widget-box mb-3 rounded-8">
                <h5 className="text-black medium pb-3">Sports Category</h5>
                {sportsData.map((ite, iIndex, itemArray) => {
                  return (
                    <div className="d-flex align-items-center gap-3 pb-2" key={iIndex}>
                      <input type="checkbox" id={'sports' + iIndex} checked={sportsId.includes(ite.id)}
                        onChange={(e) => {
                          addRemoveSports(ite, e);
                        }} />
                      <label
                        htmlFor={'sports' + iIndex}
                        className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                      >
                        {ite.name} <span>{getFilterCount('sports', ite)}</span>
                      </label>
                    </div>
                  )
                })
                }

                {/* <Link className="text-green">Show All</Link> */}
              </div>
              {isFacilitie === false &&
                <div className="border p-3 widget-box mb-3 rounded-8">
                  <h5 className="text-black medium pb-3">Gender</h5>

                  <div className="d-flex align-items-center gap-3 pb-2">
                    <input type="checkbox" id="gender1" checked={genderInfo.includes('male')}
                      onChange={(e) => {
                        addRemoveGender('male', e);
                      }} />
                    <label
                      htmlFor="gender1"
                      className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                    >
                      Male <span>{getFilterCount('gender', 'male')}</span>
                    </label>
                  </div>

                  <div className="d-flex align-items-center gap-3 pb-2">
                    <input type="checkbox" id="gender2" checked={genderInfo.includes('female')}
                      onChange={(e) => {
                        addRemoveGender('female', e);
                      }} />
                    <label
                      htmlFor="gender2"
                      className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                    >
                      Female <span>{getFilterCount('gender', 'female')}</span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center gap-3 ">
                    <input type="checkbox" id="gender3" checked={genderInfo.includes('any')}
                      onChange={(e) => {
                        addRemoveGender('any', e);
                      }} />
                    <label
                      htmlFor="gender3"
                      className="w-100 d-flex align-items-center justify-content-between text-gray fs-16"
                    >
                      Any <span>{getFilterCount('gender', 'any')}</span>
                    </label>
                  </div>
                </div>
              }
            </div>
          </div>

          <div className="col-lg-9">
            <div className="d-md-flex align-items-center gap-2 pb-3">
              <h1 className="medium fs-24 pb-2 pb-md-0">
                {isFacilitie ? 'Facilities' : 'Coaches'} in Your Location
              </h1>
              <div className="border rounded-3 px-3 d-flex py-2 search-location-input">
                <LocationAutocomplete ref={locationRef} onLocationChanged={onLocationChanged} address={add}></LocationAutocomplete>
              </div>
            </div>

            <div className="row m-0 gx-0">
              {
              coachData.length > 0 ?
              coachData.map((item, index) => {
              
                return (
                  <CoachItem {...{
                    item:item,
                    index:index,
                    isFacilitie:isFacilitie
                  }} />
                );
              })
             : ""
            }
              {coachData.length === 0 &&
                <div className="col-xl-12 col-lg-12  col-md-12 col-sm-12  mb-3 pe-3">
                  No {isFacilitie ? 'facilities' : 'coaches'} availabile for this filters.
                </div>
              }
            </div>
            {pageInfo?.total > 12 && <div className="paginations pt-2 pb-3">
              <span className="me-2">Page 1 of {Math.ceil(pageInfo.total / pageInfo.per_page)}</span>
              {pageInfo.links.map((item, i) => {
                return (item.url ? (<React.Fragment key={i}>
                  <Link className={item.active ? 'page-active' : ''} onClick={(e) => {
                    e.preventDefault();
                    CoachList(e, item.url)
                  }}><span dangerouslySetInnerHTML={{ __html: item.label }}></span></Link>
                </React.Fragment>) : null)
              })}
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(Coaches);
