import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
// StripePaymentForm from "./StripePaymentForm";
import { connect } from "react-redux";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import CreateReferAvailability from "../../Components/CreateReferAvail";
import ToastMessage from "../../Utils/ToastMessage";
import Loader from "../../Components/loader";
import { stripePaymentMethodHandler } from "./script";

const oneCent = 0.01
function BookingSummary(props) {
  const data = useLocation();
  const history = useNavigate();

  const params = useParams();
  const [userDetail, setUserDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState({});
  const [userAvailablity, setUserAvailability] = useState([]);
  const [user, setUserInfo] = useState(props.user)
  const [bookingInfo, setCoachInfo] = useState(data.state)
  const [rewardAmount, setRewardAmount] = useState(user.total_reward > 0 ? user.total_reward * oneCent : 0)
  // const [taxAmount, setTaxAmount] = useState(parseFloat(((5 * bookingInfo?.coachData?.profileData?.price) / 100).toFixed(2)));
  // const [priceAmount, setPriceAmt] = useState((bookingInfo?.coachData?.profileData?.price) + taxAmount);
  const coachData = JSON?.parse(localStorage.getItem('coachData'));
  const eventData = JSON?.parse(localStorage.getItem('eventData'));
  const local_slug = localStorage?.getItem('slug_local');
  const [taxAmount, setTaxAmount] = useState(0
  //   parseFloat(((5 * 
  //   // (eventData?.facility_fully_fee || eventData?.coach_fee)
  //   (userDetail?.user_role?.id != 2 && !eventData?.selected_amenity?
  //   ((eventData?.facility_fully_fee || eventData?.coach_fee))
  //   :
  //   (eventData?.facility_fully_fee || eventData?.athlete_fee))
  // ) / 100).toFixed(2))
  );
  const [priceAmount, setPriceAmt] = useState(
    // (eventData?.facility_fully_fee || eventData?.coach_fee) 
    (userDetail?.user_role?.id != 2 && !eventData?.selected_amenity)?
    ((eventData?.facility_fully_fee || eventData?.coach_fee) + taxAmount)
    :
    ((eventData?.facility_fully_fee || 
      (userDetail?.user_role?.id == 3?
        eventData?.coach_fee
        : 
        eventData?.athlete_fee)
    )
    + taxAmount)
    );

  // console.log('evedt__', JSON.stringify(eventData, null, 4));
  // console.log('eventdt__', eventData);
  // console.log('selAva_', selectedAvailability)

  const [is_used_reward_checked, setIs_used_reward_checked] = useState(false)
  const getPercent = (percentage, amount) => {
    return percentage * amount / 100;
  }

  const handleResp = (resp) =>{
    console.log('respStripeAPI__', resp);
    const dataState = { ...data.state }
    // const dataState = {}
    dataState['is_used_reward_checked'] = is_used_reward_checked
    dataState['taxAmount'] = taxAmount
    dataState['priceAmount'] = priceAmount
    dataState['isReschedulBooking'] = bookingInfo?.isReschedulBooking  ? true : false
    dataState['bookingId'] = bookingInfo?.bookingId ? bookingInfo?.bookingId : null;
    dataState['is_show_reservation'] = data?.state?.is_show_reservation;
    dataState['client_secret'] = resp?.client_secret;
    if(resp?.client_secret){
      history("/stripe-payment-form", { state: dataState, replace: true });
    }
  }
  const navigatePayment = (e) => {
    e.preventDefault();
    stripePaymentMethodHandler({
      // result: paymentMethodResult,
      amount: parseFloat(priceAmount?.toFixed(2))
    }, handleResp)

    // const dataState = { ...data.state }
    // // const dataState = {}
    // dataState['is_used_reward_checked'] = is_used_reward_checked
    // dataState['taxAmount'] = taxAmount
    // dataState['priceAmount'] = priceAmount
    // dataState['isReschedulBooking'] = bookingInfo?.isReschedulBooking  ? true : false
    // dataState['bookingId'] = bookingInfo?.bookingId ? bookingInfo?.bookingId : null;
    // dataState['is_show_reservation'] = data?.state?.is_show_reservation;
    // history("/stripe-payment-form", { state: dataState, replace: true });
  }
  
  
  const getUserDetail = async () => {
		try {
			let resp = await FetchApi(Endpoints.userDetail, {});
			if (resp && resp.type === 'success') {
                setUserDetail(resp?.data);
                if((resp?.data?.user_role?.id != 2 && !eventData?.selected_amenity))
                  setPriceAmt((eventData?.facility_fully_fee || eventData?.coach_fee) + taxAmount)
                else{
                  setPriceAmt((eventData?.facility_fully_fee || 
                    (resp?.data?.user_role?.id == 3?
                      eventData?.coach_fee
                      : 
                      eventData?.athlete_fee)
                  ) + taxAmount)
                }
			}
		} catch (e) {
		}
	};

  const areObjectsEqual = (obj1, obj2) => {
    
    for (const key in obj1) {
      if (typeof obj1[key] != 'object' && typeof obj2[key] != 'object') {
      //   if (!areObjectsEqual(obj1[key], obj2[key])) {
      //     return false;
      //   }
      // } else {
        if (obj1[key] !== obj2[key]) {
          console?.log('checkinner__', obj1[key])
          return false;
        }
      }
    }
  
    return true;
  };


  const getAvailability = async (e) => {
    try {
      setIsLoading(true);
      let resp = await FetchApi(Endpoints.getUserAvailability, { user_slug: local_slug || data?.state?.slug });
      if (resp && resp.type === "success") {
        setUserAvailability(resp?.data);
        compareData(e, resp?.data);
      }
    }
    catch (e) {
      setIsLoading(false);
    }
  }

  const getEventSegments = (event) => {
    const segments = [];
    let currentStart = moment(event.start);
    const endDateTime = moment(event.end);

    const differenceInDays = endDateTime.diff(currentStart, 'days');

    const startTime = moment(event?.start);
      const endTime = moment(event?.end);

    const repeatDays = event?.repeat_days?.split(',')?.map(day => day?.trim()?.toLowerCase());

    while (currentStart.isBefore(endDateTime)) {
      if (event?.recurrences=="weekly" && 
        repeatDays?.includes?.(currentStart?.format('ddd')?.toLowerCase())
        && differenceInDays>7
      ) {
      const nextEnd = currentStart.clone().endOf('day');
      segments.push({
        ...event,
              start: currentStart.clone().set({
                hour: startTime.hour(),
                minute: startTime.minute(),
                second: startTime.second(),
              }).toDate(),
              end: currentStart.clone().set({
                hour: endTime.hour(),
                minute: endTime.minute(),
                second: endTime.second(),
              }).toDate(),
        
      });
      }
      else if (event?.recurrences!="weekly" || differenceInDays<8){
        const nextEnd = currentStart.clone().endOf('day');
        segments.push({
          ...event,
          start: currentStart.clone().set({
            hour: startTime.hour(),
            minute: startTime.minute(),
            second: startTime.second(),
          }).toDate(),
          end: currentStart.clone().set({
            hour: endTime.hour(),
            minute: endTime.minute(),
            second: endTime.second(),
          }).toDate(),
          
        });
      }
      currentStart = currentStart.clone().add(1, 'day').startOf('day');
    }
    return segments;
  };

  const updateBookingCounts = (segments, userBookings) => {
    return segments.map(segment => {
      let coachBookingCount = 0;
      let athleteBookingCount = 0;
      let booked = false;
  
      userBookings.forEach(booking => {
        if (booking.availability_date === moment(segment.start).format('YYYY-MM-DD')) {
          if(segment?.slot_type == "fully" && !booking?.cancelled_at){
            booked = true
          }
          if (booking.user.user_role?.id == 3 && !booking?.cancelled_at) {
            coachBookingCount++;
          } else if (booking.user.user_role?.id == 2 && !booking?.cancelled_at) {
            athleteBookingCount++;
          }
        }
      });

      if(segment?.confirmed_refer_availabilities?.length>0){
        // console.log('check__', segment?.confirmed_refer_availabilities?.length)
        coachBookingCount = coachBookingCount + segment?.confirmed_refer_availabilities?.length;
      }
  
      return {
        ...segment,
        coach_booking_count: coachBookingCount,
        athelete_booking_count: athleteBookingCount,
        is_booked: booked
      };
    });
  };


  const normalizeDate = (date) => {
    if (date instanceof Date) {
      return date.toISOString();
    }
    return new Date(date).toISOString();
  };

  const normalizeObjectDates = (obj) => {
    // console.log('obbbb____', obj)
    const newObj = { ...obj };
    delete newObj?.user_bookings
    for (const key in newObj) {
      if (['start', 'end'].includes(key)) { 
        newObj[key] = normalizeDate(newObj[key]);
      }
    }
    return newObj;
  };

  const removeBookingsArr = (obj) => {
    const newObj = { ...obj };
    delete newObj?.user_bookings
    
    return newObj;
  };


  const compareData = (e, data) => {
    // setIsLoading(true);
      const formattedData =
        data?.map((item) => ({
            title: item?.event_name,
            start: new Date(`${item?.slot_start_date}T${item?.slot_start_time}.0000000`),
            end: new Date(`${item?.slot_end_date}T${item?.slot_end_date_time}.0000000`),
            id: item?.id,
            ...item
        }));

    const segmentedEvents = formattedData?.flatMap((event) => getEventSegments(event));

    const segmentedEventsWithBookingCounts = segmentedEvents.flatMap(segment => 
      updateBookingCounts([segment], segment.user_bookings)
    );
    let new_dt = segmentedEventsWithBookingCounts?.filter((item)=> ((item?.id == eventData?.id) && (new Date(item?.start).toISOString() == eventData?.start)))?.[0];
    // console.log('nedt__', JSON.stringify(normalizeObjectDates(new_dt), null,4))
    // console.log('evdt__', JSON.stringify(removeBookingsArr(eventData), null,4))
    // const areEqual = removeBookingsArr(eventData) === normalizeObjectDates(new_dt);
    // console.log('nedt__', normalizeObjectDates(new_dt))
    // console.log('segdt__', segmentedEventsWithBookingCounts)
    // console.log('evdt__', removeBookingsArr(eventData))

    const areEqual = areObjectsEqual(removeBookingsArr(eventData), normalizeObjectDates(new_dt));
    // console.log('arequ__', areEqual)
    if(areEqual){
      setIsLoading(false);
      navigatePayment(e)
    }
    else{
      setIsLoading(false);
      ToastMessage.Error('Slot data has been updated. Please restart the process from the calendar');
      history(-1);
    }
  }

    useEffect(()=>{
        getUserDetail();
        // if(eventData){
          // getAvailability();
        // }
    },[]);
  return (
    <div className="main py-lg-5 py-4 align-items-start">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-8 pb-md-0 pb-4">
            <h1 className="fs-24 medium pb-3">Booking Summary</h1>
            <div className="border rounded-8 p-3 d-flex gap-md-3 gap-2 booking-box">
              <div>
              <div style={{
                width: '80px',
                height: '80px', 
              }}>
              <img
                src={
                  coachData?.image || "../assets/img/coach-detail.png"
                  // bookingInfo?.coachData?.image
                  //   ? bookingInfo?.coachData?.image
                  //   : "../assets/img/coach-detail.png"
                }
                style={{
                  width: '100%',
                  height: '100%', 
                  objectFit: 'cover',
                  borderRadius: '50%'
                }}
                // width="80"
                // height="80"
                // className="rounded-pill dp-border booking-summ-img"
                alt=""
              />
              </div>
              </div>
              <div className="d-lg-flex justify-content-between w-100">
                <div className="w-85">
                  <h5 className="medium pb-1">
                    {/* {bookingInfo?.coachData?.name} */}
                    {coachData?.name}
                    </h5>
                  <h6 className="light text-lightgray d-flex gap-2 align-content-center pb-3">
                    {/* Football Coach */}
                    {coachData?.facility_selected_amenity?.[0]?.amenity || ""}
                    <span className="d-inline-flex">
                      {/* <img
                        src="../assets/img/icon/star-yellow.svg"
                        width="14"
                        alt=""
                      /> */}
                       <ReactStars
                          count={5}
                          onChange={(e) => { }}
                          size={20}
                          isHalf={true}
                          edit={false}
                          value={coachData?.reviews_rating ? parseFloat(coachData?.reviews_rating) : 0}
                          emptyIcon={<i className="far fa-star"></i>}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          activeColor="#ffd700"
                        />
                    </span>
                  </h6>

                  <span className="fs-14 regular text-lightgray d-block pb-3">
                    Date{" "}
                    <span className="text-black semibold">
                      {/* {bookingInfo?.date + " "} */}
                      {moment(eventData?.start)?.format('DD MMM YYYY')}
                    </span>{" "}
                    Time{" "}
                    <span className="text-black semibold">
                      {/* {bookingInfo?.slot?.start} to {bookingInfo?.slot?.end} */}
                      {moment(eventData?.start)?.format('HH:mm')} 
                      {" to "} {moment(eventData?.end)?.format('HH:mm')}
                    </span>
                  </span>

                  <h5 className="medium pb-1">
                    {/* {bookingInfo?.coachData?.about} */}
                    {coachData?.about}
                  </h5>

                  {coachData?.address &&
                  <p className="fs-13 ">
                    <i className="fa fa-map-marker-alt text-green pe-1 fs-16"></i>
                    {/* {bookingInfo?.location?.location_name} */}
                    {coachData?.address + ", " + coachData?.state + ", " + coachData?.country}
                  </p>}
                  {bookingInfo?.kidInfo && <React.Fragment>
                    <div className="d-flex gap-2">
                      <span>For</span> <div className="rounded-3 bg-lightgreen p-2 d-flex align-items-center gap-2 mb-1">
                        <img
                          src={bookingInfo?.kidInfo?.image ? bookingInfo?.kidInfo?.image : '../assets/img/edit-profile.png'}
                          width="45"
                          height="45"
                          className="rounded-pill"
                          alt=""
                        />
                        <div className="w-75">
                          <h6 className="semibold">{bookingInfo?.kidInfo?.first_name} {bookingInfo?.kidInfo?.last_name}</h6>
                          <h6 className="fs-11 text-lightgray regular">Age: {bookingInfo?.kidInfo?.age}yrs</h6>
                        </div>
                      </div>
                    </div>

                  </React.Fragment>}
                </div>

                <div className="mt-lg-5 mt-3">
                  <h4 className="fs-20 text-green semibold">
                    {/* ${bookingInfo?.coachData?.profileData?.price?.toLocaleString("ja-JP")}/ */}
                    ${
                    userDetail?.user_role?.id != 2 && !eventData?.selected_amenity?
                    ((eventData?.facility_fully_fee || eventData?.coach_fee)?.toLocaleString("ja-JP"))
                    :
                    (
                      (eventData?.facility_fully_fee? eventData?.facility_fully_fee?.toLocaleString("ja-JP") : '') || 
                    (userDetail?.user_role?.id == 3?
                      eventData?.coach_fee?.toLocaleString("ja-JP")
                      : 
                      eventData?.athlete_fee?.toLocaleString("ja-JP"))
                    )
                    }/
                    <span className="text-lightgray regular fs-14">
                      per slot
                    </span>

                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <h3 className="medium pb-3">Price Summary</h3>
            <div className="border rounded-8 p-3">
              <div className="d-flex align-items-center justify-content-between pb-3">
                <span className="fs-14 semibold">Price</span>
                <span className="fs-16 semibold"> ${
                // bookingInfo?.coachData?.profileData?.price?.toLocaleString("ja-JP")
                userDetail?.user_role?.id != 2 && !eventData?.selected_amenity?
                  ((eventData?.facility_fully_fee || eventData?.coach_fee)?.toLocaleString("ja-JP"))
                  :
                  (
                    (eventData?.facility_fully_fee? eventData?.facility_fully_fee?.toLocaleString("ja-JP") : '') || 
                  // eventData?.athlete_fee?.toLocaleString("ja-JP")
                  (userDetail?.user_role?.id == 3?
                    eventData?.coach_fee?.toLocaleString("ja-JP")
                    : 
                    eventData?.athlete_fee?.toLocaleString("ja-JP"))
                )
              }</span>
              </div>

              <div className="d-flex align-items-center justify-content-between pb-3">
                <span className="fs-14 semibold">Tax</span>
                <span className="fs-16 semibold"> ${taxAmount.toLocaleString("ja-JP")}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between ">
                <span className="fs-14 semibold text-green d-flex gap-2 align-items-center">
                  <input type="checkbox" name="is_used_reward_checked" onChange={(e) => {
                    const isChecked = e.target.checked;
                    setIs_used_reward_checked(isChecked);
                    const basePrice = 
                    // bookingInfo?.coachData?.profileData?.price;
                    userDetail?.user_role?.id != 2 && !eventData?.selected_amenity?
                    ((eventData?.facility_fully_fee || eventData?.coach_fee))
                    :
                    (eventData?.facility_fully_fee || 
                    (userDetail?.user_role?.id == 3?
                      eventData?.coach_fee
                      : 
                      eventData?.athlete_fee)
                    )
                    if (isChecked) {
                      const updatedPrice = basePrice - rewardAmount
                      const taxAmount = getPercent(5, updatedPrice)
                      // setTaxAmount(parseFloat(taxAmount.toFixed(2)));
                      // setPriceAmt(updatedPrice + taxAmount);
                      setPriceAmt(updatedPrice); //added later
                    }
                    else {
                      const taxAmount = getPercent(5, basePrice)
                      // setTaxAmount(parseFloat(taxAmount.toFixed(2)));
                      // setPriceAmt(basePrice + taxAmount);
                      setPriceAmt(basePrice); //added later
                    }
                  }
                  } checked={is_used_reward_checked} id="" value={is_used_reward_checked} />
                  Use Reward Points (Available {user.total_reward})
                </span>
                <span className="fs-16 semibold"> -${rewardAmount.toFixed(2).toLocaleString("ja-JP")}</span>
              </div>
            </div>

            <div className="border rounded-8 p-3 my-2">
              <div className="d-flex align-items-center justify-content-between ">
                <span className="fs-14 semibold">Total Amount</span>
                <span className="fs-20 semibold text-green"> ${(priceAmount.toLocaleString("ja-JP"))}</span>
              </div>
            </div>

            <Link onClick={(e) => {
              // navigatePayment(e)
              getAvailability(e);
              // compareData(e);
            }} className="butn w-100 mt-2">
              {isLoading? <Loader isButton={true}/> : "Make Payment"}
            </Link>

            <Link 
            onClick={()=> history(-1)}
            // to={bookingInfo?.isFacilite ? "/facilities-details/"+bookingInfo?.coachData?.slug : '/coach-details/'+bookingInfo?.coachData?.slug}  
            className="text-green d-block text-center pt-3">Back</Link>
          </div>
        </div>
      </div>
      {/* <CreateReferAvailability
      modalIsOpen={true}
      // setModalIsOpen={setModalIsOpen}
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, {})(BookingSummary);
