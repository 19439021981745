import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Swal from "sweetalert2";
const $ = window.jQuery;

const ManageJobs = () => {
  const [jobList, setJobList] = useState([]);
  const [jobPagination, setJobPagination] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterData, setFilterData] = useState({
    data_for: "facility",
  });

  const getJobListing = async () => {
    try {
      let resp = await CommonAPi(Endpoints.getJobListing, filterData);

      if (resp && resp.status === "success") {
       
        setJobPagination(resp?.data);
        setJobList(resp?.data?.data);
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));
    }
  };

  const changeJobStatus = async () => {
    let obj = {
      job_id: selectedId,
      status: selectedItem === "active" ? "inactive" : "active",
    };

    try {
      let resp = await CommonAPi(Endpoints.changeJobStatus, obj);

      if (resp && resp.status === "success") {
        const updatedJob = resp.data;
        // Finding the index of the updated job in the jobList array
        const updatedIndex = jobList.findIndex(
          (job) => job.id === updatedJob.id
        );
        if (updatedIndex !== -1) {
          // Create a new jobList array with the updated job
          const newJobList = [...jobList];
          newJobList[updatedIndex] = updatedJob;
          setJobList(newJobList);
        }
      }
      if (resp && resp.status === "false") {
        ToastMessage.Error(resp.message);
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));
    }
  };

  const deleteJob = async (i, listItem) => {
    let jobListInState = [...listItem];
    const selectedItem = jobList[i];

    if (selectedItem?.id) {
      try {
        const postData = { id: selectedItem.id };

        let resp = await CommonAPi(Endpoints.deleteJob, postData);
        if (resp && resp.status === "success") {
          jobListInState.splice(i, 1);
          setJobList(jobListInState);
        }
        if (resp && resp.message && resp.message.email) {
          Swal.fire({
            text: "This email is not exist",
            icon: "error",
          });
        }
      } catch (e) {
        if (e && e.response && e.response.message) {
          Swal.fire({
            text: "Failed to delete the post",
            icon: "error",
          });
        }
      }
    } else {
      jobListInState.splice(i, 1);
      setJobList(jobListInState);
    }
  };

  useEffect(() => {
    getJobListing();
  }, []);

  return (
    <div className="wrapper">
      <div className="main py-5">
        <div className="container">
          <h1 className="fs-24 medium pb-2">
            Manage Jobs
            <Link to={"/job-create"} className="ms-2 butn py-2 ">
              Add Job
            </Link>
          </h1>
          {/* <div className="job-table-container"> */}
          <table className="job-table w-100">
            <thead>
              <tr className="fs-14 text-green table-head bg-lightgreen">
                <th>Jobs</th>
                <th>Date</th>
                <th>Applicants</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
              jobList.length > 0 ?
              jobList?.map((item, index) => {
                return (
                  <tr key={index} className="border">
                    <td>
                      <h5 className="medium pb-2">{item?.job_title}</h5>
                      <h6 className="medium ">
                        <span className="text-lightgray regular">Salary:</span>{" "}
                        ${item?.salary_from.toLocaleString("ja-JP")} - ${item?.salary_to.toLocaleString("ja-JP")}{" "}
                        {item?.salary_type === "monthly" ? "month" : item?.salary_type}
                      </h6>
                    </td>

                    <td>
                      <h6 className="medium pb-2 ">
                        <span className="text-lightgray regular">
                          Posted on:
                        </span>{" "}
                        {item?.created_at.substring(0, 10)}
                      </h6>

                      <h6 className="medium">
                        <span className="text-lightgray regular">
                          Expired on:
                        </span>{" "}
                        {item?.job_expire_date}
                      </h6>
                    </td>

                    <td>
                      <Link to={"/job-application/"+ item?.id}>
                    
                      <h6 className="medium">

                        {item?.totalJobApplicant} Applicants
                      </h6>
                      </Link>
                    </td>

                    <td>
                      {(item?.status === "active" || item?.status === "inactive") && new Date(item?.job_expire_date) > new Date()  ? (
                        <Link
                          className={
                            item?.status === "active"
                              ? "medium  text-green"
                              : "medium text-orange"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedId(item?.id);
                            setSelectedItem(item?.status);
                            $("#active").modal("show");
                          }}
                        >
                          {item.status === "active" ? "Active" : "Inactive"}
                        </Link>
                      ) : (
                        <Link className={"medium text-danger"}>Expired</Link>
                      )}
                      {/* <Link
                        className={
                          item?.status === "active"
                            ? "medium  text-green"
                            : item?.status === "expired"
                            ? "medium text-red"
                            : "medium text-orange"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedId(item?.id);
                          setSelectedItem(item?.status);
                          $("#active").modal("show");
                        }}
                      >
                        {item.status === "active"
                          ? "Active"
                          : item?.status === "expired"
                          ? "Expired"
                          : "Inactive"}
                      </Link> */}
                    </td>

                    <td>
                      <div className="d-flex gap-2">
                        <Link
                          to={`/job-view/${item?.id}`}
                          className="job-action-btn"
                        >
                          <img src="assets/img/icon/eye.svg" className="" />
                        </Link>
                        <Link
                          to={`/update-job/${item?.id}`}
                          className="job-action-btn"
                        >
                          <img
                            src="assets/img/icon/edit-2.svg"
                            className="icon-green"
                          />
                        </Link>
                        <Link
                          data-bs-toggle="modal"
                          data-bs-target="#deletejob"
                          className="job-action-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedId(index);
                          }}
                        >
                          <img
                            src="assets/img/icon/trash.svg"
                            className=" icon-red"
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })
             : 
             <tr>
              <td>No job added</td>
             </tr>
            }
            </tbody>
          </table>
          {/* </div> */}

          {jobPagination.length > 0 && (
            <div className="paginations text-start py-3">
              <span className="me-2">
                Page 1 of{" "}
                {Math.ceil(jobPagination.total / jobPagination.per_page)}
              </span>
              {jobPagination.links.map((item, i) => {
                return item.url ? (
                  <React.Fragment key={i}>
                    <Link
                      className={item.active ? "page-active" : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        getJobListing(item.url);
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: item.label }}
                      ></span>
                    </Link>
                  </React.Fragment>
                ) : null;
              })}
            </div>
          )}

          {/* <div className="paginations text-start py-3">
            <span className="me-1 fs-15 text-gray12">page 1 of 30</span>
            <a href="#" className="prev-btn">
              <i className="fa fa-arrow-left pe-2"></i> Previous
            </a>
            <a href="#" className="page-active">
              01
            </a>
            <a href="#" className="">
              02
            </a>
            <a href="#" className="">
              03
            </a>
            <a href="#" className="">
              04
            </a>
            <a href="#" className="">
              05
            </a>
            <a href="#" className="next-btn btn-active">
              Next <i className="fa fa-arrow-right ps-2"></i>
            </a>
          </div> */}
        </div>

        {/* <!-- Modal --> */}

        <div
          className="modal fade"
          id="active"
          tabIndex="-1"
          aria-labelledby="sharepostmodal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Status Inactive/Active</h5>
                <button
                  type="button"
                  className="modal-close bg-transparent border-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="assets/img/icon/close-circle.svg" alt="" />
                </button>
              </div>
              <div className="modal-body">
                Are you sure you want to change the status from {selectedItem}{" "}
                to {selectedItem === "active" ? "inactive" : "active"} ?{" "}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={changeJobStatus}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}

        {/* <!-- Modal --> */}
        <div
          className="modal fade "
          id="deletejob"
          tabIndex="-1"
          aria-labelledby="createpostmodal"
          aria-hidden="true"
        >
          <div className="modal-dialog ">
            <div className="modal-content border-0 rounded-10">
              <div className="modal-header border-0 p-2">
                <button
                  type="button"
                  className="modal-close bg-transparent border-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="assets/img/icon/close-circle.svg" alt="" />
                </button>
              </div>

              <div className="modal-body">
                <h3 className="text-darkgray pb-3 text-center">Delete Job</h3>

                <div className="d-flex gap-2 pb-4 align-items-center">
                  <h6>Do you want to delete selected job?</h6>
                </div>

                <div className="form-field"></div>
                <div className="d-flex">
                  <button
                    className="w-50 btn btn-secondary rounded-pill border-0"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    No
                  </button>

                  <button
                    className="butn w-50 ms-2 rounded-pill border-0"
                    data-bs-dismiss="modal"
                    onClick={(e) => {
                      deleteJob(selectedId, jobList);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}
      </div>
    </div>
  );
};

export default ManageJobs;
