import React, { useState } from "react";
import ChatRoomList from "./ChatRoomList";
import ChatMessage from "./ChatMessage";
function UserMessagesRequest() {
  const [activeChatId, setActiveChatId] = useState("");
  const [messageStatusChange, setMessageStatusChange] = useState(false);
  return (
    <div className="wrapper">
      {/* <Header /> */}
      <div className="main py-5">
        <div className="container">
          <div className="row user-message-request">
            <ChatRoomList  
              {...{
                pageType:"pending",
                setActiveChatId:setActiveChatId,
                activeChatId:activeChatId,
                messageStatusChange:messageStatusChange,
              }}/>

              <ChatMessage 
              {...{
                pageType:"pending",
                activeChatId:activeChatId,
                setMessageStatusChange:setMessageStatusChange,
              }}
              />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default UserMessagesRequest;
